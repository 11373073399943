import React from 'react';
import Tooltip from "@mui/material/Tooltip"

const LABEL_OFFSET_TOP = -24;

const Bar = ({value, sum, options, lang, maxVal, label, i}) => {
  const valueString = value.toLocaleString(lang.locale);
  const percentUnrounded = value / sum * 100;
  const percent = options.round === 0 ? 
    Math.round(percentUnrounded)
    : Math.round(percentUnrounded * (10 ** (options.round))) / (10 ** (options.round));
  const percentString = `${percent.toLocaleString(lang.locale)} %`;
  const relSize = Math.round(value / maxVal * 100);

  const barStyle = options.horizontal ? 
    {height: `${relSize/100 * options.columnHeight}px`, width: '100%'}
    : {height: '100%', width: `${relSize}%`}

  const valueStyle = options.horizontal ?
    {position: 'absolute', top: 'auto', bottom: `${relSize/100 * options.columnHeight + (relSize === 100 ? LABEL_OFFSET_TOP : 0)}px`}
    : {position: 'auto'}

  const tooltipString = options.percent ?
    percentString
    : valueString;

  const barString = options.hideValues ?
    ''
    : tooltipString;

  const barInnerStyle = {
    ...(options.stacked ? {width: '100%', height: '100%'} : barStyle),
    backgroundColor: i % 2 ? options.barColors[1] : options.barColors[0]
  }
  return (
    <Tooltip title={label ? `${label}: ${tooltipString}` : tooltipString}>
      <div className="infographic-bar" style={options.stacked ? barStyle : {}}>
        <div className="infographic-bar-inner" style={barInnerStyle} />
        <div className="infographic-field-value" style={valueStyle}>
          { barString }
        </div>
      </div>
    </Tooltip>
  )
}
 
const Field = (props) => {
  const { data, i, options, lang, rankingPos, maxVal, overallSum, sortedFields, barLabels = [] } = props;
  const label = data.label[lang.id] || data.name[lang.id];
  const datasets = data.values.length;

  const fieldSum = data.values
    .map(v => v.value)
    .reduce((a, b) => a + b);
  const sum = options.fieldPercent ? fieldSum : overallSum;

  const orderId = options.noOrder ? i : rankingPos;
  if (!options.horizontal) {
    const topOffset = `${orderId * (options.responsiveBarHeight + options.barSpacing)}px`;
    const barContainerStyle = {
      left: options.barOffset + 'px',
      width: options.barOffset ? `calc(100% - ${options.barOffset}px)` : '100%'
    }
    return (
      <div className="infographic-field" style={{top: topOffset, height: `${options.responsiveBarHeight}px`}}>
        <div className="infographic-field-label" style={{maxWidth: options.barOffset ? `${options.barOffset}px` : '100%'}}>{label}</div>
        <div className="infographic-bar-container" style={barContainerStyle}>
          {
            data.values.map((v, i) => {
              const barLabel = barLabels.hasOwnProperty(i) ? barLabels[i] : '';
              return (
                <Bar 
                  key={i} 
                  i={i}
                  value={v.value} 
                  label={barLabel} 
                  datasets={datasets} 
                  sum={sum} 
                  maxVal={maxVal} 
                  options={options} 
                  lang={lang} 
                />
              );
            })
          }
        </div>
      </div>
    );
  }

  else { // horizontal
    const length = sortedFields.length;
    const leftOffset = `${orderId * 100 / length}%`;
    const width = `calc(${100 / length}% - ${options.barSpacing}px)`;
    return (
      <div className="infographic-field" style={{left: leftOffset, height: '100%', width: width}}>
        <div className="infographic-field-label">{label}</div>
        <div className="infographic-bar-container" style={{width: '100%'}}>
          {
            data.values
              .map((v, i) => {
                const barLabel = barLabels.hasOwnProperty(i) ? barLabels[i] : '';
                return (
                  <Bar 
                    key={i} 
                    i={i}
                    value={v.value} 
                    label={barLabel} 
                    datasets={datasets} 
                    sum={sum} 
                    maxVal={maxVal} 
                    options={options} 
                    lang={lang} 
                  />
                )
              })
          }
        </div>
      </div>
    )
  }
}

export default Field;