import startPageVideoMobile from "./src/videos/0g_mobile.mp4"
import startPagePosterMobile from "./src/videos/0g_mobile.jpg"
import startPageVideoDesktop from "./src/videos/0g_desktop.mp4"
import startPagePosterDesktop from "./src/videos/0g_desktop.jpg"

import dekoderLogo from "./src/images/logos/dekoder-black.svg"
import dekoderWhite from "./src/images/logos/dekoder-white.svg"
import dekoderLogoSlogan from "./src/images/logos/dekoder-black-slogan.svg"
import fsoLogo from "./src/images/logos/fso.png"
// import fsoLogoMenu from "./src/images/logos/fso-menu.png"
import volkswagenstiftungLogo from "./src/images/logos/volkswagenstiftung.svg"
import srfLogo from "./src/images/logos/srf.svg"
import russlandAnalysenLogo from "./src/images/logos/russland-analysen.png"
import ovdLogo from "./src/images/logos/ovd-info.png"

export {
  siteMetadata,
  metaInfos,
  restApi,
  categoryIds,
  categoryNames,
} from "./meta-data-node"

export const BASE_URL = "https://protest.dekoder.org"

export const BREAKPOINT_MOBILE = 850
export const BREAKPOINT_TABLET = 1250

export const videos = [
  {
    id: "start_desktop",
    src: startPageVideoDesktop,
    poster: startPagePosterDesktop,
  },
  {
    id: "start_mobile",
    src: startPageVideoMobile,
    poster: startPagePosterMobile,
  },
]

export const siteLogos = [
  {
    src: dekoderWhite,
    url: "https://www.dekoder.org/",
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]",
    },
  },
]

export const startPageLogos = [
  { src: dekoderLogo, url: "https://www.dekoder.org/" },
  {
    src: fsoLogo,
    url: "https://www.forschungsstelle.uni-bremen.de/",
  },
]

const ANIMATIONS_DIR = "/animations_6_9/"

export const animations = [
  {
    id: 0,
    srcDesktop: `${ANIMATIONS_DIR}1-bely-krug/desktop.json`,
    srcMobile: `${ANIMATIONS_DIR}1-bely-krug/desktop.json`,
  },
  {
    id: 1,
    srcDesktop: `${ANIMATIONS_DIR}2-dalno/desktop.json`,
    srcMobile: `${ANIMATIONS_DIR}2-dalno/mobile.json`,
  },
  {
    id: 2,
    srcDesktop: `${ANIMATIONS_DIR}3-putina-v-otstavku/desktop.json`,
    srcMobile: `${ANIMATIONS_DIR}3-putina-v-otstavku/mobile.json`,
  },
  {
    id: 3,
    srcDesktop: `${ANIMATIONS_DIR}4-shariki/desktop.json`,
    srcMobile: `${ANIMATIONS_DIR}4-shariki/mobile.json`,
  },
]

export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.dekoder.org/",
    logo: dekoderLogoSlogan,
  },
  {
    name: {
      de: "Forschungsstelle Osteuropa an der Universität Bremen",
      en: "Research Centre for East European Studies at the University of Bremen",
      ru: "Исследовательский центр Восточной Европы при Бременском университете",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.forschungsstelle.uni-bremen.de/",
    logo: fsoLogo,
  },
  {
    name: {
      de: "VolkswagenStiftung",
      en: "Volkswagen Foundation",
      ru: "Volkswagen Foundation",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.volkswagenstiftung.de/",
    logo: volkswagenstiftungLogo,
  },
]

export const mediaPartners = [
  {
    name: {
      de: "SRF – Schweizer Radio und Fernsehen",
      en: "SRF – Swiss Radio and Television",
      ru: "Швейцарское радио и телевидение СРФ",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.srf.ch/",
    logo: srfLogo,
    logoWidth: 65,
  },
  {
    name: {
      de: "OWD-Info",
      en: "OVD-Info",
      ru: "ОВД-Инфо",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://ovdinfo.org/",
    logo: ovdLogo,
  },
  {
    name: {
      de: "Russland Analysen",
      en: "Russland Analysen",
      ru: "Russland Analysen",
    },
    text: { de: "", en: "", ru: "" },
    url: "http://www.laender-analysen.de/russland/",
    logo: russlandAnalysenLogo,
  },
]
