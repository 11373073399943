import React, { useRef, useState, useContext, useMemo } from "react"
import { connect } from "react-redux"

import {
  useDataLoader,
  useDatasetSelector,
  useRangeFilter,
  useRawDataViewer,
  useGeoJsonConverter,
  useScrollLock,
  useCategories,
  usePopups,
  useCategoryFilter,
} from "./map-controller-hooks"

import BodyClassWrapper from "../body-class-wrapper"
import Map from "./map"
import MapLegend from "./map-legend"

import { LangContext } from "gatsby-source-dek-wp"

import { sources } from "./sources"

import "./mapbox.css"
import "./map-controller.scss"

const ON_MAP_BODY_CLASS = "on-map"

const MapController = ({
  mapUid,
  sourceIds = [],
  title,
  standalone,
  mapPopups,
  mapPoints,
  dispatch,
}) => {
  const mapRef = useRef()
  const lang = useContext(LangContext)
  const [locked, lockComponent] = useScrollLock(mapRef, standalone)
  const sourcesLocalized = useMemo(
    () => sources(lang).filter((s) => sourceIds.includes(s.id)),
    [lang, sourceIds]
  )
  const datasets = useDataLoader(sourcesLocalized, mapPoints)
  const [selectedDataset, datasetSelector] = useDatasetSelector(datasets)
  const keys = selectedDataset.keys || {}

  const [rawData, rangeFilterComponent, rangeString] = useRangeFilter(
    keys.range,
    selectedDataset.data,
    lang
  )
  const [rawDataViewerComponent, setRawDataViewerOptions] = useRawDataViewer(
    rawData,
    selectedDataset.rawViewColumns,
    selectedDataset,
    rangeString,
    lang
  )
  const geoJson = useGeoJsonConverter(
    rawData,
    selectedDataset.customGeoJsonConverter
  )

  const [highlightedPoint, setHighlightedPoint] = useState("")
  const categories = useCategories(geoJson, selectedDataset, keys.category)
  const [shownCategories, onCategoryClick] = useCategoryFilter(categories)

  const [popupComponents, popupInteractions] = usePopups(
    mapPopups,
    dispatch,
    selectedDataset,
    setHighlightedPoint,
    mapUid,
    setRawDataViewerOptions,
    rawData,
    rangeString,
    geoJson,
    categories,
    lang
  )
  const { addOrRemovePopup, hoverPopup, unHoverPopup } = popupInteractions

  return (
    <BodyClassWrapper bodyClassName={ON_MAP_BODY_CLASS}>
      <div className={`map-controller`}>
        {rawDataViewerComponent}
        {popupComponents}
        <div className="map-controller-top-left">
          <div className="map-controller-title">{title}</div>
          {datasetSelector}
          {rangeFilterComponent}
        </div>
        <Map
          mapRef={mapRef}
          locked={locked}
          standalone={standalone}
          geoJson={geoJson}
          interactions={{
            onClick: addOrRemovePopup,
            onMouseEnter: hoverPopup,
            onMouseLeave: unHoverPopup,
          }}
          highlightedPoint={highlightedPoint}
          selectedPoints={mapPopups.map((p) => p[keys.primary])}
          categories={categories}
          shownCategories={shownCategories}
          primaryKey={keys.primary}
          categoryKey={keys.category}
          type={selectedDataset.type}
          sourceLang={selectedDataset.sourceLang}
          customBounds={selectedDataset.customBounds}
        >
          {lockComponent}
        </Map>
        <MapLegend
          title={selectedDataset.legendTitle}
          subtitle={selectedDataset.legendSubtitle}
          categories={categories}
          shownCategories={shownCategories}
          onCategoryClick={onCategoryClick}
          sort={selectedDataset.sort}
          // onlyPredefined={false}
          onlyPredefined={!!selectedDataset.hasOwnProperty("categoryColors")}
        />
      </div>
    </BodyClassWrapper>
  )
}

const MapControllerRedux = connect(
  (state) => {
    return {
      mapPopups: state.mapPopups,
      mapPoints: state.mapPoints,
    }
  },
  (dispatch) => {
    return { dispatch }
  }
)(MapController)

export default MapControllerRedux
