import React, { useState, useEffect, useRef, useContext } from "react"

import { isPortraitDevice } from "../../helpers"

import BackgroundVideo from "../background-video"
import Animation from "../animation"
import PageTeaser from "../page-teaser"
import Page from "../page"
import StartPageLogos from "./logos"

import "./start-page.scss"

import scrollToElement from "scroll-to-element"
import { ArrowDown } from "../buttons"
import { LangContext, ContentsContext } from "gatsby-source-dek-wp"

const StartPageTitle = ({ title, subtitle }) => (
  <div className="start-page-title-container">
    <div className="start-page-title">{title}</div>
    <div className="start-page-subtitle">{subtitle}</div>
  </div>
)

const StartPage = ({ metaData }) => {
  const pages = useContext(ContentsContext)

  const majorPages = pages.filter((p) => p.pageType === "major")
  const aboutPage = pages.find((p) => p.pageType === "about")

  const { metaInfos, videos, animations, startPageLogos } = metaData
  const lang = useContext(LangContext)
  const { title, description } = metaInfos.find((m) => m.langId === lang.id)

  const video = useVideo(videos)

  const outerRef = useRef()
  const aboutPageRef = useRef()

  const [refCollection, setCurrentSlide, scrollDownButton] =
    useScrollDownButton(majorPages.length, aboutPageRef)

  return (
    <>
      <BackgroundVideo
        videoSrc={video.src}
        poster={video.poster}
        titleDelayTime={7000}
        captionUrl={video.captionUrl}
        startPage={true}
        startPageScrollDownButton={scrollDownButton}
      >
        <StartPageLogos logos={startPageLogos} />
        <StartPageTitle title={title} subtitle={description} />
      </BackgroundVideo>
      <div
        key={`start_page_split_view`}
        ref={outerRef}
        className="split-view-container"
      >
        <div
          className="split-view-first start-page"
          style={{
            overflowX: "hidden",
            pointerEvents: "none",
            marginBottom: 0,
          }}
        >
          <Animation
            sources={animations}
            containerElement={outerRef}
            setCurrentSlide={setCurrentSlide}
          />
        </div>
        <div className="split-view-second start-page">
          {majorPages.map((p, i) => {
            return (
              <div
                ref={(el) => refCollection.push(el)}
                key={`start_page_teaser_${i}`}
              >
                <PageTeaser page={p} />
              </div>
            )
          })}
        </div>
      </div>
      <div ref={aboutPageRef}>
        <Page page={aboutPage} fromStartPage={true} metaData={metaData} />
      </div>
    </>
  )
}

export default StartPage

function useVideo(videos) {
  const [video, setVideo] = useState({})
  useEffect(() => {
    const id = isPortraitDevice() ? "start_mobile" : "start_desktop"
    const video = videos.find((v) => v.id === id)
    setVideo(video)
  }, [videos])
  return video
}

function useScrollDownButton(length, aboutPageRef) {
  let refCollection = []
  const [currentSlide, setCurrentSlide] = useState(0)
  function scrollToRef(key) {
    if (!refCollection.hasOwnProperty(key)) return
    const targetEl = refCollection[key]
    const targetElHeight = targetEl.offsetHeight
    scrollToElement(targetEl, {
      offset: targetElHeight / 3,
      ease: "inOutSine",
      duration: 2500,
    })
    // scrollIntoView(targetEl, {time: 2000, align: {topOffset: -targetElHeight / 3}}); // :3 ??
  }
  function scrollDown() {
    if (currentSlide === length - 1) {
      if (!aboutPageRef.current) return
      scrollToElement(aboutPageRef.current, {
        offset: -15,
        ease: "inOutSine",
        duration: 2000,
      })
    } else {
      const nextSlideKey = currentSlide + 1
      scrollToRef(nextSlideKey)
    }
  }
  const scrollDownButton =
    currentSlide < length ? (
      <ArrowDown onClick={scrollDown} extraClasses="fixed start-page" />
    ) : (
      ""
    ) // no button on last slide
  return [refCollection, setCurrentSlide, scrollDownButton]
}
