const ADD_MAPPOINT = "ADD_MAPPOINT"
const REMOVE_MAPPOINT = "REMOVE_MAPPOINT"

export function addMapPoint(uid, name, lat, lon, category) {
  return {
    type: ADD_MAPPOINT,
    uid,
    name,
    lat,
    lon,
    category,
  }
}

export function removeMapPoint(uid) {
  return {
    type: REMOVE_MAPPOINT,
    uid,
  }
}

export function mapPointReducer(state = [], action) {
  switch (action.type) {
    case ADD_MAPPOINT:
      const obj = {
        uid: action.uid,
        name: action.name,
        lat: action.lat,
        lon: action.lon,
        category: action.category,
        annotationId: action.uid,
      }
      return [...state.filter((p) => p.uid !== action.uid), obj]
    case REMOVE_MAPPOINT:
      return [...state.filter((p) => p.uid !== action.uid)]
    default:
      return state
  }
}
