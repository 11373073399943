import React from "react"
import BackgroundVideo from "../background-video"

import "./page-title.scss"

export const PageTitleClassic = ({ children }) => (
  <div className="page-title-classic">
    <h1>{children}</h1>
  </div>
)

export const PageTitleVideo = ({
  titleVideo,
  titleVideoPoster,
  titleVideoCaptions,
  title,
  teaser,
}) => (
  <BackgroundVideo
    videoSrc={titleVideo}
    poster={titleVideoPoster}
    titleDelayTime={7000}
    captionUrl={titleVideoCaptions}
  >
    <div className={`page-title-major ${titleVideo ? "" : "with-theme-bg"}`}>
      <div className="page-title-container">
        <h1>{title}</h1>
        <div className="page-title-teaser">{teaser}</div>
      </div>
    </div>
  </BackgroundVideo>
)
