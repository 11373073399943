import Highcharts from "highcharts"

function getSeriesForGroups(data, groups) {
  return groups.reduce((acc, group, i) => {
    const predicted = {
      name: group.name,
      color: group.color ? group.color : Highcharts.getOptions().colors[i],
      data: data
        .filter((r) => r.group === group.key)
        .map((r) => [r.x, r.predicted * 100]),
    }
    const confidenceInterval = {
      name: `${group.name} | confidence interval`,
      linkedTo: ":previous",
      type: "arearange",
      color: group.color ? group.color : Highcharts.getOptions().colors[i],
      fillOpacity: 0.3,
      data: data
        .filter((r) => r.group === group.key)
        .map((r) => [r.x, r.confLow * 100, r.confHigh * 100]),
    }
    return (acc = [...acc, predicted, confidenceInterval])
  }, [])
}

function getHighchartsOptions({
  series,
  title = "",
  xTitle = "",
  yTitle = "",
}) {
  return {
    chart: {
      type: "line",
      style: {
        fontFamily: "Roboto Condensed",
      },
    },
    title: {
      text: title,
      style: {
        fontWeight: 700,
      },
    },
    plotOptions: {
      line: {
        marker: { enabled: false },
      },
      spline: {
        marker: { enabled: false },
      },
      arearange: {
        marker: { enabled: false },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: yTitle,
      },
      labels: {
        format: "{value} %",
      },
    },
    xAxis: {
      title: {
        text: xTitle,
      },
      allowDecimals: false,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " %",
      shared: true,
    },
    series: series,
  }
}

const ageOptions = {
  de: {
    title: "",
    xTitle: "Alter",
    yTitle: "geschätzte Protestwahrscheinlichkeit",
    groups: [{ key: 1, name: "" }],
  },
  en: {
    title: "",
    xTitle: "Age",
    yTitle: "Estimated probability of protest activity",
    groups: [{ key: 1, name: "" }],
  },
  ru: {
    title: "",
    xTitle: "возраст",
    yTitle: "приблизительная возможность участия в протестных акциях",
    groups: [{ key: 1, name: "" }],
  },
}

const ageCityOptions = {
  de: {
    title: "",
    xTitle: "Alter",
    yTitle: "geschätzte Protestwahrscheinlichkeit",
    groups: [
      { key: 0, name: "Regionen", color: "#000000" },
      { key: 1, name: "Moskau und St. Petersburg", color: "#ff0000" },
    ],
  },
  en: {
    title: "",
    xTitle: "Age",
    yTitle: "Estimated probability of protest activity",
    groups: [
      { key: 0, name: "Regions", color: "#000000" },
      { key: 1, name: "Moscow and St. Petersburg", color: "#ff0000" },
    ],
  },
  ru: {
    title: "",
    xTitle: "возраст",
    yTitle: "приблизительная возможность участия в протестных акциях",
    groups: [
      { key: 0, name: "регионы", color: "#000000" },
      { key: 1, name: "Москва и Санкт-Петербург", color: "#ff0000" },
    ],
  },
}

const ageIncomeOptions = {
  de: {
    title: "nach Lebensstandard",
    xTitle: "Alter",
    yTitle: "geschätzte Protestwahrscheinlichkeit",
    groups: [
      { key: 1, name: "niedrig" },
      { key: 2, name: "2" },
      { key: 3, name: "3" },
      { key: 4, name: "4" },
      { key: 5, name: "hoch" },
    ],
  },
  en: {
    title: "Standard of living",
    xTitle: "Age",
    yTitle: "Estimated probability of protest activity",
    groups: [
      { key: 1, name: "low" },
      { key: 2, name: "2" },
      { key: 3, name: "3" },
      { key: 4, name: "4" },
      { key: 5, name: "high" },
    ],
  },
  ru: {
    title: "распределение по благосостоянию",
    xTitle: "возраст",
    yTitle: "приблизительная возможность участия в протестных акциях",
    groups: [
      { key: 1, name: "низкое" },
      { key: 2, name: "2" },
      { key: 3, name: "3" },
      { key: 4, name: "4" },
      { key: 5, name: "высокое" },
    ],
  },
}

const agePutinOptions = {
  de: {
    title: "Vertrauen Sie Putin?",
    xTitle: "Alter",
    yTitle: "geschätzte Protestwahrscheinlichkeit",
    groups: [
      { key: 1, name: "überhaupt nicht" },
      { key: 2, name: "eher nein" },
      { key: 3, name: "eher ja" },
      { key: 4, name: "voll und ganz" },
    ],
  },
  en: {
    title: "Do you trust Putin?",
    xTitle: "Age",
    yTitle: "Estimated probability of protest activity",
    groups: [
      { key: 1, name: "Do not trust at all" },
      { key: 2, name: "Tend not to trust" },
      { key: 3, name: "Tend to trust" },
      { key: 4, name: "Trust completely" },
    ],
  },
  ru: {
    title: "Доверяете ли вы Путину?",
    xTitle: "возраст",
    yTitle: "приблизительная возможность участия в протестных акциях",
    groups: [
      { key: 1, name: "совершенно не доверяю" },
      { key: 2, name: "скорее нет" },
      { key: 3, name: "скорее да" },
      { key: 4, name: "полностью доверяю" },
    ],
  },
}

const ageEnvironmentOptions = {
  de: {
    title: "Vertrauen Sie Putin?",
    xTitle: "Alter",
    yTitle: "geschätzte Protestwahrscheinlichkeit",
    groups: [
      { key: 1, name: "überhaupt nicht" },
      { key: 2, name: "eher nein" },
      { key: 3, name: "eher ja" },
      { key: 4, name: "voll und ganz" },
    ],
  },
  en: {
    title: "Do you trust Putin?",
    xTitle: "Age",
    yTitle: "Estimated probability of protest activity",
    groups: [
      { key: 1, name: "Do not trust at all" },
      { key: 2, name: "Tend not to trust" },
      { key: 3, name: "Tend to trust" },
      { key: 4, name: "Trust completely" },
    ],
  },
  ru: {
    title: "Доверяете ли вы Путину?",
    xTitle: "возраст",
    yTitle: "приблизительная возможность участия в протестных акциях",
    groups: [
      { key: 1, name: "совершенно не доверяю" },
      { key: 2, name: "скорее нет" },
      { key: 3, name: "скорее да" },
      { key: 4, name: "полностью доверяю" },
    ],
  },
}

function getCompiledOptions(data, optionsObj, lang) {
  return getHighchartsOptions({
    series: getSeriesForGroups(data, optionsObj[lang.id].groups),
    title: optionsObj[lang.id].title,
    xTitle: optionsObj[lang.id].xTitle,
    yTitle: optionsObj[lang.id].yTitle,
  })
}

export default function getOptions(id, lang) {
  const age = getCompiledOptions(ageData(), ageOptions, lang)
  const ageCity = getCompiledOptions(ageCityData(), ageCityOptions, lang)
  const ageIncome = getCompiledOptions(ageIncomeData(), ageIncomeOptions, lang)
  const agePutin = getCompiledOptions(agePutinData(), agePutinOptions, lang)
  const agePutinEnvironment = getCompiledOptions(
    agePutinEnvironmentData(),
    ageEnvironmentOptions,
    lang
  )

  /**
   *
   */

  const allOptions = [
    { id: 1, options: age },
    { id: 2, options: ageCity },
    { id: 3, options: ageIncome },
    { id: 4, options: agePutin },
    { id: 5, options: agePutinEnvironment },
  ]

  const obj = allOptions.find((o) => o.id === id)
  return obj ? obj.options : {}
}

function ageData() {
  return [
    {
      x: 16,
      predicted: 0.045732635,
      confLow: 0.020492233,
      confHigh: 0.098922081,
      group: 1,
    },
    {
      x: 17,
      predicted: 0.042196863,
      confLow: 0.019915631,
      confHigh: 0.087188336,
      group: 1,
    },
    {
      x: 18,
      predicted: 0.038923305,
      confLow: 0.019298598,
      confHigh: 0.076938713,
      group: 1,
    },
    {
      x: 19,
      predicted: 0.035894187,
      confLow: 0.018633234,
      confHigh: 0.068036341,
      group: 1,
    },
    {
      x: 20,
      predicted: 0.033092687,
      confLow: 0.017910931,
      confHigh: 0.060352081,
      group: 1,
    },
    {
      x: 21,
      predicted: 0.030502922,
      confLow: 0.017123116,
      confHigh: 0.05376565,
      group: 1,
    },
    {
      x: 22,
      predicted: 0.028109935,
      confLow: 0.016262674,
      confHigh: 0.048165283,
      group: 1,
    },
    {
      x: 23,
      predicted: 0.025899665,
      confLow: 0.015326154,
      confHigh: 0.043445982,
      group: 1,
    },
    {
      x: 24,
      predicted: 0.023858921,
      confLow: 0.014316474,
      confHigh: 0.039506827,
      group: 1,
    },
    {
      x: 25,
      predicted: 0.021975348,
      confLow: 0.013245162,
      confHigh: 0.036248415,
      group: 1,
    },
    {
      x: 26,
      predicted: 0.020237394,
      confLow: 0.012132744,
      confHigh: 0.033571947,
      group: 1,
    },
    {
      x: 27,
      predicted: 0.018634269,
      confLow: 0.011006353,
      confHigh: 0.031380946,
      group: 1,
    },
    {
      x: 28,
      predicted: 0.017155914,
      confLow: 0.009895205,
      confHigh: 0.029585051,
      group: 1,
    },
    {
      x: 29,
      predicted: 0.015792957,
      confLow: 0.008825821,
      confHigh: 0.028104034,
      group: 1,
    },
    {
      x: 30,
      predicted: 0.01453668,
      confLow: 0.007818774,
      confHigh: 0.026870303,
      group: 1,
    },
    {
      x: 31,
      predicted: 0.013378976,
      confLow: 0.006887564,
      confHigh: 0.025829313,
      group: 1,
    },
    {
      x: 32,
      predicted: 0.01231232,
      confLow: 0.006039119,
      confHigh: 0.024938385,
      group: 1,
    },
    {
      x: 33,
      predicted: 0.011329728,
      confLow: 0.005275131,
      confHigh: 0.024164749,
      group: 1,
    },
    {
      x: 34,
      predicted: 0.010424725,
      confLow: 0.004593558,
      confHigh: 0.023483479,
      group: 1,
    },
  ]
}

function ageCityData() {
  return [
    {
      x: 16,
      predicted: 0.043258158,
      stdError: 0.449692163,
      confLow: 0.018383843,
      confHigh: 0.098414449,
      group: 0,
    },
    {
      x: 16,
      predicted: 0.047917105,
      stdError: 0.453757348,
      confLow: 0.020262205,
      confHigh: 0.109113198,
      group: 1,
    },
    {
      x: 17,
      predicted: 0.039905698,
      stdError: 0.424548181,
      confLow: 0.017764805,
      confHigh: 0.087191978,
      group: 0,
    },
    {
      x: 17,
      predicted: 0.044220269,
      stdError: 0.426744264,
      confLow: 0.019651632,
      confHigh: 0.096481889,
      group: 1,
    },
    {
      x: 18,
      predicted: 0.036803055,
      stdError: 0.401186797,
      confLow: 0.0171074,
      confHigh: 0.077388834,
      group: 0,
    },
    {
      x: 18,
      predicted: 0.040796425,
      stdError: 0.401269452,
      confLow: 0.019002813,
      confHigh: 0.085408241,
      group: 1,
    },
    {
      x: 19,
      predicted: 0.033933115,
      stdError: 0.379936975,
      confLow: 0.016406994,
      confHigh: 0.068869957,
      group: 0,
    },
    {
      x: 19,
      predicted: 0.037627243,
      stdError: 0.377644344,
      confLow: 0.018309737,
      confHigh: 0.075752797,
      group: 1,
    },
    {
      x: 20,
      predicted: 0.031279708,
      stdError: 0.361171615,
      confLow: 0.015659568,
      confHigh: 0.061507082,
      group: 0,
    },
    {
      x: 20,
      predicted: 0.034695347,
      stdError: 0.35623714,
      confLow: 0.017566307,
      confHigh: 0.067381469,
      group: 1,
    },
    {
      x: 21,
      predicted: 0.028827593,
      stdError: 0.345296013,
      confLow: 0.014862518,
      confHigh: 0.055179511,
      group: 0,
    },
    {
      x: 21,
      predicted: 0.03198431,
      stdError: 0.337470176,
      confLow: 0.01676702,
      confHigh: 0.060167268,
      group: 1,
    },
    {
      x: 22,
      predicted: 0.026562436,
      stdError: 0.332724074,
      confLow: 0.014015692,
      confHigh: 0.049773936,
      group: 0,
    },
    {
      x: 22,
      predicted: 0.02947864,
      stdError: 0.321805704,
      confLow: 0.015908034,
      confHigh: 0.053990736,
      group: 1,
    },
    {
      x: 23,
      predicted: 0.024470782,
      stdError: 0.323840783,
      confLow: 0.013122513,
      confHigh: 0.045183637,
      group: 0,
    },
    {
      x: 23,
      predicted: 0.027163758,
      stdError: 0.309714826,
      confLow: 0.014988619,
      confHigh: 0.048739312,
      group: 1,
    },
    {
      x: 24,
      predicted: 0.02254002,
      stdError: 0.318954492,
      confLow: 0.012190845,
      confHigh: 0.041307527,
      group: 0,
    },
    {
      x: 24,
      predicted: 0.025025969,
      stdError: 0.301627597,
      confLow: 0.014012747,
      confHigh: 0.044306005,
      group: 1,
    },
    {
      x: 25,
      predicted: 0.020758356,
      stdError: 0.318249358,
      confLow: 0.011233177,
      confHigh: 0.0380496,
      group: 0,
    },
    {
      x: 25,
      predicted: 0.023052439,
      stdError: 0.297870294,
      confLow: 0.012990306,
      confHigh: 0.040588069,
      group: 1,
    },
    {
      x: 26,
      predicted: 0.019114768,
      stdError: 0.321752873,
      confLow: 0.010265802,
      confHigh: 0.035319212,
      group: 0,
    },
    {
      x: 26,
      predicted: 0.02123115,
      stdError: 0.29860641,
      confLow: 0.011937233,
      confHigh: 0.037486463,
      group: 1,
    },
    {
      x: 27,
      predicted: 0.017598976,
      stdError: 0.329330745,
      confLow: 0.009307024,
      confHigh: 0.033032196,
      group: 0,
    },
    {
      x: 27,
      predicted: 0.019550875,
      stdError: 0.303803284,
      confLow: 0.010874139,
      confHigh: 0.034906658,
      group: 1,
    },
    {
      x: 28,
      predicted: 0.0162014,
      stdError: 0.340711226,
      confLow: 0.008374906,
      confHigh: 0.03111241,
      group: 0,
    },
    {
      x: 28,
      predicted: 0.018001134,
      stdError: 0.313238972,
      confLow: 0.009823629,
      confHigh: 0.032760638,
      group: 1,
    },
    {
      x: 29,
      predicted: 0.014913124,
      stdError: 0.355529338,
      confLow: 0.00748521,
      confHigh: 0.029493062,
      group: 0,
    },
    {
      x: 29,
      predicted: 0.016572161,
      stdError: 0.326546234,
      confLow: 0.008807205,
      confHigh: 0.030969262,
      group: 1,
    },
    {
      x: 30,
      predicted: 0.013725858,
      stdError: 0.37337602,
      confLow: 0.006650065,
      confHigh: 0.028117321,
      group: 0,
    },
    {
      x: 30,
      predicted: 0.015254861,
      stdError: 0.343275114,
      confLow: 0.007842754,
      confHigh: 0.029464043,
      group: 1,
    },
    {
      x: 31,
      predicted: 0.012631901,
      stdError: 0.393839771,
      confLow: 0.005877498,
      confHigh: 0.026938109,
      group: 0,
    },
    {
      x: 31,
      predicted: 0.014040776,
      stdError: 0.362952801,
      confLow: 0.006943209,
      confHigh: 0.028187757,
      group: 1,
    },
    {
      x: 32,
      predicted: 0.011624105,
      stdError: 0.416535052,
      confLow: 0.005171663,
      confHigh: 0.025917226,
      group: 0,
    },
    {
      x: 32,
      predicted: 0.012922049,
      stdError: 0.385127562,
      confLow: 0.006116377,
      confHigh: 0.02709393,
      group: 1,
    },
    {
      x: 33,
      predicted: 0.010695842,
      stdError: 0.441117564,
      confLow: 0.004533481,
      confHigh: 0.025024128,
      group: 0,
    },
    {
      x: 33,
      predicted: 0.011891383,
      stdError: 0.409393836,
      confLow: 0.005365553,
      confHigh: 0.026145586,
      group: 1,
    },
    {
      x: 34,
      predicted: 0.009840968,
      stdError: 0.467289559,
      confLow: 0.003961425,
      confHigh: 0.024234602,
      group: 0,
    },
    {
      x: 34,
      predicted: 0.010942012,
      stdError: 0.435402065,
      confLow: 0.004690495,
      confHigh: 0.025313676,
      group: 1,
    },
  ]
}

function ageIncomeData() {
  return [
    {
      x: 16,
      predicted: 0.043731745,
      stdError: 0.733969607,
      confLow: 0.010734306,
      confHigh: 0.16159471,
      group: 1,
    },
    {
      x: 16,
      predicted: 0.080215153,
      stdError: 0.614107224,
      confLow: 0.025504739,
      confHigh: 0.225168025,
      group: 2,
    },
    {
      x: 16,
      predicted: 0.056196248,
      stdError: 0.467677195,
      confLow: 0.023255153,
      confHigh: 0.129607027,
      group: 3,
    },
    {
      x: 16,
      predicted: 0.031819283,
      stdError: 0.506938808,
      confLow: 0.012021929,
      confHigh: 0.081528033,
      group: 4,
    },
    {
      x: 16,
      predicted: 0.051444192,
      stdError: 0.520248857,
      confLow: 0.019187692,
      confHigh: 0.130701095,
      group: 5,
    },
    {
      x: 17,
      predicted: 0.04034413,
      stdError: 0.717857909,
      confLow: 0.010190017,
      confHigh: 0.146520871,
      group: 1,
    },
    {
      x: 17,
      predicted: 0.074220754,
      stdError: 0.596756531,
      confLow: 0.024287331,
      confHigh: 0.205221972,
      group: 2,
    },
    {
      x: 17,
      predicted: 0.051895487,
      stdError: 0.442465147,
      confLow: 0.022478771,
      confHigh: 0.115268792,
      group: 3,
    },
    {
      x: 17,
      predicted: 0.02932615,
      stdError: 0.48326227,
      confLow: 0.01158161,
      confHigh: 0.072269823,
      group: 4,
    },
    {
      x: 17,
      predicted: 0.047488812,
      stdError: 0.497932628,
      confLow: 0.018441506,
      confHigh: 0.116842397,
      group: 5,
    },
    {
      x: 18,
      predicted: 0.03720872,
      stdError: 0.703292359,
      confLow: 0.00964407,
      confHigh: 0.132979711,
      group: 1,
    },
    {
      x: 18,
      predicted: 0.068640882,
      stdError: 0.581206162,
      confLow: 0.023047071,
      confHigh: 0.187153447,
      group: 2,
    },
    {
      x: 18,
      predicted: 0.047907161,
      stdError: 0.418951579,
      confLow: 0.021657085,
      confHigh: 0.102636486,
      group: 3,
    },
    {
      x: 18,
      predicted: 0.027022908,
      stdError: 0.461291078,
      confLow: 0.01112042,
      confHigh: 0.064190202,
      group: 4,
    },
    {
      x: 18,
      predicted: 0.043823499,
      stdError: 0.477395541,
      confLow: 0.017663207,
      confHigh: 0.104603064,
      group: 5,
    },
    {
      x: 19,
      predicted: 0.034308273,
      stdError: 0.690370827,
      confLow: 0.009098008,
      confHigh: 0.12085523,
      group: 1,
    },
    {
      x: 19,
      predicted: 0.063451749,
      stdError: 0.567604104,
      confLow: 0.021787194,
      confHigh: 0.170875134,
      group: 2,
    },
    {
      x: 19,
      predicted: 0.044211057,
      stdError: 0.397438065,
      confLow: 0.020784861,
      confHigh: 0.091571349,
      group: 3,
    },
    {
      x: 19,
      predicted: 0.024895921,
      stdError: 0.441280033,
      confLow: 0.010636891,
      confHigh: 0.05716512,
      group: 4,
    },
    {
      x: 19,
      predicted: 0.040429076,
      stdError: 0.458876535,
      confLow: 0.016851533,
      confHigh: 0.093845882,
      group: 5,
    },
    {
      x: 20,
      predicted: 0.03162649,
      stdError: 0.679187151,
      confLow: 0.008553663,
      confHigh: 0.110029437,
      group: 1,
    },
    {
      x: 20,
      predicted: 0.05863021,
      stdError: 0.556093344,
      confLow: 0.020512222,
      confHigh: 0.156281108,
      group: 2,
    },
    {
      x: 20,
      predicted: 0.040787896,
      stdError: 0.378266013,
      confLow: 0.019857531,
      confHigh: 0.081935214,
      group: 3,
    },
    {
      x: 20,
      predicted: 0.022932405,
      stdError: 0.42350708,
      confLow: 0.010130088,
      confHigh: 0.051079284,
      group: 4,
    },
    {
      x: 20,
      predicted: 0.037287321,
      stdError: 0.442628984,
      confLow: 0.016006278,
      confHigh: 0.08443458,
      group: 5,
    },
    {
      x: 21,
      predicted: 0.029148008,
      stdError: 0.669828384,
      confLow: 0.008013142,
      confHigh: 0.100385556,
      group: 1,
    },
    {
      x: 21,
      predicted: 0.054153864,
      stdError: 0.546805968,
      confLow: 0.019228035,
      confHigh: 0.143252973,
      group: 2,
    },
    {
      x: 21,
      predicted: 0.03761935,
      stdError: 0.361807835,
      confLow: 0.018872032,
      confHigh: 0.073593175,
      group: 3,
    },
    {
      x: 21,
      predicted: 0.021120395,
      stdError: 0.408264618,
      confLow: 0.009599927,
      confHigh: 0.045826387,
      group: 4,
    },
    {
      x: 21,
      predicted: 0.034380966,
      stdError: 0.4289111,
      confLow: 0.015128711,
      confHigh: 0.076236482,
      group: 5,
    },
    {
      x: 22,
      predicted: 0.026858371,
      stdError: 0.662371885,
      confLow: 0.007478792,
      confHigh: 0.091810444,
      group: 1,
    },
    {
      x: 22,
      predicted: 0.050001128,
      stdError: 0.539856737,
      confLow: 0.017941842,
      confHigh: 0.131665148,
      group: 2,
    },
    {
      x: 22,
      predicted: 0.034688047,
      stdError: 0.348448293,
      confLow: 0.017827894,
      confHigh: 0.06641494,
      group: 3,
    },
    {
      x: 22,
      predicted: 0.019448711,
      stdError: 0.395845071,
      confLow: 0.009047517,
      confHigh: 0.041308855,
      group: 4,
    },
    {
      x: 22,
      predicted: 0.031693687,
      stdError: 0.417972029,
      confLow: 0.014222001,
      confHigh: 0.06912421,
      group: 5,
    },
    {
      x: 23,
      predicted: 0.024744005,
      stdError: 0.656882436,
      confLow: 0.006953153,
      confHigh: 0.084196318,
      group: 1,
    },
    {
      x: 23,
      predicted: 0.046151302,
      stdError: 0.535336715,
      confLow: 0.016662027,
      confHigh: 0.121389297,
      group: 2,
    },
    {
      x: 23,
      predicted: 0.031977562,
      stdError: 0.338554409,
      confLow: 0.016728437,
      confHigh: 0.060275198,
      group: 3,
    },
    {
      x: 23,
      predicted: 0.017906921,
      stdError: 0.386520651,
      confLow: 0.008475489,
      confHigh: 0.037437279,
      group: 4,
    },
    {
      x: 23,
      predicted: 0.029210098,
      stdError: 0.410034235,
      confLow: 0.013291526,
      confHigh: 0.062976704,
      group: 5,
    },
    {
      x: 24,
      predicted: 0.02279219,
      stdError: 0.653409617,
      confLow: 0.006438882,
      confHigh: 0.077441921,
      group: 1,
    },
    {
      x: 24,
      predicted: 0.042584606,
      stdError: 0.53330767,
      confLow: 0.015397871,
      confHigh: 0.112297934,
      group: 2,
    },
    {
      x: 24,
      predicted: 0.029472404,
      stdError: 0.332435759,
      confLow: 0.015581795,
      confHigh: 0.055053476,
      group: 3,
    },
    {
      x: 24,
      predicted: 0.016485301,
      stdError: 0.380518959,
      confLow: 0.007888207,
      confHigh: 0.034129752,
      group: 4,
    },
    {
      x: 24,
      predicted: 0.026915719,
      stdError: 0.405274106,
      confLow: 0.012344993,
      confHigh: 0.057679828,
      group: 5,
    },
    {
      x: 25,
      predicted: 0.020991021,
      stdError: 0.651985652,
      confLow: 0.005938666,
      confHigh: 0.071453229,
      group: 1,
    },
    {
      x: 25,
      predicted: 0.039282201,
      stdError: 0.53379801,
      confLow: 0.014159147,
      confHigh: 0.104267294,
      group: 2,
    },
    {
      x: 25,
      predicted: 0.027157998,
      stdError: 0.330302212,
      confLow: 0.014401304,
      confHigh: 0.050634069,
      group: 3,
    },
    {
      x: 25,
      predicted: 0.015174798,
      stdError: 0.377998299,
      confLow: 0.007291765,
      confHigh: 0.031311275,
      group: 4,
    },
    {
      x: 25,
      predicted: 0.024796954,
      stdError: 0.403804037,
      confLow: 0.011392228,
      confHigh: 0.053126808,
      group: 5,
    },
    {
      x: 26,
      predicted: 0.019329375,
      stdError: 0.652623954,
      confLow: 0.005455123,
      confHigh: 0.066143803,
      group: 1,
    },
    {
      x: 26,
      predicted: 0.036226206,
      stdError: 0.536800829,
      confLow: 0.012955635,
      confHigh: 0.097179537,
      group: 2,
    },
    {
      x: 26,
      predicted: 0.025020651,
      stdError: 0.332230551,
      confLow: 0.013204897,
      confHigh: 0.046906585,
      group: 3,
    },
    {
      x: 26,
      predicted: 0.013966995,
      stdError: 0.379028127,
      confLow: 0.006693687,
      confHigh: 0.028913378,
      group: 4,
    },
    {
      x: 26,
      predicted: 0.02284106,
      stdError: 0.405659796,
      confLow: 0.010444626,
      confHigh: 0.049218676,
      group: 5,
    },
    {
      x: 27,
      predicted: 0.017796873,
      stdError: 0.655318496,
      confLow: 0.0049907,
      confHigh: 0.061434868,
      group: 1,
    },
    {
      x: 27,
      predicted: 0.033399689,
      stdError: 0.542274392,
      confLow: 0.011796605,
      confHigh: 0.090924372,
      group: 2,
    },
    {
      x: 27,
      predicted: 0.02304753,
      stdError: 0.338151296,
      confLow: 0.012013432,
      confHigh: 0.04376727,
      group: 3,
    },
    {
      x: 27,
      predicted: 0.01285407,
      stdError: 0.383579847,
      confLow: 0.006102351,
      confHigh: 0.026873989,
      group: 4,
    },
    {
      x: 27,
      predicted: 0.021036112,
      stdError: 0.410796314,
      confLow: 0.009514294,
      confHigh: 0.045864766,
      group: 5,
    },
    {
      x: 28,
      predicted: 0.016383844,
      stdError: 0.660044095,
      confLow: 0.004547578,
      confHigh: 0.05725519,
      group: 1,
    },
    {
      x: 28,
      predicted: 0.030786664,
      stdError: 0.550144957,
      confLow: 0.010690334,
      confHigh: 0.085400147,
      group: 2,
    },
    {
      x: 28,
      predicted: 0.021226621,
      stdError: 0.347860647,
      confLow: 0.01084831,
      confHigh: 0.041120852,
      group: 3,
    },
    {
      x: 28,
      predicted: 0.011828761,
      stdError: 0.391530648,
      confLow: 0.005526229,
      confHigh: 0.02513749,
      group: 4,
    },
    {
      x: 28,
      predicted: 0.019370967,
      stdError: 0.419092977,
      confLow: 0.008613054,
      confHigh: 0.042983196,
      group: 5,
    },
    {
      x: 29,
      predicted: 0.015081283,
      stdError: 0.666757569,
      confLow: 0.00412759,
      confHigh: 0.053540803,
      group: 1,
    },
    {
      x: 29,
      predicted: 0.028372069,
      stdError: 0.560311523,
      confLow: 0.009643717,
      confHigh: 0.080514471,
      group: 2,
    },
    {
      x: 29,
      predicted: 0.019546697,
      stdError: 0.361053087,
      confLow: 0.009729075,
      confHigh: 0.038882303,
      group: 3,
    },
    {
      x: 29,
      predicted: 0.010884335,
      stdError: 0.402679238,
      confLow: 0.004973114,
      confHigh: 0.023654806,
      group: 4,
    },
    {
      x: 29,
      predicted: 0.017835228,
      stdError: 0.430367061,
      confLow: 0.007751498,
      confHigh: 0.040501196,
      group: 5,
    },
    {
      x: 30,
      predicted: 0.013880818,
      stdError: 0.675399641,
      confLow: 0.003732163,
      confHigh: 0.050234631,
      group: 1,
    },
    {
      x: 30,
      predicted: 0.026141742,
      stdError: 0.572651816,
      confLow: 0.008662005,
      confHigh: 0.076184419,
      group: 2,
    },
    {
      x: 30,
      predicted: 0.017997282,
      stdError: 0.377363491,
      confLow: 0.008671603,
      confHigh: 0.036977951,
      group: 3,
    },
    {
      x: 30,
      predicted: 0.010014548,
      stdError: 0.416769073,
      confLow: 0.004449502,
      confHigh: 0.022383377,
      group: 4,
    },
    {
      x: 30,
      predicted: 0.016419204,
      stdError: 0.444392014,
      confLow: 0.006938269,
      confHigh: 0.038355172,
      group: 5,
    },
    {
      x: 31,
      predicted: 0.012774671,
      stdError: 0.685897416,
      confLow: 0.003362279,
      confHigh: 0.047286035,
      group: 1,
    },
    {
      x: 31,
      predicted: 0.024082395,
      stdError: 0.587028767,
      confLow: 0.007748692,
      confHigh: 0.072336391,
      group: 2,
    },
    {
      x: 31,
      predicted: 0.016568609,
      stdError: 0.396407175,
      confLow: 0.0076872,
      confHigh: 0.03534566,
      group: 3,
    },
    {
      x: 31,
      predicted: 0.009213621,
      stdError: 0.433513463,
      confLow: 0.003960223,
      confHigh: 0.02128693,
      group: 4,
    },
    {
      x: 31,
      predicted: 0.015113876,
      stdError: 0.460916791,
      confLow: 0.006179661,
      confHigh: 0.036490427,
      group: 5,
    },
    {
      x: 32,
      predicted: 0.01175562,
      stdError: 0.698167191,
      confLow: 0.003018473,
      confHigh: 0.044650316,
      group: 1,
    },
    {
      x: 32,
      predicted: 0.022181581,
      stdError: 0.603296789,
      confLow: 0.006905541,
      confHigh: 0.068905694,
      group: 2,
    },
    {
      x: 32,
      predicted: 0.015251587,
      stdError: 0.41781056,
      confLow: 0.006782551,
      confHigh: 0.033934187,
      group: 3,
    },
    {
      x: 32,
      predicted: 0.0084762,
      stdError: 0.452617893,
      confLow: 0.003508348,
      confHigh: 0.020335025,
      group: 4,
    },
    {
      x: 32,
      predicted: 0.013910853,
      stdError: 0.479683109,
      confLow: 0.005479542,
      confHigh: 0.034860582,
      group: 5,
    },
    {
      x: 33,
      predicted: 0.010816969,
      stdError: 0.712117378,
      confLow: 0.002700841,
      confHigh: 0.042288199,
      group: 1,
    },
    {
      x: 33,
      predicted: 0.020427657,
      stdError: 0.621307354,
      confLow: 0.006132713,
      confHigh: 0.06583593,
      group: 2,
    },
    {
      x: 33,
      predicted: 0.014037759,
      stdError: 0.441230383,
      confLow: 0.005960261,
      confHigh: 0.03270196,
      group: 3,
    },
    {
      x: 33,
      predicted: 0.007797335,
      stdError: 0.473796963,
      confLow: 0.0030953,
      confHigh: 0.019502445,
      group: 4,
    },
    {
      x: 33,
      predicted: 0.012802343,
      stdError: 0.500438861,
      confLow: 0.00483954,
      confHigh: 0.033426771,
      group: 5,
    },
    {
      x: 34,
      predicted: 0.009952512,
      stdError: 0.727651334,
      confLow: 0.00240908,
      confHigh: 0.040165306,
      group: 1,
    },
    {
      x: 34,
      predicted: 0.01880975,
      stdError: 0.640913577,
      confLow: 0.005428989,
      confHigh: 0.063078264,
      group: 2,
    },
    {
      x: 34,
      predicted: 0.012919268,
      stdError: 0.466362958,
      confLow: 0.005219676,
      confHigh: 0.031615634,
      group: 3,
    },
    {
      x: 34,
      predicted: 0.007172448,
      stdError: 0.496785405,
      confLow: 0.002721108,
      confHigh: 0.0187685,
      group: 4,
    },
    {
      x: 34,
      predicted: 0.011781111,
      stdError: 0.522947218,
      confLow: 0.004259375,
      confHigh: 0.03215671,
      group: 5,
    },
  ]
}

function agePutinData() {
  return [
    {
      x: 16,
      predicted: 0.286087372,
      confLow: 0.1570248,
      confHigh: 0.46296971,
      group: 1,
    },
    {
      x: 16,
      predicted: 0.054370394,
      confLow: 0.019797815,
      confHigh: 0.140653224,
      group: 2,
    },
    {
      x: 16,
      predicted: 0.034322546,
      confLow: 0.012820948,
      confHigh: 0.08864573,
      group: 3,
    },
    {
      x: 16,
      predicted: 0.01385384,
      confLow: 0.002948105,
      confHigh: 0.062570718,
      group: 4,
    },
    {
      x: 17,
      predicted: 0.269211263,
      confLow: 0.153853093,
      confHigh: 0.427376969,
      group: 1,
    },
    {
      x: 17,
      predicted: 0.050201935,
      confLow: 0.018991174,
      confHigh: 0.126111697,
      group: 2,
    },
    {
      x: 17,
      predicted: 0.031639684,
      confLow: 0.01232585,
      confHigh: 0.078802477,
      group: 3,
    },
    {
      x: 17,
      predicted: 0.012749814,
      confLow: 0.002791974,
      confHigh: 0.05622093,
      group: 4,
    },
    {
      x: 18,
      predicted: 0.252977902,
      confLow: 0.150423884,
      confHigh: 0.393098828,
      group: 1,
    },
    {
      x: 18,
      predicted: 0.046337402,
      confLow: 0.018153343,
      confHigh: 0.113232404,
      group: 2,
    },
    {
      x: 18,
      predicted: 0.029160199,
      confLow: 0.011808867,
      confHigh: 0.070195639,
      group: 3,
    },
    {
      x: 18,
      predicted: 0.011732723,
      confLow: 0.002636555,
      confHigh: 0.05061816,
      group: 4,
    },
    {
      x: 19,
      predicted: 0.237405412,
      confLow: 0.14666566,
      confHigh: 0.360563625,
      group: 1,
    },
    {
      x: 19,
      predicted: 0.042756968,
      confLow: 0.017283912,
      confHigh: 0.101880176,
      group: 2,
    },
    {
      x: 19,
      predicted: 0.026869631,
      confLow: 0.01126899,
      confHigh: 0.062698049,
      group: 3,
    },
    {
      x: 19,
      predicted: 0.010795881,
      confLow: 0.002482285,
      confHigh: 0.045678117,
      group: 4,
    },
    {
      x: 20,
      predicted: 0.222505989,
      confLow: 0.142487364,
      confHigh: 0.330160052,
      group: 1,
    },
    {
      x: 20,
      predicted: 0.039441748,
      confLow: 0.016383647,
      confHigh: 0.091918874,
      group: 2,
    },
    {
      x: 20,
      predicted: 0.024754401,
      confLow: 0.010705914,
      confHigh: 0.056190493,
      group: 3,
    },
    {
      x: 20,
      predicted: 0.009933092,
      confLow: 0.002329655,
      confHigh: 0.041324432,
      group: 4,
    },
    {
      x: 21,
      predicted: 0.208286249,
      confLow: 0.137777161,
      confHigh: 0.30223089,
      group: 1,
    },
    {
      x: 21,
      predicted: 0.03637381,
      confLow: 0.015454849,
      confHigh: 0.083214499,
      group: 2,
    },
    {
      x: 21,
      predicted: 0.022801785,
      confLow: 0.010120341,
      confHigh: 0.050562218,
      group: 3,
    },
    {
      x: 21,
      predicted: 0.009138619,
      confLow: 0.002179204,
      confHigh: 0.037488326,
      group: 4,
    },
    {
      x: 22,
      predicted: 0.194747629,
      confLow: 0.132407509,
      confHigh: 0.277065501,
      group: 1,
    },
    {
      x: 22,
      predicted: 0.033536178,
      confLow: 0.014501664,
      confHigh: 0.075637362,
      group: 2,
    },
    {
      x: 22,
      predicted: 0.020999874,
      confLow: 0.009514283,
      confHigh: 0.045710925,
      group: 3,
    },
    {
      x: 22,
      predicted: 0.00840715,
      confLow: 0.002031505,
      confHigh: 0.034108153,
      group: 4,
    },
    {
      x: 23,
      predicted: 0.181886849,
      confLow: 0.126253539,
      confHigh: 0.254883577,
      group: 1,
    },
    {
      x: 23,
      predicted: 0.030912816,
      confLow: 0.013530269,
      confHigh: 0.069063537,
      group: 2,
    },
    {
      x: 23,
      predicted: 0.019337541,
      confLow: 0.008891306,
      confHigh: 0.041542441,
      group: 3,
    },
    {
      x: 23,
      predicted: 0.007733773,
      confLow: 0.001887158,
      confHigh: 0.031128897,
      group: 4,
    },
    {
      x: 24,
      predicted: 0.169696391,
      confLow: 0.119231212,
      confHigh: 0.235802719,
      group: 1,
    },
    {
      x: 24,
      predicted: 0.028488617,
      confLow: 0.012548856,
      confHigh: 0.063375849,
      group: 2,
    },
    {
      x: 24,
      predicted: 0.017804404,
      confLow: 0.008256631,
      confHigh: 0.037970281,
      group: 3,
    },
    {
      x: 24,
      predicted: 0.007113943,
      confLow: 0.001746767,
      confHigh: 0.028501633,
      group: 4,
    },
    {
      x: 25,
      predicted: 0.158165009,
      confLow: 0.111350486,
      confHigh: 0.219793495,
      group: 1,
    },
    {
      x: 25,
      predicted: 0.026249376,
      confLow: 0.011567331,
      confHigh: 0.058464611,
      group: 2,
    },
    {
      x: 25,
      predicted: 0.016390787,
      confLow: 0.007617002,
      confHigh: 0.034915247,
      group: 3,
    },
    {
      x: 25,
      predicted: 0.006543462,
      confLow: 0.001610925,
      confHigh: 0.026182992,
      group: 4,
    },
    {
      x: 26,
      predicted: 0.147278227,
      confLow: 0.10275582,
      confHigh: 0.206648501,
      group: 1,
    },
    {
      x: 26,
      predicted: 0.024181761,
      confLow: 0.010596737,
      confHigh: 0.054228241,
      group: 2,
    },
    {
      x: 26,
      predicted: 0.015087683,
      confLow: 0.006980297,
      confHigh: 0.032305193,
      group: 3,
    },
    {
      x: 26,
      predicted: 0.006018452,
      confLow: 0.001480194,
      confHigh: 0.024134635,
      group: 4,
    },
    {
      x: 27,
      predicted: 0.137018835,
      confLow: 0.093716718,
      confHigh: 0.196001735,
      group: 1,
    },
    {
      x: 27,
      predicted: 0.022273283,
      confLow: 0.009648469,
      confHigh: 0.050573812,
      group: 2,
    },
    {
      x: 27,
      predicted: 0.013886715,
      confLow: 0.006354919,
      confHigh: 0.03007495,
      group: 3,
    },
    {
      x: 27,
      predicted: 0.005535331,
      confLow: 0.001355087,
      confHigh: 0.02232276,
      group: 4,
    },
    {
      x: 28,
      predicted: 0.127367364,
      confLow: 0.084563257,
      confHigh: 0.187402556,
      group: 1,
    },
    {
      x: 28,
      predicted: 0.02051226,
      confLow: 0.008733406,
      confHigh: 0.047417478,
      group: 2,
    },
    {
      x: 28,
      predicted: 0.012780104,
      confLow: 0.005749081,
      confHigh: 0.028166346,
      group: 3,
    },
    {
      x: 28,
      predicted: 0.005090793,
      confLow: 0.001236046,
      confHigh: 0.020717633,
      group: 4,
    },
    {
      x: 29,
      predicted: 0.118302537,
      confLow: 0.075606784,
      confHigh: 0.180403517,
      group: 1,
    },
    {
      x: 29,
      predicted: 0.018887781,
      confLow: 0.007861128,
      confHigh: 0.044684703,
      group: 2,
    },
    {
      x: 29,
      predicted: 0.011760625,
      confLow: 0.005170122,
      confHigh: 0.026528208,
      group: 3,
    },
    {
      x: 29,
      predicted: 0.004681787,
      confLow: 0.001123433,
      confHigh: 0.019293158,
      group: 4,
    },
    {
      x: 30,
      predicted: 0.109801682,
      confLow: 0.067088401,
      confHigh: 0.174619157,
      group: 1,
    },
    {
      x: 30,
      predicted: 0.01738967,
      confLow: 0.007039336,
      confHigh: 0.042310218,
      group: 2,
    },
    {
      x: 30,
      predicted: 0.010821579,
      confLow: 0.004624001,
      confHigh: 0.025116209,
      group: 3,
    },
    {
      x: 30,
      predicted: 0.0043055,
      confLow: 0.001017517,
      confHigh: 0.018026482,
      group: 4,
    },
    {
      x: 31,
      predicted: 0.101841116,
      confLow: 0.059165086,
      confHigh: 0.169745777,
      group: 1,
    },
    {
      x: 31,
      predicted: 0.016008445,
      confLow: 0.006273549,
      confHigh: 0.040237669,
      group: 2,
    },
    {
      x: 31,
      predicted: 0.009956757,
      confLow: 0.00411502,
      confHigh: 0.023892542,
      group: 3,
    },
    {
      x: 31,
      predicted: 0.003959336,
      confLow: 0.000918469,
      confHigh: 0.016897627,
      group: 4,
    },
    {
      x: 32,
      predicted: 0.094396488,
      confLow: 0.051919813,
      confHigh: 0.165555904,
      group: 1,
    },
    {
      x: 32,
      predicted: 0.014735282,
      confLow: 0.005567064,
      confHigh: 0.038419016,
      group: 2,
    },
    {
      x: 32,
      predicted: 0.009160409,
      confLow: 0.003645783,
      confHigh: 0.022825389,
      group: 3,
    },
    {
      x: 32,
      predicted: 0.003640901,
      confLow: 0.000826361,
      confHigh: 0.015889165,
      group: 4,
    },
    {
      x: 33,
      predicted: 0.087443082,
      confLow: 0.045379979,
      confHigh: 0.161883202,
      group: 1,
    },
    {
      x: 33,
      predicted: 0.01356198,
      confLow: 0.004921128,
      confHigh: 0.036813724,
      group: 2,
    },
    {
      x: 33,
      predicted: 0.008427211,
      confLow: 0.003217333,
      confHigh: 0.021888273,
      group: 3,
    },
    {
      x: 33,
      predicted: 0.003347991,
      confLow: 0.000741172,
      confHigh: 0.014985912,
      group: 4,
    },
    {
      x: 34,
      predicted: 0.080956088,
      confLow: 0.039535277,
      confHigh: 0.158606902,
      group: 1,
    },
    {
      x: 34,
      predicted: 0.012480918,
      confLow: 0.004335243,
      confHigh: 0.035387873,
      group: 2,
    },
    {
      x: 34,
      predicted: 0.007752239,
      confLow: 0.002829405,
      confHigh: 0.021059344,
      group: 3,
    },
    {
      x: 34,
      predicted: 0.003078573,
      confLow: 0.000662791,
      confHigh: 0.014174664,
      group: 4,
    },
  ]
}

function agePutinEnvironmentData() {
  return [
    {
      x: 16,
      predicted: 0.053242111,
      confLow: 0.024246617,
      confHigh: 0.112900049,
      group: 1,
    },
    {
      x: 16,
      predicted: 0.030791038,
      confLow: 0.012287454,
      confHigh: 0.075042011,
      group: 2,
    },
    {
      x: 16,
      predicted: 0.03904457,
      confLow: 0.018152059,
      confHigh: 0.081976077,
      group: 3,
    },
    {
      x: 16,
      predicted: 0.036299812,
      confLow: 0.015634346,
      confHigh: 0.082005277,
      group: 4,
    },
    {
      x: 17,
      predicted: 0.054041187,
      confLow: 0.02564671,
      confHigh: 0.110313147,
      group: 1,
    },
    {
      x: 17,
      predicted: 0.031264288,
      confLow: 0.012936619,
      confHigh: 0.073620638,
      group: 2,
    },
    {
      x: 17,
      predicted: 0.039639486,
      confLow: 0.019273362,
      confHigh: 0.079775871,
      group: 3,
    },
    {
      x: 17,
      predicted: 0.036854511,
      confLow: 0.016581141,
      confHigh: 0.079901674,
      group: 4,
    },
    {
      x: 18,
      predicted: 0.054851562,
      confLow: 0.02706743,
      confHigh: 0.107989969,
      group: 1,
    },
    {
      x: 18,
      predicted: 0.031744573,
      confLow: 0.013588001,
      confHigh: 0.072382072,
      group: 2,
    },
    {
      x: 18,
      predicted: 0.040243088,
      confLow: 0.020424925,
      confHigh: 0.077764138,
      group: 3,
    },
    {
      x: 18,
      predicted: 0.037417357,
      confLow: 0.017552527,
      confHigh: 0.077979496,
      group: 4,
    },
    {
      x: 19,
      predicted: 0.055673373,
      confLow: 0.028493215,
      confHigh: 0.105953454,
      group: 1,
    },
    {
      x: 19,
      predicted: 0.032231991,
      confLow: 0.014234569,
      confHigh: 0.071337655,
      group: 2,
    },
    {
      x: 19,
      predicted: 0.040855489,
      confLow: 0.021596225,
      confHigh: 0.075956579,
      group: 3,
    },
    {
      x: 19,
      predicted: 0.03798846,
      confLow: 0.018540521,
      confHigh: 0.076251272,
      group: 4,
    },
    {
      x: 20,
      predicted: 0.056506761,
      confLow: 0.029904499,
      confHigh: 0.104231071,
      group: 1,
    },
    {
      x: 20,
      predicted: 0.03272664,
      confLow: 0.014868142,
      confHigh: 0.070500351,
      group: 2,
    },
    {
      x: 20,
      predicted: 0.041476807,
      confLow: 0.022773028,
      confHigh: 0.074373121,
      group: 3,
    },
    {
      x: 20,
      predicted: 0.03856793,
      confLow: 0.019534639,
      confHigh: 0.07473252,
      group: 4,
    },
    {
      x: 21,
      predicted: 0.057351867,
      confLow: 0.031277529,
      confHigh: 0.102855013,
      group: 1,
    },
    {
      x: 21,
      predicted: 0.03322862,
      confLow: 0.015479497,
      confHigh: 0.069884646,
      group: 2,
    },
    {
      x: 21,
      predicted: 0.042107159,
      confLow: 0.023936767,
      confHigh: 0.073038555,
      group: 3,
    },
    {
      x: 21,
      predicted: 0.03915588,
      confLow: 0.020521567,
      confHigh: 0.073442091,
      group: 4,
    },
    {
      x: 22,
      predicted: 0.058208832,
      confLow: 0.03258462,
      confHigh: 0.101861945,
      group: 1,
    },
    {
      x: 22,
      predicted: 0.033738031,
      confLow: 0.01605861,
      confHigh: 0.069506333,
      group: 2,
    },
    {
      x: 22,
      predicted: 0.042746664,
      confLow: 0.025064157,
      confHigh: 0.071982939,
      group: 3,
    },
    {
      x: 22,
      predicted: 0.039752422,
      confLow: 0.021484988,
      confHigh: 0.072402366,
      group: 4,
    },
    {
      x: 23,
      predicted: 0.059077799,
      confLow: 0.033795083,
      confHigh: 0.101292078,
      group: 1,
    },
    {
      x: 23,
      predicted: 0.034254974,
      confLow: 0.016595046,
      confHigh: 0.069382134,
      group: 2,
    },
    {
      x: 23,
      predicted: 0.043395441,
      confLow: 0.026127363,
      confHigh: 0.071241449,
      group: 3,
    },
    {
      x: 23,
      predicted: 0.040357671,
      confLow: 0.022405693,
      confHigh: 0.07163916,
      group: 4,
    },
    {
      x: 24,
      predicted: 0.059958913,
      confLow: 0.034877017,
      confHigh: 0.101187374,
      group: 1,
    },
    {
      x: 24,
      predicted: 0.034779554,
      confLow: 0.017078489,
      confHigh: 0.069529184,
      group: 2,
    },
    {
      x: 24,
      predicted: 0.044053612,
      confLow: 0.027095091,
      confHigh: 0.070853293,
      group: 3,
    },
    {
      x: 24,
      predicted: 0.040971742,
      confLow: 0.023262159,
      confHigh: 0.071181158,
      group: 4,
    },
    {
      x: 25,
      predicted: 0.060852319,
      confLow: 0.035799964,
      confHigh: 0.101588875,
      group: 1,
    },
    {
      x: 25,
      predicted: 0.035311872,
      confLow: 0.017499391,
      confHigh: 0.069964397,
      group: 2,
    },
    {
      x: 25,
      predicted: 0.044721298,
      confLow: 0.027934974,
      confHigh: 0.070859344,
      group: 3,
    },
    {
      x: 25,
      predicted: 0.041594751,
      confLow: 0.024031734,
      confHigh: 0.071058744,
      group: 4,
    },
    {
      x: 26,
      predicted: 0.061758162,
      confLow: 0.036538126,
      confHigh: 0.102533471,
      group: 1,
    },
    {
      x: 26,
      predicted: 0.035852036,
      confLow: 0.017849648,
      confHigh: 0.070703799,
      group: 2,
    },
    {
      x: 26,
      predicted: 0.045398624,
      confLow: 0.028617226,
      confHigh: 0.071298481,
      group: 3,
    },
    {
      x: 26,
      predicted: 0.042226817,
      confLow: 0.024692472,
      confHigh: 0.071302173,
      group: 4,
    },
    {
      x: 27,
      predicted: 0.062676589,
      confLow: 0.037073448,
      confHigh: 0.104050782,
      group: 1,
    },
    {
      x: 27,
      predicted: 0.036400151,
      confLow: 0.018123215,
      confHigh: 0.071761921,
      group: 2,
    },
    {
      x: 27,
      predicted: 0.046085713,
      confLow: 0.029118956,
      confHigh: 0.072203287,
      group: 3,
    },
    {
      x: 27,
      predicted: 0.042868057,
      confLow: 0.025225417,
      confHigh: 0.071939299,
      group: 4,
    },
    {
      x: 28,
      predicted: 0.063607748,
      confLow: 0.037397766,
      confHigh: 0.106160983,
      group: 1,
    },
    {
      x: 28,
      predicted: 0.036956324,
      confLow: 0.018316539,
      confHigh: 0.073151368,
      group: 2,
    },
    {
      x: 28,
      predicted: 0.046782691,
      confLow: 0.02942783,
      confHigh: 0.073596371,
      group: 3,
    },
    {
      x: 28,
      predicted: 0.043518593,
      confLow: 0.025616885,
      confHigh: 0.072993289,
      group: 4,
    },
    {
      x: 29,
      predicted: 0.064551789,
      confLow: 0.037513345,
      confHigh: 0.108874208,
      group: 1,
    },
    {
      x: 29,
      predicted: 0.037520664,
      confLow: 0.018428744,
      confHigh: 0.074882638,
      group: 2,
    },
    {
      x: 29,
      predicted: 0.047489685,
      confLow: 0.029543735,
      confHigh: 0.075488708,
      group: 3,
    },
    {
      x: 29,
      predicted: 0.044178545,
      confLow: 0.025860129,
      confHigh: 0.074480959,
      group: 4,
    },
    {
      x: 30,
      predicted: 0.06550886,
      confLow: 0.03743174,
      confHigh: 0.112191633,
      group: 1,
    },
    {
      x: 30,
      predicted: 0.038093282,
      confLow: 0.018461539,
      confHigh: 0.076964211,
      group: 2,
    },
    {
      x: 30,
      predicted: 0.048206823,
      confLow: 0.02947782,
      confHigh: 0.07788057,
      group: 3,
    },
    {
      x: 30,
      predicted: 0.044848037,
      confLow: 0.025955891,
      confHigh: 0.076412215,
      group: 4,
    },
    {
      x: 31,
      predicted: 0.066479114,
      confLow: 0.037171442,
      confHigh: 0.116107758,
      group: 1,
    },
    {
      x: 31,
      predicted: 0.038674287,
      confLow: 0.018418873,
      confHigh: 0.07940288,
      group: 2,
    },
    {
      x: 31,
      predicted: 0.048934235,
      confLow: 0.029249491,
      confHigh: 0.080764516,
      group: 3,
    },
    {
      x: 31,
      predicted: 0.04552719,
      confLow: 0.02591171,
      confHigh: 0.078790728,
      group: 4,
    },
    {
      x: 32,
      predicted: 0.0674627,
      confLow: 0.036755054,
      confHigh: 0.120613146,
      group: 1,
    },
    {
      x: 32,
      predicted: 0.039263792,
      confLow: 0.018306443,
      confHigh: 0.082204233,
      group: 2,
    },
    {
      x: 32,
      predicted: 0.049672049,
      confLow: 0.028882626,
      confHigh: 0.084129137,
      group: 3,
    },
    {
      x: 32,
      predicted: 0.046216131,
      confLow: 0.025740302,
      confHigh: 0.081615537,
      group: 4,
    },
    {
      x: 33,
      predicted: 0.068459771,
      confLow: 0.036206701,
      confHigh: 0.125696913,
      group: 1,
    },
    {
      x: 33,
      predicted: 0.03986191,
      confLow: 0.018131121,
      confHigh: 0.085373198,
      group: 2,
    },
    {
      x: 33,
      predicted: 0.050420398,
      confLow: 0.028402203,
      confHigh: 0.087962392,
      group: 3,
    },
    {
      x: 33,
      predicted: 0.046914984,
      confLow: 0.025457531,
      confHigh: 0.084883045,
      group: 4,
    },
    {
      x: 34,
      predicted: 0.069470481,
      confLow: 0.035550038,
      confHigh: 0.131348602,
      group: 1,
    },
    {
      x: 34,
      predicted: 0.040468755,
      confLow: 0.017900419,
      confHigh: 0.088914559,
      group: 2,
    },
    {
      x: 34,
      predicted: 0.051179415,
      confLow: 0.027831931,
      confHigh: 0.092253922,
      group: 3,
    },
    {
      x: 34,
      predicted: 0.047623878,
      confLow: 0.02508051,
      confHigh: 0.088588882,
      group: 4,
    },
  ]
}
