exports.categoryIds = [187, 191, 195]

exports.categoryNames = ["protest-de", "protest-en", "protest-ru"]

exports.metaInfos = [
  {
    langId: "de",
    title: "Protest in Russland",
    description: "Ein dekoder-Special",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/09/protest_social_default_de.png",
  },
  {
    langId: "en",
    title: "Protest in Russia",
    description: "A dekoder-Special",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/09/protest_social_default_en.png",
  },
  {
    langId: "ru",
    title: "Протесты в России",
    description: "Спецпроект dekoder.org",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/09/protest_social_default_ru.png",
  },
]

exports.siteMetadata = {
  title: `Proteste in Russland`,
  description: `Ein dekoder-Special`,
  siteUrl: `https://protest.dekoder.org`,
  author: `dekoder.org`,
}

exports.pathPrefix = `/`
exports.faviconPath = `src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout.js")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = `UA-66707787-3`
