import React from "react"
import InfographicOptions, {
  defaultOptions,
  langs,
} from "./infographic-options"
import Field from "./components/bar-field"

import "./bar-infographic.scss"
// import Highcharts from 'highcharts';

const BarInfographic = ({ data, options, lang, selectedSeries, labels }) => {
  const fields = prepareData(data)
  options = new InfographicOptions({
    ...defaultOptions,
    ...options,
  })
  lang = lang || langs[0]
  const series = selectedSeries || 0
  const sortedFields = fields
    .slice()
    .sort((a, b) => b.values[series].value - a.values[series].value)

  const stackedMax = fields
    .map((f) => f.values.map((v) => v.value).reduce((a, b) => a + b))
    .reduce((a, b) => Math.max(a, b))

  const maxVal = options.barMax
    ? options.barMax
    : options.stacked
    ? stackedMax
    : fields
        .map((f) =>
          f.values.map((v) => v.value).reduce((a, b) => Math.max(a, b))
        ) // overall max
        .reduce((a, b) => Math.max(a, b))
  // console.log(maxVal);
  const overallSum = fields
    // .map(f => f.values[series].value)
    .map((f) => f.values.map((v) => v.value).reduce((a, b) => a + b)) // total sum
    .reduce((a, b) => a + b)
  const totalHeight = options.horizontal
    ? `${options.columnHeight}px`
    : `${fields.length * (options.responsiveBarHeight + options.barSpacing)}px`

  return (
    <div className={getStateClasses(options)} style={{ height: totalHeight }}>
      {fields.map((f, i) => {
        const rankingPos = sortedFields.indexOf(f)
        return (
          <Field
            key={`${f.name[lang.id]}_${i}`}
            lang={lang}
            rankingPos={rankingPos}
            i={i}
            data={f}
            options={options}
            maxVal={maxVal}
            overallSum={overallSum}
            selectedSeries={series}
            sortedFields={sortedFields}
            barLabels={labels}
          />
        )
      })}
    </div>
  )
}

export default BarInfographic

function getStateClasses(options) {
  let result = "bar-infographic"
  if (options.barOffset) result += " bar-offset"
  if (options.horizontal) result += " horizontal"
  result += options.split ? " split" : " no-split"
  if (options.stacked) result += " stacked"
  return result
}

function getJsDateFromExcel(excelDate) {
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000)
}

function isDate(string) {
  return !isNaN(new Date(string).getYear())
}

function prepareData(data) {
  const newData = []
  data.forEach((entry) => {
    const newEntry = {}
    newEntry.values = []
    newEntry.name = {}
    newEntry.label = {}
    for (let key in entry) {
      if (key === "name") newEntry.name.de = entry[key]
      else if (key.match(/name_[a-z]+/)) {
        const lng = key.split("_")[1]
        newEntry.name[lng] = entry[key]
      } else if (key === "label") newEntry.label.de = entry[key]
      else if (key.match(/name_[a-z]+/)) {
        const lng = key.split("_")[1]
        newEntry.label[lng] = entry[key]
      } else if (key === "marked") newEntry.marked = entry[key]
      else if (key === "fixedLast") newEntry.fixedLast = entry[key]
      else {
        let x
        if (parseInt(key).toString().length === 5) x = getJsDateFromExcel(key)
        else if (isDate(key)) x = new Date(key)
        else if (typeof key === "string") x = key
        else return
        newEntry.values.push({
          x: isDate(x) ? x.getFullYear() : x,
          date: isDate(x) ? x : null,
          value: entry[key],
        })
      }
    }
    newData.push(newEntry)
  })

  return newData
}
