import React from "react"
import App from "./components/app"
import * as metaData from "../meta-data-es6"

const Layout = props => (
  <App
    {...props}
    metaData={metaData}
  />
)

export default Layout
