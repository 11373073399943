import React, { useContext } from "react"
import { ContentsContext } from "gatsby-source-dek-wp"
import PageTeaser from "../page-teaser"

const ContentTile = (props) => {
  const { id, slug } = props
  const pages = useContext(ContentsContext)
  const page = pages.find((p) => p.postId === id || p.slug === slug)
  if (!page) return null
  return (
    <div className="content-tile">
      <PageTeaser page={page} />
    </div>
  )
}

export default ContentTile
