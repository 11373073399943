import * as turf from "@turf/turf"

import ruRegionsGeoJson from "./data/ru-regions.json"
import { enrichGeoJsonWithData } from "./data-converter"
import lankinaDataRaw from "./data/lankina.csv"
import ruPopuplation from "./data/ru-population2019.json"

import navalny_26_03_2017 from "./data/navalny-26-03-2017_cleaned.json"
import navalny_12_06_2017 from "./data/navalny-12-06-2017_cleaned.json"

import { getBounds } from "./map-hooks"
import { dataToGeoJson } from "./data-converter"

const MOSCOW_GEOJSON = ruRegionsGeoJson.features.find(
  (f) => f.properties.iso_3166_2 === "RU-MOS"
)
const MOSCOW_POLYGON_FUZZY = turf.transformScale(MOSCOW_GEOJSON, 1.5)

// http://colorbrewer2.org/#type=sequential&scheme=OrRd&n=5
// const CHOROPLETH_COLORS_7 = ['#fef0d9','#fdd49e','#fdbb84','#fc8d59','#ef6548','#d7301f','#990000'];

const CHOROPLETH_COLORS_5_RED = [
  "#fef0d9",
  "#fdcc8a",
  "#fc8d59",
  "#e34a33",
  "#b30000",
]
const CHOROPLETH_COLORS_5_ORANGE = [
  "#ffffd4",
  "#fed98e",
  "#fe9929",
  "#d95f0e",
  "#993404",
]
const CHOROPLETH_COLORS_5_GREEN = [
  "#ffffcc",
  "#c2e699",
  "#78c679",
  "#31a354",
  "#006837",
]
const CHOROPLETH_COLORS_5_BLUE = [
  "#ffffcc",
  "#a1dab4",
  "#41b6c4",
  "#2c7fb8",
  "#253494",
]
const CHOROPLETH_COLORS_5_VIOLETTE = [
  "#feebe2",
  "#fbb4b9",
  "#f768a1",
  "#c51b8a",
  "#7a0177",
]

const navalnyCategoryColors = [
  {
    name: "Согласовали",
    color: "#297373",
    orderId: 2,
    showAs: {
      de: "genehmigt",
      en: "approved",
      ru: "согласовали",
    },
  },
  {
    name: "Не согласовали",
    color: "#DB5461",
    orderId: 0,
    showAs: {
      de: "nicht genehmigt",
      en: "not approved",
      ru: "не согласовали",
    },
  },
  {
    name: "Согласовали в другом месте",
    color: "#E9D758",
    orderId: 1,
    showAs: {
      de: "an anderem Ort genehmigt",
      en: "approved at a different location",
      ru: "cогласовали в другом месте",
    },
  },
  {
    name: "Гайд-парк",
    color: "#1B98E0",
    orderId: 3,
    showAs: {
      de: '"Hyde Park"*',
      en: '"Hyde Park"*',
      ru: "«Гайд-парк»*",
    },
  },
  {
    name: "default",
    color: "#CCCCCC",
    default: true,
    orderId: 4,
    showAs: {
      de: "unklar",
      en: "unknown",
      ru: "другое",
    },
  },
]

const navalnyColumns = [
  {
    name: "Число участников: нижняя граница",
    showAs: {
      de: "Teilnehmer (min)",
      en: "Participants (min)",
      ru: "Число участников (нижняя граница)",
    },
  },
  {
    name: "Число участников: верхняя граница",
    showAs: {
      de: "Teilnehmer (max)",
      en: "Participants (min)",
      ru: "Число участников (верхняя граница)",
    },
  },
  {
    name: "Число задержанных",
    showAs: {
      de: "Festnahmen",
      en: "Detentions",
      ru: "Число задержанных",
    },
  },
  {
    name: "Согласование",
    showAs: {
      de: "Genehmigt",
      en: "Approved",
      ru: "Согласование",
    },
    transform: (v, lang) => {
      return localizedApproval(v, lang.id)
    },
  },
]

function localizedApproval(v, langId) {
  const match = navalnyCategoryColors.find((c) => c.name === v)
  const defaultValue = navalnyCategoryColors.find((c) => c.default)
  return match ? match.showAs[langId] : defaultValue.showAs[langId]
}

const steckbriefCategoryColors = [
  {
    name: ["Politischer Protest", "political protest", "политический протест"],
    color: CHOROPLETH_COLORS_5_VIOLETTE[3],
    orderId: 0,
    showAs: {
      de: "Politischer Protest",
      en: "Political protest",
      ru: "Политический протест",
    },
  },
  {
    name: ["Sozialprotest", "social protest", "cоциальный протест"],
    color: CHOROPLETH_COLORS_5_BLUE[3],
    orderId: 1,
    showAs: {
      de: "Sozialprotest",
      en: "Social protest",
      ru: "Социальный протест",
    },
  },
  {
    name: ["Umweltprotest", "environmental protest", "экологический протест"],
    color: CHOROPLETH_COLORS_5_GREEN[3],
    orderId: 2,
    showAs: {
      de: "Umweltprotest",
      en: "Environmental protest",
      ru: "Экологический протест",
    },
  },
  {
    name: [
      "Nationalistischer Protest",
      "nationalist protest",
      "националистический протест",
    ],
    color: CHOROPLETH_COLORS_5_RED[3],
    orderId: 3,
    showAs: {
      de: "Nationalistischer Protest",
      en: "Nationalist protest",
      ru: "Националистический протест",
    },
  },
  {
    name: ["Mischform", "hybrid type", "гибридный тип"],
    color: "#E9D758",
    orderId: 4,
    showAs: {
      de: "Mischform",
      en: "Hybrid type",
      ru: "гибридный тип",
    },
  },
]

export function sources(lang) {
  return [
    {
      id: "navalny-26-03-2017",
      name: {
        de: "26. März 2017",
        en: "March 26, 2017",
        ru: "26 марта 2017 г.",
      },
      // path: '/map-data/26-03-2017.csv',
      data: navalny_26_03_2017,
      sourceLang: "ru",
      sort: "orderId",
      keys: {
        primary: "city",
        category: "Согласование",
      },
      categoryColors: navalnyCategoryColors,
      columns: navalnyColumns,
      customBounds: getBounds(dataToGeoJson(navalny_12_06_2017)),
    },
    {
      id: "navalny-12-06-2017",
      name: {
        de: "12. Juni 2017",
        en: "June 12, 2017",
        ru: "12 июня 2017 г.",
      },
      // path: '/map-data/12-june.csv',
      data: navalny_12_06_2017.filter(
        (d) => d["Статус акции"] !== "Не проводилась"
      ),
      sourceLang: "ru",
      sort: "orderId",
      keys: {
        primary: "city",
        category: "Согласование",
      },
      categoryColors: navalnyCategoryColors,
      columns: navalnyColumns,
    },
    {
      id: "steckbriefe",
      name: {
        de: "alle",
        en: "all",
        ru: "все",
      },
      useMapPoints: true,
      sort: "orderId",
      categoryColors: steckbriefCategoryColors,
      onlyOnePopup: true,
    },
    {
      id: "steckbriefe-msc-only",
      name: {
        de: "nur Moskau",
        en: "only Moscow",
        ru: "Москва",
      },
      useMapPoints: true,
      mapPointFilter: (p) =>
        turf.inside(turf.point([p.lon, p.lat]), MOSCOW_POLYGON_FUZZY),
      sort: "orderId",
      categoryColors: steckbriefCategoryColors,
      onlyOnePopup: true,
    },
    getLankinaSourceObject(
      lang,
      "lankinaAll",
      { de: "alle", en: "all", ru: "все" },
      undefined,
      undefined,
      {
        barChartCategories: [
          { keys: ["Pol"], showAs: lankinaCatNames("Pol") },
          { keys: ["Econ"], showAs: lankinaCatNames("Econ") },
          { keys: ["Social"], showAs: lankinaCatNames("Social") },
          { keys: ["Cultur"], showAs: lankinaCatNames("Cultur") },
          { keys: ["Legal"], showAs: lankinaCatNames("Legal") },
          { keys: ["Environ"], showAs: lankinaCatNames("Environ") },
        ],
      }
    ),
    getLankinaSourceObject(
      lang,
      "lankinaPol",
      lankinaCatNames("Pol"),
      ["Pol"],
      CHOROPLETH_COLORS_5_VIOLETTE
    ),
    getLankinaSourceObject(
      lang,
      "lankinaEcon",
      lankinaCatNames("Econ"),
      ["Econ"],
      CHOROPLETH_COLORS_5_BLUE
    ),
    getLankinaSourceObject(
      lang,
      "lankinaSocial",
      lankinaCatNames("Social"),
      ["Social"],
      CHOROPLETH_COLORS_5_ORANGE
    ),
    getLankinaSourceObject(
      lang,
      "lankinaLegal",
      lankinaCatNames("Legal"),
      ["Legal"],
      CHOROPLETH_COLORS_5_BLUE
    ),
    getLankinaSourceObject(
      lang,
      "lankinaEnviron",
      lankinaCatNames("Environ"),
      ["Environ"],
      CHOROPLETH_COLORS_5_GREEN
    ),
    getLankinaSourceObject(
      lang,
      "lankinaCultur",
      lankinaCatNames("Cultur"),
      ["Cultur"],
      CHOROPLETH_COLORS_5_VIOLETTE
    ),
    // getLankinaSourceObject( lang, 'lankinaCivic', 'Civic', ['Civic', 'Civic+'] ),
  ]
}

function lankinaCatNames(id) {
  const categories = {
    Pol: { de: "Politik", en: "political", ru: "политические" },
    Econ: { de: "Wirtschaft", en: "economic", ru: "экономические" },
    Social: { de: "Soziales", en: "social", ru: "социальные" },
    Legal: { de: "Recht", en: "legal", ru: "правовые" },
    Environ: { de: "Umwelt", en: "environmental", ru: "экологические" },
    Cultur: { de: "Kultur", en: "cultural", ru: "культурные" },
  }
  return categories.hasOwnProperty(id) ? categories[id] : id
}

function getLankinaSourceObject(
  lang,
  id,
  name,
  filterKeys = [],
  colors,
  extraOptions
) {
  function hasFilterKey(d) {
    return filterKeys.reduce((acc, curr) => !!d[curr], false)
  }
  return {
    ...extraOptions,
    id: id,
    name: name,
    data: filterKeys.length
      ? lankinaDataRaw.filter(hasFilterKey)
      : lankinaDataRaw,
    type: "fill",
    useSubtitle: true,
    keys: {
      primary: `name_${lang.id}`,
      category: "category",
      range: "Date", // makes range filter available,
      rawData: "reg_code", // makes raw data viewer available
    },
    sort: "orderId",
    legendTitle: {
      de: "Normierte Protesthäufigkeit",
      en: "Standardised protest frequency",
      ru: "Относительная частота протестов",
    },
    legendSubtitle: {
      de: "Proteste pro 1 Mio Einwohner im gewählten Zeitraum",
      en: "Protest per million inhabitants in the time period",
      ru: "Протесты на один миллион жителей за определенный период времени",
    },
    columns: [
      {
        name: "count",
        showAs: {
          de: "Proteste",
          en: "Protests",
          ru: "Количество протестов",
        },
      },
      // { name: 'category', showAs: 'Kategorie' },
      {
        name: "population",
        showAs: {
          de: "Einwohner",
          en: "Popuplation",
          ru: "Число жителей",
        },
        transform: (v) =>
          (Math.round(v / 1000) * 1000).toLocaleString(
            lang ? lang.locale : "en-EN"
          ),
      },
      {
        name: "countPerMillion",
        showAs: {
          de: "Normierte Protesthäufigkeit*",
          en: "Standardised protest frequency*",
          ru: "Относительная частота протестов*",
        },
        transform: (v) =>
          (Math.round(v * 10) / 10).toLocaleString(
            lang ? lang.locale : "en-EN"
          ),
      },
    ],
    rawViewColumns: [
      { name: "Date", showAs: "Date" },
      { name: "protesters", showAs: "protesters" },
      { name: "Link", showAs: "Link" },
    ],
    categoryColors: (geoJson) => {
      const data =
        typeof geoJson === "object"
          ? geoJson.features.map((f) => f.properties)
          : []
      const result = getChoroplethBins(data, "countPerMillion", 5, colors)
      return result
    },
    customGeoJsonConverter: (rawData) => {
      const parsedLankinaData = parseLankinaData(rawData)

      return enrichGeoJsonWithData(ruRegionsGeoJson, [
        {
          data: ruPopuplation,
          matchKeyGeoJson: "iso_3166_2",
          matchKeyData: "reg_code",
          filterGeoJson: false,
        },
        {
          data: parsedLankinaData,
          matchKeyGeoJson: "iso_3166_2",
          matchKeyData: "regCode",
          filterGeoJson: false,
        },
      ])
    },
  }
}

function rangeName(range = [], isFirstRange, lang) {
  const minVal = range[0] === 0 && isFirstRange ? ">0" : range[0] // .toLocaleString(lang.id)
  const maxVal = range[1] - 1 // - .1;
  return `${minVal}–${maxVal}`
}

function getChoroplethBins(
  data,
  valueKey,
  binCount = 5,
  colors = CHOROPLETH_COLORS_5_RED
) {
  // console.log('GET CHOROPLETH BINS', data);
  const values = data.map((d) => d[valueKey]).filter((v) => !!v) // take into account only data with values
  const ROUND_DECIMAL_PLACES = 0
  const roundFactor =
    ROUND_DECIMAL_PLACES === 0 ? 1 : 10 ** ROUND_DECIMAL_PLACES
  const min = Math.floor(Math.min(...values) * roundFactor) / roundFactor
  const max = Math.floor(Math.max(...values) * roundFactor + 1) / roundFactor
  const delta = max - min
  const step = Math.round(delta / binCount)

  return [...Array(binCount).keys()].map((i, index, arr) => {
    const range = [
      i * step + min,
      index === arr.length - 1 // last range
        ? max + 1
        : (i + 1) * step + min,
    ]
    const name = rangeName(range, index === 0)
    return {
      orderId: i,
      range: range,
      name: name,
      color: colors[i],
    }
  })
}

function enrichWithChoroplethCategories(data, valueKey, binCount) {
  const bins = getChoroplethBins(data, valueKey, binCount)
  // console.log(bins);

  const result = data.map((d) => {
    const category = bins.reduce((acc, b, index) => {
      const name = rangeName(b.range, index === 0)
      return b.range[0] <= d[valueKey] && d[valueKey] <= b.range[1] ? name : acc
    }, "DEF")
    return { ...d, category }
  })
  return result
}

function parseLankinaData(rawData) {
  let result = rawData
    .slice()
    .filter((r) => r.reg_code !== "UA-43" && r.reg_code !== "UA-40") // Crimea & Sevastopol
    .reduce((acc, curr) => {
      const popuplationMatch = ruPopuplation.find(
        (p) => p.reg_code === curr.reg_code
      )
      if (!popuplationMatch) console.log(curr.reg_code)
      const popuplation = popuplationMatch ? popuplationMatch.population : 1
      const row = acc.find((r) => r.regCode === curr.reg_code) || {
        regCode: curr.reg_code,
        count: 0,
        popuplation,
      }
      const count = row.count + 1
      const countPerMillion = (count / row.popuplation) * 1000000 // 100000;
      const updatedRow = { ...row, count, countPerMillion }
      return [...acc.filter((r) => r.regCode !== curr.reg_code), updatedRow]
    }, [])
  result = enrichWithChoroplethCategories(result, "countPerMillion")
  return result
}
