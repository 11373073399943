import React, { useState, useCallback, useEffect } from "react"
import { Waypoint } from "react-waypoint"
import Chart from "../chart"
import "./split-view.scss"

export const SplitView = ({ children }) => {
  const [currentChart, setCurrentChart] = useState({ props: {} })
  const getLastChart = useCallback(
    (component) => {
      const currentIndex = children.indexOf(component)
      for (let i = currentIndex; i >= 0; i--) {
        const child = children[i]
        if (child.props.data) {
          return child
        }
      }
      return false
    },
    [children]
  )

  const handleEnter = useCallback(
    (component) => {
      const newChart = getLastChart(component)
      if (newChart) setCurrentChart(newChart)
    },
    [getLastChart]
  )

  useEffect(() => {
    handleEnter(children[0])
  }, [handleEnter, children])

  const chartProps = { ...currentChart.props, hide: false } // overwrite hide prop from ChartSetter

  return (
    <div className="split-view-container">
      <div className="split-view-first">
        <Chart {...chartProps} />
      </div>
      <div className="split-view-second">
        {children.map((component, i) => {
          const isBlock = component.type === "p"
          return (
            <Waypoint
              key={i}
              topOffset={
                typeof window !== "undefined"
                  ? window.innerHeight / 4 - 10
                  : 400
              }
              bottomOffset={
                typeof window !== "undefined"
                  ? window.innerHeight / 4 - 10
                  : 400
              }
              onEnter={() => handleEnter(component)}
            >
              <div className={isBlock ? "split-view-block-wrapper" : ""}>{component}</div>
            </Waypoint>
          )
        })}
      </div>
    </div>
  )
}
