// action types
const ADD_OR_REMOVE_MAP_POPUP = "ADD_OR_REMOVE_MAP_POPUP"
const REMOVE_MAP_POPUP = "REMOVE_MAP_POPUP"
const HOVER_MAP_POPUP = "HOVER_MAP_POPUP"
const UNHOVER_MAP_POPUP = "UNHOVER_MAP_POPUP"
const FLUSH_MAP_POPUPS = "FLUSH_MAP_POPUPS"
// const REMOVE_ALL_MAP_POPUPS = 'REMOVE_ALL_MAP_POPUPS';

// action creators
export function addOrRemoveMapPopup(uid, mapUid, ts, object) {
  //console.log(uid, ts, object);
  return {
    type: ADD_OR_REMOVE_MAP_POPUP,
    uid,
    mapUid,
    ts,
    object,
  }
}
export function removeMapPopup(uid) {
  return {
    type: REMOVE_MAP_POPUP,
    uid,
  }
}

export function hoverMapPopup(uid) {
  return {
    type: HOVER_MAP_POPUP,
    uid,
  }
}

export function unHoverMapPopups() {
  return {
    type: UNHOVER_MAP_POPUP,
  }
}

export function flushMapPopups() {
  return {
    type: FLUSH_MAP_POPUPS,
  }
}

export function mapPopupReducer(state = [], action) {
  // console.log(state);
  switch (action.type) {
    case ADD_OR_REMOVE_MAP_POPUP:
      const existingPopup = state.find((i) => i.uid === action.uid)
      if (existingPopup) return [...state.filter((i) => i.uid !== action.uid)]
      const popupToAdd = {
        ...action.object,
        ts: action.ts,
        uid: action.uid,
        mapUid: action.mapUid,
      }
      return [...state.filter((i) => i.uid !== action.uid), popupToAdd]
    case REMOVE_MAP_POPUP:
      return [...state.filter((i) => i.uid !== action.uid)]
    case HOVER_MAP_POPUP:
      const popupToHover = state.find((i) => i.uid === action.uid)
      if (!popupToHover) return state
      const hoveredPopup = { ...popupToHover, hover: true }
      return [...state.filter((i) => i.uid !== action.uid), hoveredPopup]
    case UNHOVER_MAP_POPUP:
      const popupToUnhover = state.find((i) => i.hover)
      if (!popupToUnhover) return state
      const unhoveredPopup = { ...popupToUnhover, hover: false }
      return [
        ...state.filter((i) => i.uid !== popupToUnhover.uid),
        unhoveredPopup,
      ]
    case FLUSH_MAP_POPUPS:
      return []
    default:
      return state
  }
}
