import React, { useState, useEffect, useCallback, useMemo, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons"
import debounce from "debounce"
import Papa from "papaparse"
import { dataToGeoJson } from "./data-converter"
import { isMobileDevice } from "../../helpers"

import DatasetSelector from "./dataset-selector"
import RangeSelector, { dateToLocaleString } from "./range-selector"
import MapPopup from "./map-popup"
import TableView from "./table-view"

import {
  addOrRemoveMapPopup,
  removeMapPopup,
  hoverMapPopup,
  unHoverMapPopups,
  flushMapPopups,
} from "./map-popup-redux"

import { getCategoryColor } from "./map-hooks"

export function useDataLoader(sources = [], mapPoints = []) {
  // enriches sources array with data
  const [datasets, setDatasets] = useState(sources)

  useEffect(() => {
    if (!sources.length) return

    const allPromises = sources
      // .filter(source => source.path || source.data)
      .map((source) => {
        if (source.data)
          // geoJson
          return { ...source }
        else if (source.useMapPoints) {
          // map points
          return {
            ...source,
            keys: {
              primary: "name",
              category: "category",
              annotationId: "annotationId",
            },
            // data: dataToGeoJson([]),
          }
        } else {
          return fetch(source.path) // CSV
            .then((r) => r.text())
            .then((text) => {
              // console.log('fetched', source.path)
              const parsedCsv = Papa.parse(text, {
                header: true,
                delimiter: ";",
              }).data
              return {
                ...source, // copy all infos from source object
                data: parsedCsv,
              }
            })
        }
      })
    Promise.all(allPromises).then(setDatasets)
  }, [sources])

  useEffect(() => {
    let needsUpdate = false
    const datasetsEnrichedWithMapPoints = datasets.map((ds) => {
      if (ds.useMapPoints && mapPoints.length && !ds.data) {
        needsUpdate = true
        return {
          ...ds,
          data:
            typeof ds.mapPointFilter === "function"
              ? mapPoints.filter(ds.mapPointFilter)
              : mapPoints,
        }
      } else return ds
    })
    if (needsUpdate) setDatasets(datasetsEnrichedWithMapPoints)
  }, [datasets, mapPoints])

  return datasets
}

export function useDatasetSelector(datasets = []) {
  const initialState = datasets.length ? datasets[0] : { keys: {} }
  const [selectedDataset, setSelectedDataset] = useState(initialState)
  useEffect(() => {
    if (!datasets.length) return
    setSelectedDataset(datasets[0])
  }, [datasets])
  const datasetSelector = datasets.length > 1 && (
    <DatasetSelector
      datasets={datasets}
      selectedDataset={selectedDataset}
      setSelectedDataset={setSelectedDataset}
    />
  )
  return [selectedDataset, datasetSelector]
}

export function useRangeFilter(rangeKey, data = [], lang) {
  const usesRangeFilter = !!rangeKey
  const [minTs, setMinTs] = useState(0)
  const [maxTs, setMaxTs] = useState(0)
  const [maxIsMax, setMaxIsMax] = useState(true)
  const [minIsMin, setMinIsMin] = useState(true)

  const allSteps = useMemo(() => {
    if (!data || !rangeKey) return []
    const allValues = data.map((d) => ts(d[rangeKey]))
    let steps = getMonthDatesFromTo(
      Math.min(...allValues),
      Math.max(...allValues)
    ).sort()
    return steps
  }, [data, rangeKey])

  useEffect(() => {
    if (allSteps.indexOf(maxTs) === allSteps.length - 1) setMaxIsMax(true)
    if (allSteps.indexOf(minTs) === 0) setMinIsMin(true)
  }, [allSteps, maxTs, minTs])

  useEffect(() => {
    if (!allSteps.length) return
    const availableMax = allSteps[allSteps.length - 1]
    const availableMin = allSteps[0]
    if (minTs <= 0 || minTs < availableMin || minIsMin) setMinTs(availableMin)
    if (maxTs <= 0 || maxTs > availableMax || maxIsMax) setMaxTs(availableMax)
  }, [allSteps, maxTs, minTs, maxIsMax, minIsMin])

  function setCurrRange(range) {
    if (range.max === allSteps.length - 1) setMaxIsMax(true)
    else setMaxIsMax(false)
    if (range.min === 0) setMinIsMin(true)
    else setMinIsMin(false)
    setMinTs(allSteps[range.min])
    setMaxTs(allSteps[range.max])
  }

  const currStepRange = useMemo(() => {
    // uses array index values
    return {
      min: Math.max(allSteps.indexOf(minTs), 0),
      max: Math.max(allSteps.indexOf(maxTs), 0),
    }
  }, [allSteps, minTs, maxTs])

  const rangeFilterComponent = usesRangeFilter && (
    <RangeSelector
      value={currStepRange}
      allSteps={allSteps}
      onChangeComplete={setCurrRange}
    />
  )
  const filteredData = useMemo(() => {
    if (!data) return
    if (!usesRangeFilter) return data
    const dataFilter = (d) => {
      const date = new Date(d[rangeKey])
      const dataTs = new Date(date.getFullYear(), date.getMonth()).getTime()
      return dataTs >= minTs && dataTs <= maxTs
    }
    return data.filter(dataFilter)
  }, [data, rangeKey, minTs, maxTs, usesRangeFilter]) // bypass

  const rangeString = `${dateToLocaleString(
    new Date(minTs),
    lang.locale
  )} – ${dateToLocaleString(new Date(maxTs), lang.locale)}`

  return [filteredData, rangeFilterComponent, rangeString]

  function ts(dateString) {
    return new Date(dateString).getTime()
  }
}

export function useRawDataViewer(
  rawData,
  rawViewColumns,
  selectedDataset,
  rangeString,
  lang
) {
  const [rawDataViewerOptions, setRawDataViewerOptions] = useState({
    show: false,
  })
  const rawDataViewerComponent = rawDataViewerOptions.show && (
    <TableView
      data={rawData}
      filter={rawDataViewerOptions.filter}
      title={rawDataViewerOptions.title}
      subtitle={`${getDatasetName(selectedDataset, lang)} | ${rangeString}`}
      close={() => setRawDataViewerOptions({ show: false })}
      columns={rawViewColumns}
      color={rawDataViewerOptions.color}
    />
  )
  return [rawDataViewerComponent, setRawDataViewerOptions]
}

export function useGeoJsonConverter(rawData = [], customGeoJsonConverter) {
  // console.log(rawData);
  const geoJson = useMemo(
    () =>
      typeof customGeoJsonConverter === "function"
        ? customGeoJsonConverter(rawData)
        : dataToGeoJson(rawData),
    [rawData, customGeoJsonConverter]
  )
  return geoJson
}

export function useScrollLock(mapRef, standalone) {
  const [locked, setLocked] = useState(true)
  const [lockHighlight, setLockHighlight] = useState(false)

  useEffect(() => {
    const initiallyLocked = standalone
      ? isMobileDevice()
        ? true
        : false
      : true
    setLocked(initiallyLocked)
  }, [standalone])

  function highlightLockButton() {
    setLockHighlight(true)
    setTimeout(() => setLockHighlight(false), 1000)
  }

  const debouncedHighlightLockButton = debounce(highlightLockButton, 500, true)

  const handleWheel = useCallback(() => {
    if (locked) debouncedHighlightLockButton()
  }, [locked, debouncedHighlightLockButton])

  function markMapTouchMove(e) {
    e.isMapTouchMove = true
  }

  function toggleMapLock() {
    setLocked(!locked)
  }

  useEffect(() => {
    if (!mapRef.current) return
    const canvas = mapRef.current.querySelector("canvas")
    if (!canvas) return
    canvas.addEventListener("wheel", handleWheel, true)
    canvas.addEventListener("touchmove", handleWheel, true)
    canvas.addEventListener("touchmove", markMapTouchMove, true)
    canvas.addEventListener("touchstart", handleWheel, true)
    return () => {
      canvas.removeEventListener("wheel", handleWheel, true)
      canvas.removeEventListener("touchmove", handleWheel, true)
      canvas.removeEventListener("touchmove", markMapTouchMove, true)
      canvas.removeEventListener("touchstart", handleWheel, true)
    }
  }, [handleWheel, mapRef])

  const lockComponent = (
    <button
      className={`map-lock-button ${lockHighlight ? " highlight" : ""}`}
      onClick={toggleMapLock}
    >
      <FontAwesomeIcon icon={locked ? faLock : faLockOpen} />
    </button>
  )

  return [locked, lockComponent]
}

export function useCategories(geoJson, selectedDataset, categoryKey) {
  const categories = useMemo(() => {
    const { categoryColors = [] } = selectedDataset
    const predefinedCategories =
      typeof categoryColors === "function"
        ? categoryColors(geoJson)
        : categoryColors
    const defaultCategory = predefinedCategories.find((c) => c.default)
    if (defaultCategory) {
      defaultCategory.connected = []
      defaultCategory.count = 0
    }
    const distinctCategories = getCategoriesFromGeoJson(
      geoJson,
      categoryKey
    ).map((categoryName) => {
      const predefinedMatch = predefinedCategories.find((c) => {
        return (
          c.name === categoryName ||
          (Array.isArray(c.name) && c.name.includes(categoryName))
        )
      })
      const color = predefinedMatch
        ? predefinedMatch.color
        : defaultCategory
        ? defaultCategory.color
        : randomColor()
      const itemCount = getCategoryItemCount(geoJson, categoryKey, categoryName)
      const categoryObject = {
        name: categoryName,
        color: color,
        count: itemCount,
        predefined: !!predefinedMatch,
        orderId: predefinedMatch ? predefinedMatch.orderId || 0 : 0,
        showAs: predefinedMatch ? predefinedMatch.showAs : undefined,
      }
      if (!predefinedMatch && defaultCategory) {
        defaultCategory.connected = [
          ...defaultCategory.connected,
          categoryObject,
        ]
        defaultCategory.count = defaultCategory.count + itemCount // add item count to default count
      }
      return categoryObject
    })
    return defaultCategory
      ? [...distinctCategories, defaultCategory]
      : distinctCategories
  }, [geoJson, selectedDataset, categoryKey])
  return categories
}

export function useCategoryFilter(categories) {
  const [shownCategories, setShownCategories] = useState([])
  const onCategoryClick = useCallback(
    (category, multipleSelection) => {
      if (multipleSelection) {
        const otherShownCategories = shownCategories.filter(
          (c) => c !== category
        )
        if (shownCategories.includes(category))
          setShownCategories(otherShownCategories)
        else setShownCategories([...otherShownCategories, category])
      } else {
        if (
          (shownCategories.length === 1 &&
            shownCategories.includes(category)) || // only option
          (category.default &&
            shownCategories.length ===
              shownCategories.filter((c) => c.default || !c.predefined).length)
        ) {
          setShownCategories(categories)
        } else {
          const filter = category.default
            ? (c) => c === category || !c.predefined
            : (c) => c === category
          setShownCategories(categories.filter(filter))
        }
      }
    },
    [shownCategories, categories]
  )

  const previousCategories = usePrevious(categories)

  useEffect(() => {
    // reset: show all categories when categories change
    if (!categories.length) return
    setShownCategories((sc) => {
      const allWereShown = sc === previousCategories
      if (
        // show all categories
        !sc.length ||
        (sc.length === 1 && sc[0].name === "default") ||
        allWereShown
      ) {
        return categories
      } else {
        // remain custom selection on dataset change
        const matchingCategories = categories.filter((c) =>
          sc.map((sc) => sc.name).includes(c.name)
        )
        return matchingCategories.length ? matchingCategories : categories
      }
    })
  }, [categories, previousCategories])

  return [shownCategories, onCategoryClick]
}

export function usePopups(
  mapPopups,
  dispatch,
  selectedDataset,
  setHighlightedPoint,
  mapUid,
  setRawDataViewerOptions,
  rawData,
  rangeString,
  geoJson,
  categories,
  lang
) {
  const { onlyOnePopup, keys } = selectedDataset

  const addOrRemovePopup = useCallback(
    (uid, ts, popupProps) => {
      setHighlightedPoint("")
      if (onlyOnePopup) dispatch(flushMapPopups(selectedDataset))
      dispatch(addOrRemoveMapPopup(uid, mapUid, ts, popupProps))
    },
    [dispatch, setHighlightedPoint, mapUid, onlyOnePopup, selectedDataset]
  )

  const removePopup = useCallback(
    (uid) => {
      setHighlightedPoint("")
      dispatch(removeMapPopup(uid))
    },
    [dispatch, setHighlightedPoint]
  )

  const hoverPopup = useCallback((uid) => dispatch(hoverMapPopup(uid)), [
    dispatch,
  ])

  const unHoverPopup = useCallback(() => dispatch(unHoverMapPopups()), [
    dispatch,
  ])

  /* useEffect(() => { // flush on dataset change
    dispatch(flushMapPopups(selectedDataset))
  }, [dispatch, selectedDataset]); */

  const subtitle = selectedDataset.useSubtitle
    ? `${getDatasetName(selectedDataset, lang)} | ${rangeString}`
    : undefined

  const popupComponents = mapPopups
    .filter((p) => p.mapUid === mapUid)
    .map((p) => {
      const popupData =
        getPropsFromGeoJsonFeature(geoJson, keys.primary, p[keys.primary]) || {}
      const color = getCategoryColor(popupData[keys.category], categories)
      return (
        <MapPopup
          key={`map_popup_${p[keys.primary]}`}
          keys={selectedDataset.keys}
          orderId={1 / p.ts}
          popupData={popupData}
          hover={p.hover}
          color={color}
          hidePopup={() => removePopup(p[selectedDataset.keys.primary])}
          columns={selectedDataset.columns}
          sourceLangId={selectedDataset.sourceLang}
          onMouseOver={() =>
            setHighlightedPoint(p[selectedDataset.keys.primary])
          }
          onMouseOut={() => setHighlightedPoint("")}
          onFocus={() => setHighlightedPoint(p[selectedDataset.keys.primary])}
          onBlur={() => setHighlightedPoint("")}
          getPopupTitle={selectedDataset.getPopupTitle}
          setRawDataViewerOptions={setRawDataViewerOptions}
          rawData={rawData}
          barChartCategories={selectedDataset.barChartCategories}
          subtitle={subtitle}
        />
      )
    })

  const popupInteractions = {
    addOrRemovePopup,
    removePopup,
    hoverPopup,
    unHoverPopup,
  }

  return [popupComponents, popupInteractions]
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/**
 * helpers
 */

export function getLocalizedString(field, lang) {
  return typeof field === "object" ? field[lang.id] : field // string
}
export function getDatasetName(ds, lang) {
  return getLocalizedString(ds.name, lang)
}

function getPropsFromGeoJsonFeature(geoJson, searchKey, searchValue) {
  // console.log(geoJson, searchKey, searchValue);
  if (!geoJson) return
  const feature = geoJson.features.find(
    (f) => f.properties[searchKey] === searchValue
  )
  return feature ? feature.properties : undefined
}

function getMonthDatesFromTo(fromTs, toTs) {
  const fromDate = new Date(fromTs)
  const toDate = new Date(toTs)
  const minYear = fromDate.getFullYear()
  const minMonth = fromDate.getMonth()
  const maxYear = toDate.getFullYear()
  const maxMonth = toDate.getMonth()

  const years = Array.from(Array(maxYear - minYear + 1).keys()).map(
    (y) => y + minYear
  )
  const customMonthsMinYear = Array.from(Array(11 - minMonth + 1).keys()).map(
    (m) => 11 - m
  )
  const customMonthsMaxYear = Array.from(Array(maxMonth + 1).keys())
  const customMonths = {
    [minYear]: customMonthsMinYear,
    [maxYear]: customMonthsMaxYear,
  }
  return getMonthDatesFor(years, customMonths)
}

// getDatesFor([1990, 1991, 1992, 1993, 1994], {1990: [11], 1994: [0, 1, 2, 3, 4, 5, 6, 7, 8]});
function getMonthDatesFor(years, customMonthes = {}) {
  let result = []
  years.forEach((year) => {
    const months = customMonthes[year] || [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    months.forEach((month) => {
      result.push(new Date(year, month).getTime())
    })
  })
  return result
}

function getCategoryItemCount(geoJson, categoryKey, categoryNames) {
  const queryCategories = categoryNames
    ? typeof categoryNames === "object" // array
      ? categoryNames
      : [categoryNames]
    : []
  const features = getFeaturesFromGeoJson(geoJson)
  return features.reduce(
    (acc, curr) =>
      queryCategories.includes(curr.properties[categoryKey]) ? acc + 1 : acc,
    0
  )
}

function getFeaturesFromGeoJson(geoJson) {
  if (typeof geoJson !== "object") return []
  if (typeof geoJson.features !== "object") return []
  return geoJson.features
}

function getCategoriesFromGeoJson(geoJson, categoryKey) {
  const features = getFeaturesFromGeoJson(geoJson)
  const categorySet = new Set(
    features.map((f) => f.properties[categoryKey]).filter((f) => !!f)
  )
  return [...categorySet]
}

function randomColor() {
  const letters = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
