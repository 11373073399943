import React from "react"
import SimpleChatBot from "react-simple-chatbot"
import JSON5 from "json5"

import "./chat-bot.scss"

const ChatBot = ({ attributes }) => {
  const { botavatar, steps, speechsynthesis } = attributes
  let parsedSteps = {}
  try {
    parsedSteps = JSON5.parse(steps)
  } catch (e) {
    console.log(e)
  }
  return (
    <div className="chat-bot-outer">
      <div className="chat-bot-inner">
        <SimpleChatBot
          botAvatar={botavatar}
          steps={parsedSteps}
          speechSynthesis={speechsynthesis}
        />
      </div>
    </div>
  )
}

export default ChatBot
