import React, { useEffect, useRef, useState } from "react"
import debounce from "debounce"

import "./progress-bar.scss"

const ReadingProgressBar = () => {
  const divEl = useRef()
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    const debouncedOnScroll = debounce(onScroll, 10, true)

    window.addEventListener("scroll", debouncedOnScroll)
    return () => window.removeEventListener("scroll", debouncedOnScroll)

    function onScroll(e) {
      // console.log('onScroll')
      if (!e.target) return
      if (!e.target.children.length) return
      const scrollPos = document.scrollingElement.scrollTop - window.innerHeight // e.target.scrollTop;
      const max = e.target.children[0].scrollHeight - 2 * window.innerHeight
      const newPercent = Math.max(0, Math.min((scrollPos / max) * 100, 100))
      setPercent(newPercent)
    }
  }, [])

  return (
    <div
      ref={divEl}
      style={{ width: `${percent}%` }}
      className="reading-progress-bar"
    />
  )
}

export default ReadingProgressBar
