import { useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
import { isTouchDevice, isMobileDevice } from "../../helpers"
import transcribe from "../../custom-modules/transkribator"
import { COLOR_KEY, RADIUS_KEY } from "./geojson-property-keys"
import { titleColor } from "../../styles/globals.module.scss"

const MAPBOX_SETTINGS = {
  style: "mapbox://styles/mapbox/light-v10",
  center: [13.408333, 52.518611],
}
const SOURCE_NAME = "source1"
const LAYER_NAME = "layer1"
const HIGHLIGHT_COLOR = "rgba(230, 40, 70, 0.9)"
const SELECTED_COLOR = "rgba(230, 40, 70, 0.5)"

const MOBILE_BOUNDS_OPTIONS = {
  padding: { bottom: 10, left: 2, right: 2, top: 10 },
}
const DESKTOP_BOUNDS_OPTIONS = {
  padding: { bottom: 20, left: 20, right: 100, top: 20 },
}

/**
 * data hooks
 */

export function useMap(elementRef) {
  const [map, setMap] = useState({})
  const [loaded, setLoaded] = useState(false)
  const setMapLoadedTrue = () => setLoaded(true)
  useEffect(() => {
    if (!elementRef.current) return
    const mapboxMap = mapEmbedder(elementRef.current)
    mapboxMap.on("load", setMapLoadedTrue)
    setMap(mapboxMap)
    return () => mapboxMap.off("load", setMapLoadedTrue)
  }, [elementRef])
  return {
    map,
    loaded,
  }
}

export function useDataPlotter(mapObj, geoJson, nameKey, type) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    addLayers(map, SOURCE_NAME, LAYER_NAME, geoJson, nameKey, type)
  }, [map, loaded, geoJson, nameKey, type])
}

export function useCategoryColors(
  mapObj,
  categoryKey,
  categories,
  type,
  defaultColor = titleColor
) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    const key = type === "fill" ? "fill-color" : "circle-color"
    const expression = getMatchExpression(
      categoryKey,
      categories,
      "name",
      "color",
      defaultColor
    )
    /*type === 'fill' ? 
      getCurveStepExpression(categoryKey)
      : getMatchExpression(categoryKey, categories, 'name', 'color', defaultColor);*/
    const pP = {
      [key]: expression,
    }
    // console.log(pP);
    updatePaintProperties(map, LAYER_NAME, pP)
  }, [map, loaded, categories, categoryKey, type, defaultColor])
}

export function useShownCategoriesFilter(mapObj, categoryKey, shownCategories) {
  // , '__default_item_to_avoid_empty_array__'
  const filter = shownCategories.length
    ? [
        "match",
        ["get", categoryKey],
        [...shownCategories.map((c) => c.name || "")],
        true,
        false,
      ]
    : ""
  // console.log(filter);
  useMapFilter(mapObj, LAYER_NAME, filter)
}

function useMapFilter(mapObj, layerName, filter) {
  // console.log(filter);
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    if (!map.getLayer(layerName)) return
    map.setFilter(layerName, filter)
  }, [map, loaded, layerName, filter])
}

/**
 * interactivity hooks
 */

export function usePointClick(
  mapObj,
  primaryKey,
  categoryKey,
  categories,
  onClick
) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    const boundOnPointClick = (e) =>
      onPointClick(e, primaryKey, categoryKey, categories, onClick)
    map.on("click", LAYER_NAME, boundOnPointClick)
    return () => map.off("click", LAYER_NAME, boundOnPointClick)
  }, [map, loaded, primaryKey, categoryKey, categories, onClick])
}

export function usePointHover(
  mapObj,
  primaryKey,
  onMouseEnter,
  onMouseLeave,
  lang,
  sourceLang
) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    let mapboxPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true,
    })
    const boundOnMouseEnterPoint = (e) =>
      onMouseEnterPoint(
        e,
        map,
        primaryKey,
        onMouseEnter,
        mapboxPopup,
        lang,
        sourceLang
      )
    const boundOnMouseLeavePoint = (e) =>
      onMouseLeavePoint(e, map, primaryKey, onMouseLeave, mapboxPopup)
    map.on("mouseenter", LAYER_NAME, boundOnMouseEnterPoint)
    map.on("mouseleave", LAYER_NAME, boundOnMouseLeavePoint)
    return () => {
      map.off("mouseenter", LAYER_NAME, boundOnMouseEnterPoint)
      map.off("mouseleave", LAYER_NAME, boundOnMouseLeavePoint)
    }
  }, [map, loaded, primaryKey, onMouseEnter, onMouseLeave, lang, sourceLang])
}

export function useMarkSelected(mapObj, selectedPoints = [], nameKey) {
  const relevantPoints = selectedPoints.filter((p) => !!p)
  const matchNames = relevantPoints.length
    ? [...relevantPoints]
    : ["__default_item_to_avoid_empty_array__"]
  const filter = ["match", ["get", nameKey], matchNames, true, false]
  // console.log(filter);
  const layerName = `${LAYER_NAME}_selected`
  // console.log(filter);
  useMapFilter(mapObj, layerName, filter)
}

export function useHoverHighlight(mapObj, highlightedPoint, nameKey) {
  const [layerName, filter] = getHighlightLayerAndFilter(
    nameKey,
    highlightedPoint
  )
  useMapFilter(mapObj, layerName, filter)
}

/**
 * adjustment hooks
 */

export function useHeightAdjust(mapObj) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    // bugfix: sometimes initial height is not set correctly
    map.resize()
  }, [map, loaded])
}

export function useMapLock(mapObj, locked) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    if (locked) {
      map.scrollZoom.disable()
      if (isTouchDevice()) map.dragPan.disable()
    } else {
      map.scrollZoom.enable()
      if (isTouchDevice()) map.dragPan.enable()
    }
  }, [map, loaded, locked])
}

export function useCustomBoundsOptions() {
  const [customOptions, setCustomOptions] = useState({})
  useEffect(() => {
    setCustomOptions(
      isMobileDevice() ? MOBILE_BOUNDS_OPTIONS : DESKTOP_BOUNDS_OPTIONS
    )
  }, [])
  return customOptions
}

export function useFitBounds(mapObj, geoJson, options, type, customBounds) {
  const { map, loaded } = mapObj
  useEffect(() => {
    if (!loaded) return
    // console.log('fitbounds');
    setBoundingBox(map, geoJson, options, customBounds)
  }, [map, loaded, geoJson, options, type, customBounds])
}

/**
 * map funcitons
 */

function getMatchExpression(
  propertyKey,
  array,
  matchKey = "name",
  valueKey = "value",
  defaultValue
) {
  return [
    "match",
    ["get", propertyKey],
    ...array.reduce(
      (acc, curr) => [...acc, curr[matchKey] || "", curr[valueKey] || ""],
      []
    ),
    defaultValue,
  ]
}

function getHighlightLayerAndFilter(key, name) {
  const layer = `${LAYER_NAME}_highlighted`
  const filter = getMatchFilter(key, name)
  return [layer, filter]
}

function getMatchFilter(key, name) {
  return [
    "match",
    ["get", key],
    [name, "__default_item_to_avoid_empty_array__"],
    true,
    false,
  ]
}

function mapEmbedder(element) {
  if (!element) return
  if (typeof mapboxgl === "undefined") return
  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_KEY
  const map = new mapboxgl.Map({
    container: element,
    ...MAPBOX_SETTINGS,
  })
  map.addControl(
    new mapboxgl.NavigationControl({ showZoom: true }),
    "bottom-left"
  )
  return map
}

function addLayers(map, sourceName, layerName, data, nameKey, type) {
  if (map.getLayer(layerName)) map.removeLayer(layerName)
  if (map.getLayer(`${layerName}_selected`))
    map.removeLayer(`${layerName}_selected`)
  if (map.getLayer(`${layerName}_highlighted`))
    map.removeLayer(`${layerName}_highlighted`)
  if (map.getSource(sourceName)) map.removeSource(sourceName)

  map.addSource(sourceName, {
    type: "geojson",
    data: data,
    cluster: false,
  })

  const circlePaint = {
    "circle-color": ["get", COLOR_KEY],
    "circle-radius": ["get", RADIUS_KEY],
    "circle-opacity": 0.8,
  }

  const circlePaintSelected = {
    "circle-color": SELECTED_COLOR,
    "circle-radius": ["get", RADIUS_KEY],
  }

  const circlePaintHighlighted = {
    "circle-color": HIGHLIGHT_COLOR,
    "circle-radius": ["get", RADIUS_KEY],
  }

  const fillPaint = {
    "fill-color": "rgba(250, 250, 250, 0)",
    "fill-outline-color": "#888888",
    "fill-opacity": 0.5,
  }

  const fillPaintHighlighted = {
    "fill-color": titleColor,
    "fill-outline-color": "#F2F2F2",
    "fill-opacity": 0.5,
  }

  map.addLayer({
    id: layerName,
    type: type || "circle",
    source: sourceName,
    filter: ["all"],
    paint: type === "fill" ? fillPaint : circlePaint,
  })
  map.addLayer({
    id: `${layerName}_selected`,
    type: type || "circle",
    source: sourceName,
    filter: ["==", nameKey, ""],
    paint: type === "fill" ? fillPaintHighlighted : circlePaintSelected,
  })
  map.addLayer({
    id: `${layerName}_highlighted`,
    type: type || "circle",
    source: sourceName,
    filter: ["==", nameKey, ""],
    paint: type === "fill" ? fillPaintHighlighted : circlePaintHighlighted,
  })
}

function onMouseEnterPoint(
  e,
  map,
  primaryKey,
  onMouseEnter,
  mapboxPopup,
  lang,
  sourceLang
) {
  map.getCanvas().style.cursor = "pointer"
  const coordinates = e.features[0].geometry.coordinates.slice()
  const name = transcribe(
    e.features[0].properties[primaryKey],
    lang.id,
    sourceLang
  )
  if (coordinates.length === 2 && typeof coordinates[0] === "number") {
    // only points!
    mapboxPopup.setLngLat(coordinates).setHTML(name).addTo(map)
  }
  if (typeof onMouseEnter === "function") onMouseEnter(name)
}

function onMouseLeavePoint(e, map, primaryKey, onMouseLeave, mapboxPopup) {
  map.getCanvas().style.cursor = ""
  mapboxPopup.remove()
  if (typeof onMouseLeave === "function") onMouseLeave()
  const filterOptions = getHighlightLayerAndFilter(primaryKey, "")
  map.setFilter(...filterOptions)
}

function onPointClick(e, primaryKey, categoryKey, categories, onClick) {
  const props = e.features[0].properties
  const categoryColor = getCategoryColor(props[categoryKey], categories)
  const newObj = categoryColor ? { ...props, color: categoryColor } : props
  if (typeof onClick === "function")
    onClick(props[primaryKey], Date.now(), newObj)
}

export function getCategoryColor(categoryName, categories = []) {
  const matchColor = categories.find((c) => c.name === categoryName)
  return matchColor ? matchColor.color : null
}

export function getBounds(geoJson) {
  if (!geoJson) return
  const features = geoJson.features
  if (!features) return
  if (!features.length) return

  if (typeof mapboxgl === "undefined") return
  if (typeof mapboxgl.LngLatBounds === "undefined") return
  let result = new mapboxgl.LngLatBounds()
  features.forEach((f) => {
    if (!f.geometry) return
    if (f.geometry.type === "Polygon") {
      f.geometry.coordinates[0]
        .filter((c) => typeof c === "object" && c.length === 2)
        .forEach((c) => result.extend(c))
    } else if (f.geometry.coordinates.length === 2)
      result.extend(f.geometry.coordinates)
  })
  return result
}

function setBoundingBox(
  map,
  geoJson,
  options = { padding: 20, offset: [0, 0] },
  customBounds
) {
  if (!map) return
  const bounds = customBounds || getBounds(geoJson)
  if (!bounds) return
  if (bounds.getSouthWest() === bounds.getNorthEast()) {
    map.flyTo({ center: bounds.getCenter() }) // single point
  } else map.fitBounds(bounds, options)
}

function updatePaintProperties(map, layerName, paintProperties = {}) {
  if (typeof map.setPaintProperty !== "function") return
  for (let key in paintProperties) {
    map.setPaintProperty(layerName, key, paintProperties[key])
  }
}
