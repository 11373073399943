import React, { useState, useEffect, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo } from "@fortawesome/free-solid-svg-icons"
import { LangContext } from "gatsby-source-dek-wp"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"
import "./range-selector.scss"

const RangeSelector = ({ value, onChangeComplete, allSteps = [] }) => {
  const lang = useContext(LangContext)
  const [tmpRange, setTmpRange] = useState(value)

  useEffect(() => {
    setTmpRange(value)
  }, [value])

  const min = 0
  const max = allSteps.length - 1
  // console.log(min, max, value);

  function onRangeChange(range) {
    if (range.min >= min && range.max <= max) setTmpRange(range)
    // onChangeComplete(range);
  }
  function formatLabel(index) {
    const ts = allSteps[index]
    return dateToLocaleString(new Date(ts), lang.locale)
  }
  function reset() {
    onChangeComplete({ min: 0, max: allSteps.length - 1 })
  }

  const resettable = tmpRange.min > min || tmpRange.max < allSteps.length - 1
  return (
    <div className="range-selector-wrapper">
      <InputRange
        className="range-selector"
        draggableTrack
        maxValue={Math.max(max, value.max)}
        minValue={min}
        value={tmpRange.max <= max ? tmpRange : value}
        formatLabel={formatLabel}
        onChange={onRangeChange}
        onChangeComplete={onChangeComplete}
      />
      <div className="range-selector-reset">
        {resettable && <FontAwesomeIcon icon={faUndo} onClick={reset} />}
      </div>
    </div>
  )
}

export default RangeSelector

export function dateToLocaleString(date, locale) {
  const options = { year: "numeric", month: "long" }
  return date.toLocaleDateString(locale, options)
}
