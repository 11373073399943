import React, { useState, useContext, useEffect, useCallback } from "react"
import { LangContext, useBodyClass } from "gatsby-source-dek-wp"

import ShareBar from "./share-bar/share-bar"
import TermSearch from "./term-search"
import { useTermsQuery, ERROR_MSG } from "./elastic-search"
import Chart from "./chart"
import Switch from "./switch/switch"
import TermBookmarks from "./term-bookmarks/term-bookmarks"
import BookmarkButton from "./term-bookmarks/bookmark-button"
import { LoadingSpinner } from "./loading-spinner/loading-spinner"
import { usePartyFilter } from "./party-filter/party-filter"
import { useGroupData } from "./group-data"
import { RangeSelect } from "./range-select"
import { TfToolWrapper } from "./term-freq-tool.style"

import { TermStateContext, TermDispatchContext } from "./contexts/terms"
import { SetTranslateContext, TranslateContext } from "./contexts/translate"
import { BookmarksContext } from "./contexts/bookmarks"
import { useBookmarksReducer } from "./term-bookmarks/bookmarks-reducer"
import { setRange, useTermReducer } from "./term-reducer"
import { useTermUrlEffect } from "./term-url-effect"
import { MethodsContext } from "./methods-text"
// import ChartSSR from "../chart/chart-ssr"

const TRANSLATE_STR = "Transliteration" // "übersetzen *"

const LEGEND_Y = {
  en: (
    <>
      <span>term frequency</span>
      <span>(per 100,000 words)</span>
    </>
  ),
  de: (
    <>
      <span>Worthäufigkeit</span>
      <span>(pro 100.000 Wörter)</span>
    </>
  ),
  ru: (
    <>
      <span>частота</span>
      <span>на 100 000 слов</span>
    </>
  ),
}

export const TermFreqTool = ({
  type,
  suggestionApi,
  termApi,
  yearRange,
  // onChartClick,
  shareProjectName,
  defaultMarkers,
}) => {
  const lang = useContext(LangContext)
  const termState = useContext(TermStateContext)
  const dispatch = useContext(TermDispatchContext)
  const [data, isLoading, error] = useTermsQuery(termState, termApi)
  const yLegend = LEGEND_Y[lang.id]
  const translateEnabled = useContext(TranslateContext)
  const setTranslateEnabled = useContext(SetTranslateContext)
  useBodyClass(translateEnabled && lang.id === "de" ? "translation-active" : "")
  const bookmarks = useContext(BookmarksContext)

  const [filteredData, partyFilter] = usePartyFilter(data)

  const interval = data[0]?.data[0]?.interval || "year"

  const groupedData = useGroupData(filteredData, interval)

  const [from, to] = yearRange
  useEffect(() => {
    if (!from || !to) return
    dispatch(setRange([from, to]))
  }, [from, to, dispatch])
  const currRange =
    Array.isArray(termState.range) && termState.range.length
      ? termState.range
      : yearRange
  const setCurrRange = useCallback(
    (newRange) => dispatch(setRange(newRange)),
    [dispatch]
  )

  return (
    <TfToolWrapper
      className={`term-frequency-tool ${type || ""} ${
        isLoading ? "loading" : ""
      }`}
    >
      <div className="term-frequency-tool-upper">
        <ShareBar
          data={data}
          shareProjectName={shareProjectName}
          yearRange={currRange}
        />
        {lang.id === "de" && (
          <Switch
            onChange={() => setTranslateEnabled((e) => !e)}
            enabled={translateEnabled}
            label={TRANSLATE_STR}
          />
        )}
        <BookmarkButton solid={true} withText={true} />
      </div>
      <TermSearch
        data={data}
        dispatch={dispatch}
        suggestionApi={suggestionApi}
        // autoFocus={type === "primary"} // eslint-disable-line jsx-a11y/no-autofocus
      />
      {partyFilter /* */}
      <div className={`term-frequency-tool-lower`}>
        <div className={`translation-credit`} style={{ display: "none" }}>
          <span>* nur Einzelwörter</span>
          <span>
            Powered by{" "}
            <a
              href="https://tech.yandex.com/dictionary/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Yandex.Dictionary
            </a>
          </span>
        </div>
        <div className="error-message">{error && ERROR_MSG[lang.id]}</div>
        <div className="legend-y">{yLegend}</div>
      </div>

      <Chart
        data={groupedData}
        customMarkers={termState.markers}
        resizeTrigger={bookmarks}
        yearRange={currRange}
        defaultMarkers={defaultMarkers}
        // onChartClick={onChartClick}
      />

      {interval === "month" && (
        <RangeSelect
          range={currRange}
          setRange={setCurrRange}
          min={yearRange[0]}
          max={yearRange[1]}
        />
      )}

      {interval === "month" && false && (
        <div
          className={`term-frequency-tool-upper`}
          style={{ justifyContent: "center" }}
        >
          <Switch
            onChange={() =>
              setInterval((i) => (i === "month" ? "year" : "month"))
            }
            enabled={interval === "month"}
            label="по месяцам"
          />
        </div>
      )}

      <TermBookmarks />
      <LoadingSpinner active={isLoading} />
    </TfToolWrapper>
  )
}

export const withTermFreqTool = (Comp) => (props) => {
  const lang = useContext(LangContext)
  const [translateEnabled, setTranslateEnabled] = useState(false)
  useEffect(() => {
    // TODO: use meta-data settings to decide when to enable translation!
    if (lang.id === "de" || lang.id === "en") setTranslateEnabled(true)
    else setTranslateEnabled(false)
  }, [lang])

  const [termState, termDispatch] = useTermReducer(lang)
  useTermUrlEffect(true, termState, termDispatch) // isFirstSection && !isStory

  const bookmarks = useBookmarksReducer()

  return (
    <TermStateContext.Provider value={termState}>
      <TermDispatchContext.Provider value={termDispatch}>
        <TranslateContext.Provider value={translateEnabled}>
          <SetTranslateContext.Provider value={setTranslateEnabled}>
            <BookmarksContext.Provider value={bookmarks}>
              <Comp {...props} />
            </BookmarksContext.Provider>
          </SetTranslateContext.Provider>
        </TranslateContext.Provider>
      </TermDispatchContext.Provider>
    </TermStateContext.Provider>
  )
}
