import React, { useContext } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faAngleDown,
  faArrowLeft,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons"

import plusButton from "./images/plus.svg"
import minusButton from "./images/minus.svg"
import xButton from "./images/x.svg"

import "./buttons.scss"
import { LangContext } from "gatsby-source-dek-wp"

export const BackArrow = ({ onClick }) => {
  const lang = useContext(LangContext)
  return (
  <Link className="back-arrow" to={`/${lang.id}`}>
    <FontAwesomeIcon icon={faArrowLeft} onClick={onClick} />
  </Link>
)}

export const MenuButton = ({ menuOpen, onClick }) => (
  <button
    className={
      "menu-button hamburger hamburger--collapse" +
      (menuOpen ? " is-active" : "")
    }
    onClick={onClick}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
)

export const PlusMinusImg = ({ expanded }) => (
  <img
    className="plus-minus-img"
    src={expanded ? minusButton : plusButton}
    alt={expanded ? "collapse" : "expand"}
  />
)

export const PlusMinusButton = ({ expanded, extraClasses, onClick }) => (
  <button onClick={onClick} className={`plus-minus-button ${extraClasses}`}>
    <PlusMinusImg expanded={expanded} />
  </button>
)

export const TocButton = ({ extraClasses }) => (
  <FontAwesomeIcon className={`toc-button ${extraClasses}`} icon={faBars} />
)

export const XButton = ({ extraClasses, onClick }) => (
  <button className={`x-button ${extraClasses}`} onClick={onClick}>
    <img src={xButton} alt="close" />
  </button>
)

export const ArrowDown = ({ onClick, extraClasses }) => (
  <div className={`arrow-down-container ${extraClasses}`}>
    <button className="arrow-down-inner" onClick={onClick}>
      <FontAwesomeIcon icon={faAngleDown} />
    </button>
  </div>
)

export const CsvButton = ({ onClick, extraClasses }) => (
  <button className={`csv-button ${extraClasses}`} onClick={onClick}>
    <FontAwesomeIcon icon={faFileCsv} />
  </button>
)
