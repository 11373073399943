import React, { useContext } from "react"
import {
  useMap,
  useHeightAdjust,
  useMapLock,
  useDataPlotter,
  usePointClick,
  usePointHover,
  useFitBounds,
  useMarkSelected,
  useHoverHighlight,
  useCategoryColors,
  useShownCategoriesFilter,
  useCustomBoundsOptions,
} from "./map-hooks"
import { LangContext } from "gatsby-source-dek-wp"
import "./map.scss"

const Map = (props) => {
  const { mapRef, locked, children, standalone, type } = props
  const { geoJson, customBounds, highlightedPoint, selectedPoints } = props
  const { primaryKey, categoryKey } = props
  const { categories, shownCategories } = props
  const { sourceLang } = props
  const { onClick, onMouseEnter, onMouseLeave } = props.interactions
  // console.log(primaryKey, categoryKey, categories);
  const lang = useContext(LangContext)
  const mapObj = useMap(mapRef)
  useHeightAdjust(mapObj)
  useMapLock(mapObj, locked, standalone)
  useDataPlotter(mapObj, geoJson, primaryKey, type)
  const boundsOptions = useCustomBoundsOptions()
  useFitBounds(mapObj, geoJson, boundsOptions, type, customBounds)
  usePointClick(mapObj, primaryKey, categoryKey, categories, onClick)
  usePointHover(
    mapObj,
    primaryKey,
    onMouseEnter,
    onMouseLeave,
    lang,
    sourceLang
  )
  useMarkSelected(mapObj, selectedPoints, primaryKey)
  useHoverHighlight(mapObj, highlightedPoint, primaryKey)
  useCategoryColors(mapObj, categoryKey, categories, type)
  useShownCategoriesFilter(mapObj, categoryKey, shownCategories)

  return (
    <div className="map-inner" ref={mapRef}>
      {children}
    </div>
  )
}

export default Map
