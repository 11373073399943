import React, { useContext, useMemo } from "react"
import { Link } from "gatsby"
import { ContentsContext, LangContext } from "gatsby-source-dek-wp"

import "./menu.scss"

const PageList = ({ pages }) => (
  <ul>
    {pages.map((p, i) => (
      <li
        key={p.slug}
        className={p.comingSoon ? "unpublished" : ""}
      >
        <Link to={`/${p.slug}`}>
          {p.title}
        </Link>
      </li>
    ))}
  </ul>
)

const Menu = ({ siteTitle }) => {
  const pages = useContext(ContentsContext)
  const lang = useContext(LangContext)

  const majorPages = useMemo(
    () => pages.filter((p) => p.pageType === "major"),
    [pages]
  )
  const defaultPages = useMemo(
    () => pages.filter((p) => p.pageType === "default"),
    [pages]
  )
  const aboutPage = useMemo(
    () => pages.find((p) => p.pageType === "about"),
    [pages]
  )

  return (
    <div className="menu">
      <div className="menu-inner">
        <div className="header-site-title">
          <Link to={`/${lang.id}`}>{siteTitle}</Link>
        </div>
        {[majorPages, defaultPages, [aboutPage]].map((list, i) => (
          <PageList key={i} pages={list} />
        ))}
      </div>
    </div>
  )
}

export default Menu
