import React, { useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { getDatasetName } from "./map-controller-hooks"

import "./dataset-selector.scss"

const DatasetSelector = ({ datasets, selectedDataset, setSelectedDataset }) => {
  const lang = useContext(LangContext)
  return (
    <div className="map-dataset-selector">
      {datasets.map((ds, i) => {
        const name = getDatasetName(ds, lang)
        return (
          <button
            key={i}
            className={`dataset-option ${
              ds === selectedDataset ? "selected" : ""
            }`}
            onClick={() => setSelectedDataset(ds)}
          >
            {name}
          </button>
        )
      })}
    </div>
  )
}

export default DatasetSelector
