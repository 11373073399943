import React, { useState, useEffect } from "react"
import { Provider } from "react-redux"

import store from "../../contexts/store"

import {
  LangContext,
  ContentsContext,
  useAllContents,
  useLangs,
  useContents,
  withAuth,
  useThemeColor,
} from "gatsby-source-dek-wp"
// import { theme } from "./theme"

import Header from "../header"
import Menu from "../menu"
import Page from "../page"
import StartPage from "../start-page"
import { useBodyClass } from "../../hooks"

import "../../styles/index.scss"

const App = ({ metaData, data, pageContext }) => {
  const { langId, slug } = pageContext
  const { categoryNames, metaInfos } = metaData
  const allPages = useAllContents(data, false, categoryNames)
  const [lang, allLanguages] = useLangs(langId, allPages)
  const [pages, selected] = useContents(allPages, lang.id, slug)

  const { title } = metaInfos.find((m) => m.langId === lang.id)

  const [menuOpen, setMenuOpen] = useState(false)
  useEffect(() => {
    setMenuOpen(false)
  }, [selected])

  useBodyClass(menuOpen ? "menu-open" : "")

  const themeColorComp = useThemeColor(
    selected?.themeColor || "rgb(50, 20, 170)",
  )

  return (
    <Provider store={store}>
      <LangContext.Provider value={lang}>
        <ContentsContext.Provider value={pages}>
          {themeColorComp}
          <div className={`app ${menuOpen ? "menu-open" : ""}`}>
            <Header
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              lang={lang}
              langs={allLanguages}
              selected={selected}
              isStartPage={!selected}
              siteTitle={title}
            />
            <Menu
              pages={pages}
              lang={lang}
              menuOpen={menuOpen}
              siteTitle={title}
            />
            <div className="page-container">
              {selected ? (
                <Page
                  page={pageContext}
                  // popupAggregator={this.popupAggregator}
                  metaData={metaData}
                />
              ) : (
                <StartPage pages={pages} metaData={metaData} />
              )}
            </div>
          </div>
        </ContentsContext.Provider>
      </LangContext.Provider>
    </Provider>
  )
}

export default withAuth(App)
