import React, { useEffect } from "react"
import { connect } from "react-redux"
import { addMapPoint, removeMapPoint } from "./map-point-redux"
import Annotation from "../../../plugins/gatsby-plugin-popup-preloader/components/annotation/annotation"

const MapPoint = ({ uid, name, lat, lon, category, content, dispatch }) => {
  useEffect(() => {
    if (!uid) return
    dispatch(addMapPoint(uid, name, lat, lon, category))
    return () => dispatch(removeMapPoint(uid))
  }, [dispatch, uid, name, lat, lon, category])

  return (
    <Annotation id={uid} classList={["map-popup-inner"]}>
      {content}
    </Annotation>
  )
}

const MapPointWithRedux = connect(null, (dispatch) => {
  return { dispatch }
})(MapPoint)

export default MapPointWithRedux
