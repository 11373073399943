import React from "react"

import "./panel-discussion.scss"

const PanelDiscussion = ({ children }) => {
  if (!children.length) return null
  return <div className="panel-discussion">{children}</div>
}

export default PanelDiscussion
