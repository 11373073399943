import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useTransformer } from "../../modules/transformer"

import "./page-teaser.scss"
import { Link } from "gatsby"

const PageTeaser = ({ page, scrollRef }) => {
  const dest = `/${page.slug}`
  const transformedDescription = useTransformer(page.teaserStartPage || page.description)?.props?.children
  return (
    <div className={`page-teaser-outer`}>
      <div className="page-teaser-bg">
        <div className="page-teaser-inner" ref={scrollRef}>
          <Link to={dest}>
            <h3>{page.menuTitle || page.title}</h3>
          </Link>
          <p>{transformedDescription}</p>
          <Link className="page-teaser-button" to={dest}>
            <div className="arrow-container">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div className="title-container">{page.title}</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageTeaser
