import React, { useMemo, cloneElement } from "react"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import innerText from "react-innertext"
import { v4 as uuidv4 } from "uuid"

import { SplitView } from "../components/split-view"
import ChatBot from "../components/chat-bot"
// import Subheading from "../components/subheading"
// import { ExtraColumnImage } from "../components/images"
import DekoderTiles from "../components/dekoder-tiles"
import ContentTile from "../components/content-tile"
import Tweet from "../components/tweet"
import PanelDiscussion from "../components/panel-discussion"
import Chart from "../components/chart"
import MapController from "../components/map"
import MapPoint from "../components/map/map-point"

import Annotation from "../../plugins/gatsby-plugin-popup-preloader/components/annotation/annotation"
import {
  carouselTransformer,
  subheadingTransformer,
  tocTransformer,
  youtubeVideoTransformer,
  introTransformer
} from "gatsby-source-dek-wp"
import {
  annotationTransformer,
  linkTransformer,
} from "gatsby-plugin-popup-preloader"

const options = {
  replace: transform,
  trim: true, // remove "\n" whitespace
}

const transformers = [
  carouselTransformer,
  annotationTransformer,
  linkTransformer,
  subheadingTransformer,
  tocTransformer,
  youtubeVideoTransformer,
  introTransformer
]

export function transform(node, i, _options = {}) {
  const classList = nodeClassList(node)

  if (node.name === "style") return null
  if (node.type !== "tag") return

  const { style } = attributesToProps(node.attribs)
  const children = getChildren(node)

  if (node.name === "dekodertiles")
    return (
      <DekoderTiles
        key={uuidv4()}
        ids={node.attribs.ids.split(",").map((id) => id * 1)}
      />
    )
  if (node.name === "contenttile")
    return <ContentTile key={uuidv4()} id={node.attribs.id * 1} />
  /* if (node.name === "h3")
    return (
      <Subheading
        key={uuidv4()}
        children={getChildren(node)}
        fromSplitView={_options.fromSplitView}
      />
    ) */
  if (node.name === "chatbot")
    return <ChatBot key={uuidv4()} attributes={node.attribs} />
  if (node.name === "map") {
    return (
      <MapController
        key={uuidv4()}
        mapUid={uuidv4()}
        sourceIds={node.attribs.sources ? node.attribs.sources.split(",") : []}
        title={node.attribs.title}
      />
    )
  }
  if (node.name === "chart" || node.name === "chartsetter")
    return (
      <Chart
        hide={node.name === "chartsetter"}
        key={uuidv4()}
        type={node.attribs.type}
        data={node.attribs.data}
        options={node.attribs.options}
        title={node.attribs.title}
        labels={node.attribs.labels}
        showInExtraColumn={classList.includes("show-in-extra-column")}
      />
    )
  // if (node.name === "figure" && classList.includes("alignright"))
  //  return <ExtraColumnImage key={uuidv4()} children={children} />
  if (classList.includes("tweet-container"))
    return <Tweet key={uuidv4()} children={children} />
  if (classList.includes("split-view"))
    return (
      <SplitView
        key={uuidv4()}
        children={
          children[0].props.children
          // getContainerChildren(node, { fromSplitView: true })
        }
      />
    )
  if (classList.includes("panel-discussion"))
    return <PanelDiscussion key={uuidv4()} children={children} />
  /* if (hasMatch(classList, /^link-with-annotation-(.+)$/)) {
    const annotationKey = hasMatch(classList, /^link-with-annotation-(.+)$/)[1]
    return (
      <AnnotatedBlock
        key={uuidv4()}
        children={children}
        annotationId={annotationKey}
      />
    )
  }*/
  if (
    classList.includes(
      "wp-block-dekoder-custom-blocks-container-with-coordinates"
    )
  ) {
    const uid = uuidv4()
    const children = getChildren(node)
    const name = children.length ? innerText(children[0]) : ""
    return (
      <MapPoint
        key={uid}
        uid={uid}
        name={name}
        lat={node.attribs.lat}
        lon={node.attribs.lon}
        category={node.attribs.category}
        content={children}
      />
    )
  }
  if (hasMatch(classList, /^annotation-(.+)$/)) {
    const annotationKey = hasMatch(classList, /^annotation-(.+)$/)[1]
    return (
      // LEGACY
      <Annotation
        key={uuidv4()}
        children={children}
        id={annotationKey}
        classList={classList}
        showInLine={classList.includes("show-inline")}
      />
    )
  }
  /* if (node.name === "img" && options.linkPrefix) {
    const nodeCopy = { ...node }
    nodeCopy.attribs.src = `${options.linkPrefix}${node.attribs.src}`
    return convertNodeToElement(nodeCopy, uuidv4())
  } */

  for (let x = 0; x < transformers.length; x++) {
    const t = transformers[x]
    const comp = t(node, 0, { classList, style, children })
    if (comp) return cloneElement(comp, { ...comp.props, key: uuidv4() })
  }
}

export function transformString(contentString) {
  return parse(contentString, options)
}

export function useTransformer(contentString) {
  const transformedContent = useMemo(
    () => transformString(contentString),
    [contentString]
  )
  return transformedContent
}

///

function nodeClassList(node) {
  if (!node.attribs) return []
  if (!node.attribs.class) return []
  return node.attribs.class.split(" ")
}

function hasMatch(array, regex) {
  return array.reduce((acc, c) => acc || c.match(regex), false)
}

function getChildren(node) {
  const c = domToReact(node.children, options) // || []
  return React.Children.toArray(c)
}
