import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react"
import scrollIntoView from "scroll-into-view"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faVolumeMute,
  faVolumeUp,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons"
import { LangContext } from "gatsby-source-dek-wp"
import { ArrowDown } from "../buttons"

export function useAutoPlay(videoSrc, videoRef) {
  useEffect(() => {
    if (!videoRef.current) return
    if (!videoSrc) return
    // console.log('new video', videoSrc);
    videoRef.current.load()
  }, [videoSrc, videoRef])
}

export function useCaptions(videoSrc, videoRef, captionUrl) {
  const [currentCaption, setCurrentCaption] = useState("")

  useEffect(() => {
    const v = videoRef.current
    // window.video = videoRef;
    if (!v) return
    if (!v.textTracks.length) return
    v.textTracks[0].mode = "hidden"
    v.textTracks[0].addEventListener("cuechange", onCueChange)
    return () => {
      if (!v) return
      if (!v.textTracks.length) return
      v.textTracks[0].removeEventListener("cuechange", onCueChange)
    }
  }, [videoSrc, videoRef])

  function onCueChange(e) {
    if (!e.target.activeCues.length) setCurrentCaption("")
    else {
      const text = e.target.activeCues[0].text
      setCurrentCaption(text)
    }
  }

  const lang = useContext(LangContext)

  // ?_ts=${Date.now()
  const captionTrack = !!captionUrl && !!videoSrc && (
    <track
      kind="subtitles"
      label={lang.name}
      srcLang={lang.id}
      src={`${captionUrl}`}
    />
  )
  return [captionTrack, currentCaption]
}

export function useTitleOverlay(
  titleDelay,
  videoSrc,
  children,
  scrollDownButton,
  showButtons = true
) {
  const [showTitle, setShowTitle] = useState(false)
  useEffect(() => {
    setShowTitle(!titleDelay)
  }, [videoSrc, titleDelay])

  function onCanPlay() {
    if (titleDelay) setTimeout(() => setShowTitle(true), titleDelay)
  }

  const titleOverlay = (
    <div
      className={
        "background-video-overlay with-theme-bg" + (showTitle ? " active" : "")
      }
    >
      {children}
      {showButtons ? scrollDownButton : ""}
    </div>
  )
  return [titleOverlay, onCanPlay, showTitle]
}

export function usePlayPauseControl(videoRef, videoSrc) {
  const [playing, setPlaying] = useState(true)

  const play = useCallback(() => {
    if (!videoSrc) return
    if (!videoRef.current) return
    setPlaying(true)
    const playPromise = videoRef.current.play()
    if (playPromise !== null) {
      playPromise.catch(() => {
        videoRef.current.play()
      })
    }
  }, [videoRef, videoSrc])

  const pause = useCallback(() => {
    if (!videoRef.current) return
    setPlaying(false)
    videoRef.current.pause()
  }, [videoRef])

  useEffect(() => {
    setPlaying(true)
  }, [videoSrc])

  const playPauseButton = (
    <button
      className="background-video-control-container"
      onClick={playing ? pause : play}
    >
      <FontAwesomeIcon icon={playing ? faPause : faPlay} />
    </button>
  )

  return [playing, play, pause, playPauseButton]
}

export function useScrollDownButton(startPage) {
  const targetRef = useRef()

  function scrollDown() {
    if (!targetRef.current) return
    scrollIntoView(targetRef.current, { align: { top: 0 } })
  }

  const scrollDownButton = !startPage && ( // start page has own scroll down button
    <ArrowDown
      onClick={scrollDown}
      extraClasses={`fixed ${startPage ? "start-page" : ""}`}
    />
  )
  const scrollDownTarget = <div ref={targetRef} />

  return [scrollDownButton, scrollDownTarget]
}

export function useMuteControl(playing) {
  const [muted, setMuted] = useState(playing)
  const muteButton = (
    <div
      className={`background-video-volume-container${
        playing ? " playing" : ""
      }${muted ? " muted" : ""}`}
    >
      <button onClick={() => setMuted(!muted)}>
        <FontAwesomeIcon icon={muted ? faVolumeUp : faVolumeMute} />
      </button>
    </div>
  )
  return [muted, muteButton]
}
