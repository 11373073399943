import React, { useContext } from "react"
import Draggable from "react-draggable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons"
import Tooltip from "@mui/material/Tooltip"
import Zoom from "@mui/material/Zoom"

import { LangContext } from "gatsby-source-dek-wp"
import "./map-legend.scss"
import { getLocalizedString } from "./map-controller-hooks"

const SUM_STRING = {
  de: "Summe",
  en: "sum",
  ru: "всего",
}

const SORT_NO = (a, b) => 0
const SORT_ASC = (a, b) => a.count - b.count
const SORT_DESC = (a, b) => b.count - a.count
const SORT_ORDER_ID = (a, b) => a.orderId - b.orderId

const sortMatches = [
  { id: "asc", function: SORT_ASC },
  { id: "desc", function: SORT_DESC },
  { id: "orderId", function: SORT_ORDER_ID },
]

function getSortFunction(sort) {
  const sortMatch = sortMatches.find((s) => s.id === sort)
  return sortMatch ? sortMatch.function : SORT_DESC
}

const MapLegend = ({
  title,
  subtitle,
  categories = [],
  shownCategories,
  onCategoryClick,
  sort,
  onlyPredefined = true,
}) => {
  const lang = useContext(LangContext)
  const defaultCategory = categories.find((c) => c.default)
  const sortedCategories = categories
    .slice()
    .filter((c) => !c.default)
    .filter((c) => c.name && c.color)
    .filter((c) => (onlyPredefined ? c.predefined : c))
    .sort(sort ? getSortFunction(sort) : SORT_NO)
  const categoriesToDisplay = defaultCategory
    ? [...sortedCategories, defaultCategory]
    : sortedCategories
  const totalCount = categoriesToDisplay.reduce(
    (acc, curr) => acc + curr.count,
    0
  )

  return (
    <Draggable handle=".map-legend-header">
      <div className="map-legend">
        <div className="map-legend-header">
          <FontAwesomeIcon icon={faArrowsAlt} />
          {!!title && (
            <div className="map-legend-title">
              {getLocalizedString(title, lang)}
            </div>
          )}
          {!!subtitle && (
            <div className="map-legend-subtitle">
              {getLocalizedString(subtitle, lang)}
            </div>
          )}
        </div>
        <div className="map-legend-body">
          <table className="map-legend-table">
            <tbody>
              {categoriesToDisplay.map((c) => {
                const displayName =
                  typeof c.showAs === "object" ? c.showAs[lang.id] : c.name
                return (
                  <Tooltip
                    key={c.name}
                    title={
                      <small>
                        Click to filter (Alt + click for multiple selection)
                      </small>
                    }
                    TransitionComponent={Zoom}
                  >
                    <tr
                      className={`map-legend-category ${
                        shownCategories.includes(c) ? "" : "inactive"
                      }`}
                      onClick={(e) => onCategoryClick(c, e.altKey)}
                    >
                      <td className="map-legend-category-color">
                        <div style={{ backgroundColor: c.color }} />
                      </td>
                      <td className="map-legend-category-name">
                        {displayName}
                      </td>
                      <td className="map-legend-category-count">{c.count}</td>
                    </tr>
                  </Tooltip>
                )
              })}
              <tr className="map-legend-category sum-row">
                <td></td>
                <td> {SUM_STRING[lang.id]}</td>
                <td className="map-legend-category-count">{totalCount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Draggable>
  )
}

export default MapLegend
