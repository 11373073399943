import React from "react"

import "./spinner.scss"

const Spinner = () => (
  <div className="spinner">
    <div className="lds-dual-ring"></div>
  </div>
)

export default Spinner
