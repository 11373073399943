import React from "react"
import {
  useAnimationInit,
  useScrollPos,
  useChangeOnScroll,
  useOverlayOpacity,
  useResizeListener,
  useParentSlideChange,
} from "./animation-hooks"
import "./animation.scss"

const Animation = ({ sources, containerElement, setCurrentSlide }) => {
  // setCurrentSlide: parent state
  const length = sources.length
  const [
    allAnimations,
    currentAnimation,
    setCurrentAnimation,
    containerRef,
  ] = useAnimationInit(sources)
  const scrollPos = useScrollPos(containerElement, length)
  useChangeOnScroll(
    scrollPos,
    allAnimations,
    currentAnimation,
    setCurrentAnimation,
    setCurrentSlide
  )
  const overlayOpacity = useOverlayOpacity(scrollPos, length)
  const fullscreen = useResizeListener(currentAnimation, containerRef)
  useParentSlideChange(scrollPos, setCurrentSlide)

  return (
    <div
      className={
        "animation-container-outer" + (fullscreen ? " fullscreen" : "")
      }
    >
      <div className="animation-container-inner" ref={containerRef}></div>
      <div className="animation-overlay" style={{ opacity: overlayOpacity }} />
    </div>
  )
}

export default Animation
