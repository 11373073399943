import { useMemo } from "react"
import { v4 as uuidv4 } from "uuid"

const BREAKPOINT_MOBILE = 850

export const useUid = () => {
  return useMemo(uuidv4, [])
}

export function isMobileDevice() {
  if (typeof window === "undefined") return
  return window.innerWidth < BREAKPOINT_MOBILE
}

export function isPortraitDevice() {
  if (typeof window === "undefined") return
  return window.innerHeight > window.innerWidth
}

export const isTouchDevice = () => {
  if (typeof window === "undefined") return
  return "ontouchstart" in document.documentElement
}

export const isDekoderLink = (url) => {
  const re1 = /https:\/\/www\.dekoder\.org\/de\/(.*?)/g
  if (url.match(re1)) return true
}

function getElementOffset(element, referenceEl = document.body, side) {
  if (typeof document === "undefined") return
  if (!element) return
  const referenceRect = referenceEl.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  return Math.round(elemRect[side] - referenceRect.top)
}

export const getOffsetTop = (element, referenceEl) => {
  return getElementOffset(element, referenceEl, "top")
}

export const getOffsetBottom = (element, referenceEl) => {
  return getElementOffset(element, referenceEl, "bottom")
}

export const isEmptyObject = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object
}
