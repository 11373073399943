import {
  titleColorLight,
  titleColorUltraLight,
} from "../../styles/globals.module.scss"

export const langs = [
  { id: "de", locale: "de-DE", name: "Deutsch", nameShort: "DE" },
  { id: "en", locale: "en-US", name: "English", nameShort: "EN" },
  { id: "ru", locale: "ru-RU", name: "По-русски", nameShort: "RU" },
  { id: "uk", locale: "uk-UA", name: "Українською", nameShort: "UK" },
]

export const defaultOptions = {
  // lang: langs[0],
  breakpointMobile: 850,
  percent: false,
  suffix: " %",
  noOrder: false,
  horizontal: false,
  columnHeight: 300,
  overallMax: false,
  split: false,
  barHeight: 36,
  // barHeightMobile: 36,
  // barHeightTablet: 36,
  barSpacing: 5,
  barOffset: 0,
  barMax: false,
  valueLabelsStickLeft: false,
  stacked: false,
  hideValues: false,
  round: 1, // decimal places
  barColors: [titleColorLight, titleColorUltraLight],
}
// get valueLabelsStickLeft() {return this.barOffset > 0;} // return this._valueLabelsStickLeft;}

export default class InfographicOptions {
  constructor(props) {
    for (let key in props) {
      this[key] = props[key]
    }
  }
  get responsiveBarHeight() {
    if (typeof window === "undefined") return this.barHeight
    return window.innerWidth > this.breakpointTablet
      ? this.barHeight // desktop
      : window.innerWidth > this.breakpointMobile
      ? this.barHeightTablet || this.barHeight
      : this.barHeightMobile || this.barHeightTablet || this.barHeight
  }
}
