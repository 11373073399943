import React, { useContext } from "react"
import { ContentsContext } from "gatsby-source-dek-wp"

import PageTeaser from "../page-teaser/page-teaser"

import "./tile-footer.scss"

const TileFooter = ({ selectedPage }) => {
  const pages = useContext(ContentsContext)

  const majorPages = pages.filter(p => p.pageType === "major")

  const selectedOrderId =
    typeof selectedPage === "object" ? selectedPage.orderId : 0

  const pagesAhead = majorPages.filter(
    (p) => p.orderId > selectedOrderId
  )
  const pagesBefore = majorPages.filter(
    (p) => p.orderId < selectedOrderId
  )

  const pagesAheadAndBefore = pagesAhead.concat(pagesBefore)

  return (
    <div className="page-footer">
      {pagesAheadAndBefore.map((page, i) => {
        return (
          <div className="footer-page-teaser-wrapper" key={i}>
            <PageTeaser
              key={`page_teaser_${i}`}
              page={page}
            />
          </div>
        )
      })}
    </div>
  )
}

export default TileFooter
