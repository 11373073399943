import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { LangContext } from "gatsby-source-dek-wp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons"

import "./language-switcher.scss"

const LanguageSwitcher = ({ langs, selected }) => {
  const [open, setOpen] = useState(false)
  const lang = useContext(LangContext)

  function onLangSelect(id) {
    setOpen(false)
  }

  function getDestSlug(langId) {
    const destSlug = selected
     ? selected.translations.find(t => t.langId === langId)?.slug || langId
     : langId
    return destSlug
  }

  return (
    <div className="language-switcher-protest">
      <button className="language-button" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faGlobeEurope} />
        <span
          className="language-button-name"
          data-long={lang.name}
          data-short={lang.short}
        />
      </button>
      <ul className={"language-menu" + (open ? " open" : " closed")}>
        {langs
          .filter((l) => l.id !== lang.id)
          .map((l) => (
            <li key={"lang_li_" + l.id}>
              <Link
                to={`/${getDestSlug(l.id)}`}
                className={`language-option${
                  l.id === lang.id ? " selected" : ""
                }`}
                value={l.id}
                onClick={() => onLangSelect()}
              >
                {l.name}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default LanguageSwitcher
