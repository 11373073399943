import React, { useContext, useEffect } from "react"

import { LangContext, usePreview, withToc } from "gatsby-source-dek-wp"

import MetaInfo from "../meta-info"
import { PageTitleClassic, PageTitleVideo } from "../page-title"
import ProgressBar from "../progress-bar"
import TileFooter from "../tile-footer"
import LogoFooter from "../logo-footer"

import { usePageTypeBodyClass } from "./page-hooks"
import { useTransformer } from "../../modules/transformer"
import "./page.scss"

import DonationEncourager, { donEnc } from "donation-encourager"
import {
  usePreloadedPopups,
  ExtraColumnWrapper,
  withAnnotations,
} from "gatsby-plugin-popup-preloader"

const PAGE_BODY_CLASS = "page-body-text"

const DON_ENC_SETTINGS = {
  targetSelector: `.${PAGE_BODY_CLASS}`,
  crossStorageUrl:
    "https://www.dekoder.org/d/cross-storage-hub/cross-storage-hub.html",
  domObserverEnabled: true,
  strings: {
    credit: "",
  },
}

function useDonEnc(contentType, updateTrigger, langId) {
  useEffect(() => {
    if (typeof window === "undefined") return
    donEnc("init", DON_ENC_SETTINGS)
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return
    if (langId === "de") donEnc("enable")
    else donEnc("disable")
  }, [langId])

  useEffect(() => {
    if (typeof window === "undefined") return
    ;[].push(updateTrigger)
    donEnc("pageview", contentType)
  }, [contentType, updateTrigger])
}

const PageBodyText = ({ children }) => {
  const langId = useContext(LangContext).id
  useDonEnc("special", children, langId)
  return (
    <div className={PAGE_BODY_CLASS}>
      <DonationEncourager />
      {children}
    </div>
  )
}

const PageBody = ({ children }) => {
  // const bodyRef = useRef()
  // const [pageBodyMinHeight, setPageBodyMinHeight] = useState(0)
  return (
    <ExtraColumnWrapper>
      <div
        className="page-body"
        // ref={bodyRef}
        // style={{ minHeight: `${pageBodyMinHeight}px` }}
      >
        {children}
      </div>
    </ExtraColumnWrapper>
  )
}

const AboutPage = ({ page, children, metaData }) => (
  <>
    <PageBody>
      <PageTitleClassic>{page.title}</PageTitleClassic>
      {children}
    </PageBody>
    <LogoFooter metaData={metaData} />
  </>
)

const DefaultPage = ({ page, children }) => (
  <>
    <ProgressBar />
    <PageBody>
      <PageTitleClassic>{page.title}</PageTitleClassic>
      <MetaInfo {...page} />
      {children}
    </PageBody>
    <TileFooter selectedPage={page} />
  </>
)

const MajorPage = ({ page, children }) => {
  const transformedDescription = useTransformer(page.description)
  return (
    <>
      <PageTitleVideo
        titleVideo={page.titleVideo}
        titleVideoPoster={page.titleVideoPoster}
        titleVideoCaptions={page.titleVideoCaptions}
        title={page.title}
        teaser={transformedDescription}
      />
      <ProgressBar />
      <PageBody>
        <MetaInfo {...page} />
        {children}
      </PageBody>
      <TileFooter selectedPage={page} />
    </>
  )
}

const Page = ({ page, fromStartPage, metaData }) => {
  const { content, popups, postId } = page
  const { restApi } = metaData
  usePreloadedPopups(popups)
  const contentState = usePreview(content, postId, restApi)
  const children = useTransformer(contentState)
  const pageType = usePageTypeBodyClass(page, fromStartPage)
  const text = <PageBodyText>{children}</PageBodyText>

  return (
    <div className="page-inner">
      {pageType === "major" ? (
        <MajorPage page={page}>{text}</MajorPage>
      ) : pageType === "about" ? (
        <AboutPage page={page} metaData={metaData}>
          {text}
        </AboutPage>
      ) : (
        <DefaultPage page={page}>{text}</DefaultPage>
      )}
    </div>
  )
}

export default withToc(withAnnotations(Page))
