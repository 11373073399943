import React from "react"
import "./background-sunglasses.scss"

const BackgroundSunglasses = ({ children, onClick }) => (
  <div // eslint-disable-line jsx-a11y/no-static-element-interactions
    className="background-sunglasses"
    onClick={onClick}
    onKeyDown={onClick}
  >
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className="background-sunclasses-child-wrapper"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  </div>
)

export default BackgroundSunglasses
