import React, { useState, useContext, useEffect } from "react"
import JSON5 from "json5"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsReact from "highcharts-react-official"
import BarInfographic from "../../custom-modules/bar-infographic/bar-infographic"
import { LangContext } from "gatsby-source-dek-wp"
import { ExtraColumnPortal } from "gatsby-plugin-popup-preloader"
import getOptions from "./highcharts-options"
import "./chart.scss"

if (typeof Highcharts === "object") {
  HighchartsMore(Highcharts)
}

const ChartLabel = ({ name }) => (
  <div className="chart-label">
    <div className="chart-label-color" />
    <div className="chart-label-name">{name}</div>
  </div>
)

const Chart = ({
  data,
  options,
  type,
  title,
  labels,
  showInExtraColumn,
  hide,
}) => {
  const lang = useContext(LangContext)
  const parsedData = useJsonParser(data, [])
  const parsedOptions = useJsonParser(options, {})

  if (hide) return null // for ChartSetter

  labels = labels ? labels.split(";") : []

  const chartComp = (
    <div className="chart-container">
      {type === "highcharts" ? renderHighcharts(parsedData, lang) : ""}
      {type !== "highcharts" && parsedData.length ? (
        <>
          <div className="chart-title">{title}</div>
          <div className="hide-when-collapsed">
            <BarInfographic
              data={parsedData}
              options={parsedOptions}
              lang={lang}
              labels={labels}
              // selectedSeries={settings.selectedSeries}
            />
            {!!labels.length && (
              <div className="chart-label-container">
                {labels.map((label, i) => (
                  <ChartLabel key={i} name={label} />
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  )

  if (showInExtraColumn)
    return (
      <ExtraColumnPortal type="chart" options={{ showInline: true }}>
        {chartComp}
      </ExtraColumnPortal>
    )

  return chartComp
}

export default Chart

function useJsonParser(rawData, initialState) {
  const [parsedData, setParsedData] = useState(initialState)
  useEffect(() => {
    if (typeof rawData === "object") setParsedData(rawData)
    else {
      try {
        if (rawData) setParsedData(JSON5.parse(rawData))
      } catch (e) {
        console.log(e, rawData)
      }
    }
  }, [rawData])
  return parsedData
}

function renderHighcharts(data, lang) {
  if (!data.highchartsOptions) return
  const optionsId = parseInt(data.highchartsOptions)
  const highchartsOptions = getOptions(optionsId, lang)
  // console.log(highchartsOptions);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={highchartsOptions}
      containerProps={{ style: { height: "100%" } }}
    />
  )
}
