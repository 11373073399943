import React from "react"
import "./logos.scss"

const StartPageLogos = ({ logos = [] }) => (
  <div className="start-page-logo-container-protest">
    {logos.map((logo, i) => (
      <div key={i} className="start-page-logo-outer">
        <a href={logo.url} target="_blank" rel="noopener noreferrer">
          <img src={logo.src} className="start-page-logo" alt="logo" />
        </a>
      </div>
    ))}
  </div>
)

export default StartPageLogos
