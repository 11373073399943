import React, { useRef, useContext, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"

import { ExtraColumnPortal } from "gatsby-plugin-popup-preloader"
import { LangContext } from "gatsby-source-dek-wp"
import transcribe from "../../custom-modules/transkribator"

import { useTransformer } from "../../modules/transformer"
import { AnnotationPopup } from "../../../plugins/gatsby-plugin-popup-preloader/components/link/popup"
import Chart from "../chart"
import "./map-popup.scss"

import { getLocalizedString } from "./map-controller-hooks"

// const KEYS_TO_FILTER = ['lat', 'lon', 'color', 'radius', 'uid', 'ts'];

const VIEW_RAW_DATA_STRING = {
  de: "Tabelle",
  en: "Table",
  ru: "Таблица",
}

const MapPopupInner = ({ title, subtitle, imageUrl, items, text, links }) => (
  <div className="map-popup-inner">
    <div className="map-popup-title">{title}</div>
    {subtitle && <div className="map-popup-subtitle">{subtitle}</div>}
    {imageUrl ? (
      <img alt={title} className="map-popup-image" src={imageUrl} />
    ) : (
      ""
    )}
    <table className="map-popup-table">
      <tbody>{items}</tbody>
    </table>
    {text ? <div className="map-popup-text">{text}</div> : ""}
    {links}
  </div>
)

const MapPopup = (props) => {
  const {
    popupData = {},
    hidePopup,
    columns = [],
    orderId,
    sourceLangId,
    color,
    keys,
    getPopupTitle,
    subtitle,
  } = props
  const { hover, onMouseOver, onMouseOut } = props
  const { setRawDataViewerOptions, rawData, barChartCategories } = props
  const lang = useContext(LangContext)
  const ref = useRef()
  const title =
    typeof getPopupTitle === "function"
      ? getPopupTitle(popupData)
      : transcribe(popupData[keys.primary], lang.id, sourceLangId)
  const text = popupData[keys.text]
  const linksString = urlStringToLinks(popupData[keys.links]) || ""
  const links = useTransformer(linksString)
  const imageUrl = popupData[keys.image]
  // const reservedKeys = Object.keys(keys).map(k => keys[k]);

  const rawDataViewerButton = useRawDataViewer(
    keys,
    title,
    popupData,
    setRawDataViewerOptions,
    color,
    lang
  )
  const barChartComponent = useBarChart(
    barChartCategories,
    rawData,
    keys,
    popupData,
    color,
    lang
  )

  const annotationId = keys.annotationId ? popupData[keys.annotationId] : null
  // console.log('ANNOTATION ID', annotationId, keys, data);

  const items = Object.keys(popupData)
    // .filter(k => !KEYS_TO_FILTER.includes(k) && !reservedKeys.includes(k))
    .filter((k) =>
      columns.length ? columns.map((c) => c.name).includes(k) : k
    )
    .filter((k) => popupData[k] || popupData[k] === 0) // show only those with values
    .map((k, i) => {
      const columnName = columns.find((c) => c.name === k)
      const key = columnName ? getLocalizedString(columnName.showAs, lang) : k
      const value = columnName
        ? typeof columnName.transform === "function"
          ? columnName.transform(popupData[k], lang)
          : popupData[k]
        : popupData[key]
      return (
        <tr key={i}>
          <td>{key}:</td>
          <td>
            <strong>{value}</strong>
          </td>
        </tr>
      )
    })

  return (
    <div className="map-popup-ref" ref={ref}>
      <ExtraColumnPortal
        type="map-popup"
        options={{ noTopShift: true }}
        collapse={hidePopup}
        linkKey={orderId}
        hover={hover}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onFocus={onMouseOver}
        onBlur={onMouseOut}
        color={color}
      >
        {annotationId ? (
          <AnnotationPopup annotationId={annotationId} />
        ) : (
          <MapPopupInner
            title={title}
            subtitle={subtitle}
            imageUrl={imageUrl}
            items={items}
            text={text}
            links={links}
          />
        )}
        {barChartComponent}
        {rawDataViewerButton}
      </ExtraColumnPortal>
    </div>
  )
}

export default MapPopup

/**
 * hooks
 */

function useRawDataViewer(
  keys,
  title,
  popupData,
  setRawDataViewerOptions,
  color,
  lang
) {
  const rawDataViewerButton = keys.rawData && (
    <button
      className="raw-data-viewer-button"
      onClick={() =>
        setRawDataViewerOptions({
          title,
          show: true,
          filter: { key: keys.rawData, value: popupData[keys.rawData] },
          color: color,
        })
      }
    >
      <FontAwesomeIcon icon={faTable} />
      {VIEW_RAW_DATA_STRING[lang.id]}
    </button>
  )
  return rawDataViewerButton
}

function useBarChart(
  barChartCategories,
  rawData,
  keys,
  popupData,
  color,
  lang
) {
  const barChartComponent = useMemo(() => {
    if (!barChartCategories) return null
    const chartData = barChartCategories
      .map((c) => {
        const filterKeys = c.keys
        const name =
          typeof c.showAs === "object"
            ? c.showAs[lang.id]
            : typeof c.showAs === "string"
            ? c.showAs
            : filterKeys[0]
        const count = rawData
          .filter((d) => d[keys.rawData] === popupData[keys.rawData]) // regional filter
          .filter((d) => hasFilterKey(d, filterKeys)) // categorial filter
          .reduce((acc, curr) => acc + 1, 0)
        return {
          [`name_${lang.id}`]: name,
          value: count,
        }
      })
      .filter((d) => d.value)
    // console.log(JSON.stringify(chartData))
    return <Chart data={chartData} options={{ barColors: [color] }} />
  }, [barChartCategories, rawData, keys, popupData, color, lang.id])

  return barChartComponent

  function hasFilterKey(d, filterKeys) {
    return filterKeys.reduce((acc, curr) => !!d[curr], false)
  }
}

/**
 * helper functions
 */

function urlStringToLinks(string, linkName = "Link") {
  if (!string) return
  return string
    .split(/[\s\r?\n|\r]+/) // split on spaces and line breaks
    .filter((l) => !!l)
    .map((singleLink) => `<a href="${singleLink}">${linkName}</a>`)
    .join(" ")
}
