/**
 * transkribator by dm@dekoder.org © 2019
 */

function endsWith(string, searchString, position) {
  const subjectString = string.toString()
  if (
    typeof position !== "number" ||
    !isFinite(position) ||
    Math.floor(position) !== position ||
    position > subjectString.length
  ) {
    position = subjectString.length
  }
  position -= searchString.length
  const lastIndex = subjectString.indexOf(searchString, position)
  return lastIndex !== -1 && lastIndex === position
}

const translit = {
  ru: {
    de: {
      Ё: "Jo",
      Й: "I",
      Ц: "Z",
      У: "U",
      К: "K",
      Е: "E",
      Н: "N",
      Г: "G",
      Ш: "Sch",
      Щ: "Schtsch",
      З: "S",
      Х: "Ch",
      Ъ: "",
      ё: "jo",
      й: "i",
      ц: "z",
      у: "u",
      к: "k",
      е: "e",
      н: "n",
      г: "g",
      ш: "sch",
      щ: "schtsch",
      з: "s",
      х: "ch",
      ъ: "",
      Ф: "F",
      Ы: "Y",
      В: "W",
      А: "A",
      П: "P",
      Р: "R",
      О: "O",
      Л: "L",
      Д: "D",
      Ж: "Sh",
      Э: "E",
      ф: "f",
      ы: "y",
      в: "w",
      а: "a",
      п: "p",
      р: "r",
      о: "o",
      л: "l",
      д: "d",
      ж: "sh",
      э: "e",
      Я: "Ja",
      Ч: "Tsch",
      С: "S",
      М: "M",
      И: "I",
      Т: "T",
      Ь: "",
      Б: "B",
      Ю: "Ju",
      я: "ja",
      ч: "tsch",
      с: "s",
      м: "m",
      и: "i",
      т: "t",
      ь: "",
      б: "b",
      ю: "ju",
    },

    en: {
      Ё: "Yo",
      Й: "I",
      Ц: "Ts",
      У: "U",
      К: "K",
      Е: "E",
      Н: "N",
      Г: "G",
      Ш: "Sh",
      Щ: "Sch",
      З: "Z",
      Х: "Kh",
      Ъ: "'",
      ё: "yo",
      й: "i",
      ц: "ts",
      у: "u",
      к: "k",
      е: "e",
      н: "n",
      г: "g",
      ш: "sh",
      щ: "sch",
      з: "z",
      х: "kh",
      ъ: "'",
      Ф: "F",
      Ы: "I",
      В: "V",
      А: "A",
      П: "P",
      Р: "R",
      О: "O",
      Л: "L",
      Д: "D",
      Ж: "Zh",
      Э: "E",
      ф: "f",
      ы: "i",
      в: "v",
      а: "a",
      п: "p",
      р: "r",
      о: "o",
      л: "l",
      д: "d",
      ж: "zh",
      э: "e",
      Я: "Ya",
      Ч: "Ch",
      С: "S",
      М: "M",
      И: "I",
      Т: "T",
      Ь: "'",
      Б: "B",
      Ю: "Yu",
      я: "ya",
      ч: "ch",
      с: "s",
      м: "m",
      и: "i",
      т: "t",
      ь: "'",
      б: "b",
      ю: "yu",
    },

    scientific: {
      Ё: "Ё",
      Й: "J",
      Ц: "C",
      У: "U",
      К: "K",
      Е: "E",
      Н: "N",
      Г: "G",
      Ш: "Š",
      Щ: "Šč",
      З: "Z",
      Х: "Ch",
      Ъ: "''",
      ё: "ё",
      й: "j",
      ц: "c",
      у: "u",
      к: "k",
      е: "e",
      н: "n",
      г: "g",
      ш: "š",
      щ: "šč",
      з: "z",
      х: "ch",
      ъ: "''",
      Ф: "F",
      Ы: "Y",
      В: "V",
      А: "A",
      П: "P",
      Р: "R",
      О: "O",
      Л: "L",
      Д: "D",
      Ж: "Ž",
      Э: "Ė",
      ф: "f",
      ы: "y",
      в: "v",
      а: "a",
      п: "p",
      р: "r",
      о: "o",
      л: "l",
      д: "d",
      ж: "ž",
      э: "ė",
      Я: "Ja",
      Ч: "Č",
      С: "S",
      М: "M",
      И: "I",
      Т: "T",
      Ь: "'",
      Б: "B",
      Ю: "Ju",
      я: "ja",
      ч: "č",
      с: "s",
      м: "m",
      и: "i",
      т: "t",
      ь: "'",
      б: "b",
      ю: "ju",
    },
  },
  ua: {
    de: {
      Ё: "Jo",
      Й: "I",
      Ц: "Z",
      У: "U",
      К: "K",
      Е: "E",
      Н: "N",
      Г: "H",
      Ш: "Sch",
      Щ: "Schtsch",
      З: "S",
      Х: "Ch",
      Ъ: "",
      ё: "jo",
      й: "i",
      ц: "z",
      у: "u",
      к: "k",
      е: "e",
      н: "n",
      г: "h",
      ш: "sch",
      щ: "schtsch",
      з: "s",
      х: "ch",
      ъ: "",
      Ф: "F",
      Ы: "Y",
      В: "W",
      А: "A",
      П: "P",
      Р: "R",
      О: "O",
      Л: "L",
      Д: "D",
      Ж: "Sh",
      Э: "E",
      ф: "f",
      ы: "y",
      в: "w",
      а: "a",
      п: "p",
      р: "r",
      о: "o",
      л: "l",
      д: "d",
      ж: "sh",
      э: "e",
      Я: "Ja",
      Ч: "Tsch",
      С: "S",
      М: "M",
      И: "Y",
      Т: "T",
      Ь: "",
      Б: "B",
      Ю: "Ju",
      я: "ja",
      ч: "tsch",
      с: "s",
      м: "m",
      и: "y",
      т: "t",
      ь: "",
      б: "b",
      ю: "ju",
      Ґ: "G",
      ґ: "g",
      Ї: "Ji",
      ї: "ji",
      І: "I",
      і: "i",
      Є: "E",
      є: "e",
    },

    en: {
      Ё: "Yo",
      Й: "I",
      Ц: "Ts",
      У: "U",
      К: "K",
      Е: "E",
      Н: "N",
      Г: "H",
      Ш: "Sh",
      Щ: "Sch",
      З: "Z",
      Х: "Kh",
      Ъ: "'",
      ё: "yo",
      й: "i",
      ц: "ts",
      у: "u",
      к: "k",
      е: "e",
      н: "n",
      г: "h",
      ш: "sh",
      щ: "sch",
      з: "z",
      х: "kh",
      ъ: "'",
      Ф: "F",
      Ы: "I",
      В: "V",
      А: "A",
      П: "P",
      Р: "R",
      О: "O",
      Л: "L",
      Д: "D",
      Ж: "Zh",
      Э: "E",
      ф: "f",
      ы: "i",
      в: "v",
      а: "a",
      п: "p",
      р: "r",
      о: "o",
      л: "l",
      д: "d",
      ж: "zh",
      э: "e",
      Я: "Ya",
      Ч: "Ch",
      С: "S",
      М: "M",
      И: "Y",
      Т: "T",
      Ь: "'",
      Б: "B",
      Ю: "Yu",
      я: "ya",
      ч: "ch",
      с: "s",
      м: "m",
      и: "y",
      т: "t",
      ь: "'",
      б: "b",
      ю: "yu",
      Ґ: "G",
      ґ: "g",
      Ї: "Yi",
      ї: "yi",
      І: "I",
      і: "i",
      Є: "E",
      є: "e",
    },
  },
}

function isVowel(c) {
  if (c)
    return (
      ["а", "е", "ё", "и", "о", "у", "ы", "ю", "э", "я", "є", "і", "ї"].indexOf(
        c.toLowerCase()
      ) !== -1
    )
  else return false
}

function transcribe(word, targetLang = "de", sourceLang = "ru") {
  if (!word) return
  let answer = ""
  // german
  if (targetLang === "de") {
    for (let i = 0; i < word.length; i++) {
      if (word.hasOwnProperty(i)) {
        const l = word.charAt(i) // letter
        if (translit[sourceLang][targetLang][l] === undefined) {
          answer += l
        } else {
          const prevL = word.charAt(i - 1) || false
          const nextL = word.charAt(i + 1)
          if (
            sourceLang === "ru" &&
            l === "е" &&
            (isVowel(prevL) || prevL === "ь")
          )
            answer += "je"
          if (
            sourceLang === "ua" &&
            l === "є" &&
            (isVowel(prevL) || prevL === "ь")
          )
            answer += "je"
          else if (
            sourceLang === "ru" &&
            l === "е" &&
            (!prevL || prevL === " ")
          )
            answer += "jе"
          else if (
            sourceLang === "ru" &&
            l === "Е" &&
            (!prevL || prevL === " ")
          )
            answer += "Jе"
          else if (
            sourceLang === "ua" &&
            l === "є" &&
            (!prevL || prevL === " ")
          )
            answer += "jе"
          else if (
            sourceLang === "ua" &&
            l === "Є" &&
            (!prevL || prevL === " ")
          )
            answer += "Jе"
          else if (l === "и" && prevL === "ь") answer += "ji"
          else if (l === "й" && (prevL === "ы" || prevL === "и")) answer += ""
          else if (l === "й" && isVowel(prevL) && isVowel(nextL)) answer += "j"
          else if (l === "с" && isVowel(prevL) && isVowel(nextL)) answer += "ss"
          else if (l === "ь" && nextL === "о") answer += "j"
          else if (
            l === "г" &&
            nextL === "o" &&
            !word.charAt(i + 2) &&
            (prevL === "e" || prevL === "о")
          )
            answer += "w"
          else if (l === "ё" && prevL) {
            if (
              prevL.toLowerCase() === "ж" ||
              prevL.toLowerCase() === "ч" ||
              prevL.toLowerCase() === "ш" ||
              prevL.toLowerCase() === "щ"
            )
              answer += "о"
            else answer += translit[sourceLang][targetLang][word[i]]
          } else if (l === "с" && prevL === "к") {
            answer = answer.slice(0, -1)
            answer += "x"
          } else if (l === "с" && prevL === "К") {
            answer = answer.slice(0, -1)
            answer += "X"
          } else answer += translit[sourceLang][targetLang][word[i]]
        }
      }
    }
    answer = answer
      .replace(/Alexandr /g, "Alexander ")
      .replace(/Alexei/g, "Alexej")
      .replace(/Dmitri/g, "Dimitri")
      .replace(/Sergei/g, "Sergej")
      .replace(/Walentin/g, "Valentin")
      .replace(/Wiktor/g, "Viktor")
      .replace(/Weronika/g, "Veronika")
      .replace(/Ukrajina/g, "Ukraine")
    if (endsWith(answer, "Alexandr"))
      answer = answer.replace(/Alexandr$/, "Alexander")
  }

  // english or scientific
  else if (targetLang === "en" || targetLang === "scientific") {
    for (let i = 0; i < word.length; i++) {
      if (word.hasOwnProperty(i)) {
        const l = word.charAt(i) // letter
        if (translit[sourceLang][targetLang][l] === undefined) {
          answer += l
        } else answer += translit[sourceLang][targetLang][word[i]]
      }
    }
    answer = answer.replace(/Ukrayina/g, "Ukraine")
  }

  // russian
  else {
    answer = word
  }
  return answer
}

export default transcribe
