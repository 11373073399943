import React, { useState, useEffect, useRef } from "react"
// import { transformString } from "../../modules/transformer"
import parse from "html-react-parser"

import "./dekoder-tiles.scss"


const LINK_PREFIX = "https://www.dekoder.org"
const API_URL = "https://www.dekoder.org/de/articles-by-nid/"

function transform(node) {
  if (node?.name === "a") {
    node.attribs.href = `${LINK_PREFIX}${node.attribs.href}`
    node.attribs.target = "_blank"
  }
  if (node?.name === "img") {
    node.attribs.src = `${LINK_PREFIX}${node.attribs.src}`
  }
}

function transformString(str) {
  const options = {
    replace: transform,
    trim: true, // remove "\n" whitespace
  }
  return parse(str, options)
}

function getNidFromTileElement(element) {
  const article = element.props.children
  const classes = article.props.className
  const match = classes.match(/ node-([0-9]*) /)
  if (match) return parseInt(match[1])
}

const DekoderTiles = ({ ids }) => {
  const ref = useRef()
  const [content, setContent] = useState()
  useEffect(() => {
    fetch(`${API_URL}${ids.join(",")}`)
      .then((r) => r.text())
      .then((r) => {
        if (!ref.current) return
        // console.log(r);
        // ref.current.innerHTML = r;
        setContent(transformString(r)) // { linkPrefix: LINK_PREFIX }
      })
  }, [ids])
  const [tiles, setTiles] = useState([])
  useEffect(() => {
    if (!content) return
    const tilesArray = (content?.props?.children?.props?.children || [])
      .map((el) => {
        return { nid: getNidFromTileElement(el), el }
      })
      .filter((obj) => obj.nid)
    setTiles(tilesArray)
  }, [content])
  return (
    <div className="dekoder-tiles-container" ref={ref}>
      <div className="view-content">
        {ids.map((nid) => {
          const matchingTile = tiles.find((t) => t.nid === nid)
          return matchingTile ? matchingTile.el : ""
        })}
      </div>
    </div>
  )
}

export default DekoderTiles
