import React, { useState, useMemo } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import TablePagination from "@mui/material/TablePagination"
import Paper from "@mui/material/Paper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"

import BackgroundSunglasses from "../background-sunglasses"
import { XButton, CsvButton } from "../buttons"

import "./table-view.scss"

const ROWS_PER_PAGE_OPTIONS = [20, 50, 100]

function usePagination(rows) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const paginationComponent = (
    <TablePagination
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        "aria-label": "previous page",
      }}
      nextIconButtonProps={{
        "aria-label": "next page",
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )

  const slicedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return [slicedRows, paginationComponent]
}

const TableView = ({
  data = [],
  filter = {},
  close,
  title,
  subtitle,
  color,
  columns = [],
}) => {
  const [expanded, setExpanded] = useState(false)
  const allKeys = data.length ? Object.keys(data[0]) : []
  const predefColumnNames = columns.map((c) => c.name)
  const keys = columns.length
    ? expanded
      ? [
          ...predefColumnNames,
          ...allKeys.filter((k) => !predefColumnNames.includes(k)),
        ]
      : predefColumnNames
    : allKeys
  const [orderDirection, setOrderDirection] = useState("asc")
  const [orderBy, setOrderBy] = useState(keys.length ? keys[0] : "")

  const rows = useMemo(() => {
    if (!data.length) return []
    return data
      .filter((r) => r[filter.key] === filter.value)
      .sort(getSorting(orderDirection, orderBy))
  }, [data, orderDirection, orderBy, filter.key, filter.value])

  const [slicedRows, paginationComponent] = usePagination(rows)

  const expandable = columns && columns.length < allKeys.length

  const fileName = `${stringToFileName(`${title}_${subtitle}`)}.csv`

  return (
    <BackgroundSunglasses onClick={close}>
      <div className="table-view" style={color ? { borderColor: color } : {}}>
        <div className="table-view-title">{title}</div>
        <div className="table-view-subtitle">
          {subtitle} |{" "}
          <CsvButton
            extraClasses="save-as-csv-button"
            onClick={() =>
              saveAsCsv(
                [allKeys, ...rows.map((r) => Object.keys(r).map((k) => r[k]))],
                fileName
              )
            }
          />
        </div>
        <XButton onClick={close} />
        <Paper className="table-view-paper">
          <Table size="small">
            <TableHead>
              <TableRow>
                {keys.map((k) => (
                  <TableCell key={k}>
                    <TableSortLabel
                      active={orderBy === k}
                      direction={orderDirection}
                      onClick={() => {
                        if (k !== orderBy) setOrderBy(k)
                        else
                          setOrderDirection(
                            orderDirection === "asc" ? "desc" : "asc"
                          )
                      }}
                    >
                      {k}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {expandable && (
                  <TableCell>
                    <FontAwesomeIcon
                      icon={expanded ? faArrowLeft : faArrowRight}
                      style={{ cursor: "pointer" }}
                      onClick={() => setExpanded(!expanded)}
                    />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedRows.map((r, rowIndex) => (
                <TableRow key={rowIndex}>
                  {keys.map((k) => (
                    <TableCell key={k}>{parseLinks(r[k])}</TableCell>
                  ))}
                  {expandable && <TableCell />}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {paginationComponent}
      </div>
    </BackgroundSunglasses>
  )
}

export default TableView

/**
 * helper functions
 */

function saveAsCsv(rows = [], fileName = "download.csv") {
  const csvContent =
    "data:text/csv;charset=utf-8," + rows.map((r) => r.join(",")).join("\n")
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
}

function stringToFileName(string) {
  return string.replace(/[^a-z0-9_-–äÄöÖüÜß]/gi, "_").toLowerCase()
}

function parseLinks(string) {
  if (typeof string !== "string") return string
  return string.match(/^http/) ? (
    <a href={string.trim()} target="blank" rel="norel noreferrer">
      {string}
    </a>
  ) : (
    string
  )
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}
