import { useRef, useState, useEffect } from "react"
import lottie from "lottie-web"
import debounce from "debounce"
import { isPortraitDevice } from "../../helpers"

export function useAnimationInit(sources) {
  const containerRef = useRef()
  const [allAnimations, setAllAnimations] = useState([])
  const [currentAnimation, setCurrentAnimation] = useState()

  useEffect(() => {
    // init
    if (!sources.length) return
    lottie.setQuality("low")
    setAllAnimations(sources.map((s, i) => loadAnimation(containerRef, s, i)))
    return () => lottie.destroy()

    function loadAnimation(ref, animationProps, index) {
      if (!ref.current) return
      if (typeof animationProps !== "object") return
      const src = isPortraitDevice()
        ? animationProps.srcMobile
        : animationProps.srcDesktop
      var params = {
        container: ref.current,
        renderer: "html",
        loop: false,
        autoplay: false,
        path: src,
      }
      const animation = lottie.loadAnimation(params)
      return animation
    }
  }, [sources])

  useEffect(() => {
    // init 1st animation
    if (!allAnimations.length) return
    setCurrentAnimation(allAnimations[0])
    window.animations = allAnimations
  }, [allAnimations])

  useEffect(() => {
    // on animation change
    if (!currentAnimation) return
    if (currentAnimation.isLoaded) currentAnimation.show()
    allAnimations
      .filter((a) => a !== currentAnimation)
      .forEach((a) => hideAnimation(a))

    function hideAnimation(animation) {
      if (animation.isLoaded) animation.hide()
      else animation.addEventListener("config_ready", hideOnConfigReady)
      function hideOnConfigReady() {
        animation.hide()
      }
    }
  }, [currentAnimation, allAnimations])

  return [allAnimations, currentAnimation, setCurrentAnimation, containerRef]
}

export function useScrollPos(containerElement, length) {
  const [scrollPos, setScrollPos] = useState(0)

  useEffect(() => {
    // add scroll listener
    const onScroll = debounce(
      () => {
        const newScrollPos = getScrollPercent()
        setScrollPos(newScrollPos)

        function getScrollPercent() {
          const rect = containerElement.current.getBoundingClientRect()
          if (rect.top > 0) return 0
          const scrollPercent = 1 - (rect.top + rect.height) / rect.height
          if (scrollPercent > 1) return 1
          return scrollPercent < 0 ? 0 : scrollPercent * length
        }
      },
      15,
      true
    )

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [containerElement, length])

  return scrollPos
}

export function useChangeOnScroll(
  scrollPos,
  allAnimations,
  currentAnimation,
  setCurrentAnimation
) {
  useEffect(() => {
    // onScrollPosChange
    if (!currentAnimation) return
    const currentAnimationKey = allAnimations.indexOf(currentAnimation)
    const newAnimationKey = Math.floor(scrollPos)

    if (currentAnimationKey !== newAnimationKey) {
      if (!allAnimations.hasOwnProperty(newAnimationKey)) return
      const newAnimation = allAnimations[newAnimationKey]
      setCurrentAnimation(newAnimation)
    } else {
      const percentWithinAnimation = scrollPos % 1
      const timelineDuration = currentAnimation.totalFrames - 1
      currentAnimation.goToAndStop(
        Math.round(percentWithinAnimation * timelineDuration),
        true
      )
    }
  }, [scrollPos, allAnimations, currentAnimation, setCurrentAnimation])
}

export function useOverlayOpacity(scrollPos, length) {
  const [overlayOpacity, setOverlayOpacity] = useState(0)

  useEffect(() => {
    const FADE_LENGTH = 0.15
    const FACTOR = 0.99

    const percentWithinAnimation = scrollPos % 1

    // fade at the end of each part (but not on last part)
    if (scrollPos < length - 1 && percentWithinAnimation > 1 - FADE_LENGTH) {
      const opacityPercent =
        (percentWithinAnimation - (1 - FADE_LENGTH)) * (1 / FADE_LENGTH)
      setOverlayOpacity(opacityPercent * FACTOR)
    }
    // fade in the beginning (but not on first part)
    else if (scrollPos > 1 && percentWithinAnimation < FADE_LENGTH) {
      const opacityPercent =
        (FADE_LENGTH - percentWithinAnimation) * (1 / FADE_LENGTH)
      setOverlayOpacity(opacityPercent * FACTOR)
    } else {
      setOverlayOpacity(0)
    }
  }, [scrollPos, length])

  return overlayOpacity
}

export function useResizeListener(currentAnimation, containerRef) {
  useEffect(() => {
    // add resize listener
    if (!currentAnimation) return
    const onWindowResize = debounce(
      () => {
        prepareContainerSize(currentAnimation, containerRef)
        if (
          currentAnimation.isLoaded &&
          typeof currentAnimation.renderer === "object"
        )
          currentAnimation.resize()
      },
      100,
      false
    )
    window.addEventListener("resize", onWindowResize)
    if (currentAnimation.isLoaded) onWindowResize()
    else currentAnimation.addEventListener("DOMLoaded", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }

    function prepareContainerSize(animation, ref) {
      if (!ref.current || typeof window === "undefined") return
      const rel = animation.animationData.w / animation.animationData.h
      let minHeight, minWidth
      if (window.innerWidth > window.innerHeight) {
        // landscape
        minHeight = Math.max(window.innerHeight, window.innerWidth / rel)
        minWidth = Math.max(window.innerWidth, minHeight * rel)
      } else {
        // portrait
        minWidth = Math.max(window.innerWidth, window.innerHeight * rel)
        minHeight = Math.max(window.innerHeight, minWidth / rel)
      }
      ref.current.style.width = `${minWidth}px`
      ref.current.style.height = `${minHeight}px`
    }
  }, [currentAnimation, containerRef])

  return true
}

export function useParentSlideChange(scrollPos, setCurrentSlide) {
  useEffect(() => {
    if (scrollPos === 0) setCurrentSlide(-1)
    else if (scrollPos === 1) setCurrentSlide("finish")
    else setCurrentSlide(Math.floor(scrollPos))
  }, [scrollPos, setCurrentSlide])
}
