import React, { useState, useEffect } from "react"
import { throttle } from "throttle-debounce"
import { BackArrow, MenuButton } from "../buttons"
import LanguageSwitcher from "../language-switcher"
import "./header.scss"

const AUTO_HIDE_THRESHOLD = 150
let prevScrollTop = 0

const Header = ({
  isStartPage,
  menuOpen,
  setMenuOpen,
  langs,
  selected
}) => {
  const hideHeader = useAutoHide()
  return (
    <div className={`header-protest ${hideHeader ? "hidden" : ""}`}>
      {!isStartPage && <BackArrow />}
      <LanguageSwitcher selected={selected} langs={langs} />
      <MenuButton menuOpen={menuOpen} onClick={() => setMenuOpen(o => !o)} /> 
    </div>
  )
}



export default Header

function useAutoHide() {
  const [hideHeader, setHideHeader] = useState(false)
  useEffect(() => {
    const throtteledOnScroll = throttle(200, onScroll)
    function onScroll() {
      const newScrollTop = document.scrollingElement.scrollTop
      const delta = newScrollTop - prevScrollTop
      //console.log(delta);
      if (newScrollTop === 0) {
        setHideHeader(false)
        return
      }
      if (delta <= -AUTO_HIDE_THRESHOLD) {
        prevScrollTop = newScrollTop
        setHideHeader(false)
      } else if (delta > 0) {
        prevScrollTop = newScrollTop
        setHideHeader(true)
      }
    }
    window.addEventListener("scroll", throtteledOnScroll)
    return () => window.removeEventListener("scroll", throtteledOnScroll)
  }, [])
  return hideHeader
}
