import React, { useRef, useState } from "react"
import { Waypoint } from "react-waypoint"
import Spinner from "../spinner"
import {
  useAutoPlay,
  useCaptions,
  useTitleOverlay,
  usePlayPauseControl,
  useScrollDownButton,
  useMuteControl,
} from "./background-video-hooks"
import "./background-video.scss"
import { useBodyClass } from "../../hooks"

const ON_VIDEO = "on-video"

const BackgroundVideo = ({
  videoSrc,
  poster,
  titleDelayTime,
  children,
  captionUrl,
  startPage,
  startPageScrollDownButton,
}) => {
  const videoRef = useRef()
  const [playing, play, pause, playPauseButton] = usePlayPauseControl(
    videoRef,
    videoSrc
  )
  const [muted, muteButton] = useMuteControl(playing)
  useAutoPlay(videoSrc, videoRef, play)
  const [scrollDownButton, scrollDownTarget] = useScrollDownButton(startPage)

  const [showButtons, setShowButtons] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const [titleOverlay, launchTitleTimer, showTitle] = useTitleOverlay(
    titleDelayTime,
    videoSrc,
    children,
    scrollDownButton,
    showButtons
  )
  const [captionTrack, currentCaption] = useCaptions(
    videoSrc,
    videoRef,
    captionUrl
  )

  const [bodyClass, setBodyClass] = useState(ON_VIDEO)
  useBodyClass(bodyClass)

  function onCanPlay() {
    setLoaded(true)
    launchTitleTimer()
  }

  function onTitleLeave(e) {
    // console.log('leave', e);
    setShowButtons(false)
    pause()
    setBodyClass("")
  }
  function onTitleEnter(e) {
    // console.log('enter', e);
    setShowButtons(true)
    play()
    setBodyClass(ON_VIDEO)
  }
  return (
    <Waypoint
      onEnter={onTitleEnter}
      onLeave={onTitleLeave}
      bottomOffset={0}
      topOffset={25} // 1/2 header-height
    >
      <div className="background-video-outer">
        {!loaded ? <Spinner /> : ""}
        <div className="background-video-container">
          <video // eslint-disable-line jsx-a11y/media-has-caption
            onCanPlay={onCanPlay}
            muted={muted}
            loop={true}
            ref={videoRef}
            controls={false}
            autoPlay={true}
            playsInline
            poster={poster}
            crossOrigin="anonymous"
          >
            <source src={`${videoSrc}` /* ?_ts=${Date.now()} */} />
            {captionTrack}
          </video>
          {currentCaption ? (
            <div className="background-video-captions">{currentCaption}</div>
          ) : (
            ""
          )}
        </div>
        <div className="background-video-raster has-vignette" />
        {titleOverlay}
        {startPage && showTitle ? startPageScrollDownButton : ""}
        {showButtons ? muteButton : ""}
        {showButtons ? playPauseButton : ""}
        <Waypoint
          onEnter={() => {
            /* leaving video */ setShowButtons(false)
          }}
          onLeave={(e) => {
            /* leaving page / entering video*/

            if (e.currentPosition === "below") setShowButtons(true)
          }}
          bottomOffset={20}
        >
          {scrollDownTarget}
        </Waypoint>
      </div>
    </Waypoint>
  )
}

export default BackgroundVideo
