import { titleColorLight } from "../../styles/globals.module.scss"
import { COLOR_KEY, RADIUS_KEY } from "./geojson-property-keys"

const DEFAULT_PROPS = {
  color: titleColorLight,
  radius: 11,
}

export function dataToGeoJson(data, props = DEFAULT_PROPS) {
  const features = data
    .slice()
    .filter((e) => e.lat && e.lon)
    .map((e) => {
      for (let k in Object.keys(e)) {
        if (typeof e[k] === "string") e[k] = e[k].trim()
      }
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [e.lon, e.lat],
        },
        properties: {
          [COLOR_KEY]: props.color,
          [RADIUS_KEY]: props.radius,
          ...e,
        },
      }
    })
  return {
    type: "FeatureCollection",
    features: features,
  }
}

export function enrichGeoJsonWithData(geoJson, dataObjects = []) {
  let newFeatures = geoJson.features.slice()

  dataObjects.forEach((dataObject) => {
    const {
      data = [],
      matchKeyGeoJson,
      matchKeyData,
      filterGeoJson = false,
    } = dataObject
    if (!data) return
    if (!data.length) return
    newFeatures = newFeatures
      .filter((f) =>
        filterGeoJson
          ? getMatchingData(f, data, matchKeyGeoJson, matchKeyData) // leave only features with matching data
          : f
      )
      .map((f) => {
        const dataProps =
          getMatchingData(f, data, matchKeyGeoJson, matchKeyData) || {}
        return {
          ...f,
          properties: {
            ...f.properties,
            ...dataProps,
          },
        }
      })
  })

  return {
    ...geoJson,
    features: newFeatures,
  }

  function getMatchingData(feature, data, matchKeyGeoJson, matchKeyData) {
    return data.find(
      (d) => d[matchKeyData] === feature.properties[matchKeyGeoJson]
    )
  }
}
