import React, { useState } from "react"
import { Waypoint } from "react-waypoint"
import { useBodyClass } from "../../hooks"
import { headerHeight } from "../../styles/globals.module.scss"

const BodyClassWrapper = ({ children, bodyClassName }) => {
  const [bodyClass, setBodyClass] = useState()
  useBodyClass(bodyClass)
  return (
    <Waypoint
      bottomOffset={
        typeof window !== "undefined"
          ? window.innerHeight - parseInt(headerHeight) / 2
          : 200
      }
      onEnter={() => setBodyClass(bodyClassName)}
      onLeave={() => setBodyClass(null)}
    >
      {children}
    </Waypoint>
  )
}

export default BodyClassWrapper
