import { createStore, combineReducers } from "redux"
import { mapPopupReducer } from "../components/map/map-popup-redux"
import { mapPointReducer } from "../components/map/map-point-redux"

const combinedReducer = combineReducers({
  mapPopups: mapPopupReducer,
  mapPoints: mapPointReducer,
})

const store = createStore(combinedReducer)

export default store

// const unsubscribe =
// store.subscribe(() => console.log(store.getState()))
